import { FC } from 'react';
import styled from 'styled-components';
import TokenStorage from '../../../helpers/TokenStorage';
// import { useActions } from '../../../hooks/useActions';

import {
	respondTo,
	respondFrom,
	respondHeightFrom,
} from '../../../styles/mixins';

import iconClose from '../../../assets/icons/close-icon.svg';
import iconCloseHover from '../../../assets/icons/close-icon-hover.svg';

type ComponentProps = {
	handleCloseModal: () => void;
};

const ExitModal: FC<ComponentProps> = ({ handleCloseModal }) => {
	// const { logout } = useActions();

	const handleLogout = () => {
		TokenStorage.removeToken();
		window.location.reload();
	};

	return (
		<Wrapper>
			<MobileCloseButton>
				<MobileCloseButtonBorder>
					<MobileCloseButtonBody
						className='nav-btn-hover'
						onClick={handleCloseModal}
					>
						<img src={iconClose} alt='Settings' draggable={false} />
						<img src={iconCloseHover} alt='Settings' draggable={false} />
					</MobileCloseButtonBody>
				</MobileCloseButtonBorder>
			</MobileCloseButton>
			<Title>Are you sure you want to quit the game?</Title>
			<ExitButton onClick={handleLogout}>
				<ExitButtonBorder>
					<ExitButtonBody className='nav-btn-hover'>EXIT</ExitButtonBody>
				</ExitButtonBorder>
			</ExitButton>
		</Wrapper>
	);
};

export default ExitModal;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #151515;
	border-radius: 10px;
	border: 1px solid #2a2c2b;
	padding: 8px;
	color: #fff;
	height: 100%;
	min-height: 200px;
	${respondFrom.sm`
		padding: 12px;
	`}
`;

const MobileCloseButton = styled.div`
	right: -6px;
	top: -6px;
	position: absolute;
	border-radius: 50%;
	height: 30px;
	width: 30px;
	background: rgba(21, 21, 21, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	cursor: pointer;
	img {
		height: 10px;
		width: 10px;
		display: inline-block;
	}
	img:last-child {
		display: none;
	}
	&:hover {
		.nav-btn-hover {
			background: #e2f4f2;
			border: 1px solid rgba(21, 21, 21, 0.8);
			img {
				display: none;
			}
			img:last-child {
				transition: all ease 0.5s;
				display: inline-block;
			}
		}
	}
	${respondTo.lg`
		display: none;
	`}
`;

const MobileCloseButtonBody = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 24px;
	width: 24px;
	border-radius: 50%;
	background: #151515;
`;

const MobileCloseButtonBorder = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	height: 26px;
	width: 26px;
	background: conic-gradient(
		from 180deg at 50% 50%,
		#af70c1 0deg,
		#45d1bd 125.63deg,
		#f9ad29 266.25deg,
		#af70c1 360deg
	);
`;

const Title = styled.h2`
	text-align: center;
	color: #e2f4f2;
	font-weight: 700;
	font-size: 28px;
	line-height: 33.6px;
	padding-bottom: 25px;
	max-width: 306px;
	${respondHeightFrom.base`
		padding-bottom: 10px;
	`}
`;

const ExitButton = styled.div`
	border-radius: 60px;
	width: 120px;
	height: 60px;
	background: rgba(21, 21, 21, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	cursor: pointer;
	&:hover {
		.nav-btn-hover {
			background: #e2f4f2;
			color: #151515;
			border: 2px solid rgba(21, 21, 21, 0.8);
		}
	}
`;

const ExitButtonBody = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 108px;
	height: 48px;
	border-radius: 60px;
	background: #151515;
	font-weight: 700;
	font-size: 22px;
	line-height: 26px;
`;

const ExitButtonBorder = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 52px;
	width: 112px;
	height: 52px;
	background: conic-gradient(
		from 180deg at 50% 50%,
		#af70c1 0deg,
		#45d1bd 125.63deg,
		#f9ad29 266.25deg,
		#af70c1 360deg
	);
`;
