import { FC, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { respondFrom, respondTo, respondHeightFrom } from '../../../styles/mixins';

import iconAudio from '../../../assets/img/header/icon-audio.svg';
import iconVisualEffects from '../../../assets/img/header/icon-visual-effects.svg';
import iconAditionalOptions from '../../../assets/img/header/icon-aditional-options.svg';
import iconClose from '../../../assets/icons/close-icon.svg';
import iconCloseHover from '../../../assets/icons/close-icon-hover.svg';
import { Game } from '../../../game/Game';
import { LocalStorage } from '../../../utils/localStorage';
import { EventType, getEvent } from '../../../GameEventBus';

type ComponentProps = {
	game: Game;
	handleCloseModal: () => void
}
const SettingsModal: FC<ComponentProps> = ({game, handleCloseModal}) => {
	const [isAudioOn, setIsAudioOn] = useState(false);
	const [isTurboOn, setIsTurboOn] = useState(LocalStorage.getItem('isTurboEnabled'));
	const [isLeftHandModeOn, setIsLeftHandModeOn] = useState(LocalStorage.getItem('isLeftHandedModeEnable'));
	const [isAditionalOptionsOn, setIsAditionalOptionsOn] = useState(false);

	const changeAudioMode  = useCallback((value: boolean) => {
		setIsAudioOn(value)
	}, [setIsAudioOn])

	const changeTurboMode  = useCallback((value: boolean) => {
		setIsTurboOn(value)
		LocalStorage.setItem('isTurboEnabled', value)
	}, [setIsTurboOn])

	const changeLeftHandMode  = useCallback((value: boolean) => {
		setIsLeftHandModeOn(value)
        LocalStorage.setItem('isLeftHandedModeEnable', value)
		getEvent(EventType.LEFT_HANDED_MODE_TOGGLE).send(value);
	}, [setIsLeftHandModeOn])
	
	return (
		<Wrapper>
			<MobileCloseButton>
				<MobileCloseButtonBorder>
					<MobileCloseButtonBody
						className='nav-btn-hover'
						onClick={handleCloseModal}
					>
						<img src={iconClose} alt='Settings' draggable={false} />
						<img src={iconCloseHover} alt='Settings' draggable={false} />
					</MobileCloseButtonBody>
				</MobileCloseButtonBorder>
			</MobileCloseButton>
			<Title>SETTINGS</Title>
			<List>
				<ListItem>
					<ItemIcon>
						<img src={iconAudio} alt='Audio' />
					</ItemIcon>
					<ItemBody>
						<h2>AUDIO</h2>
					</ItemBody>
					<ButtonLabel style={isAudioOn ? {} : { background: '#535858' }}>
						<ButtonInput
							type='checkbox'
							checked={isAudioOn}
							onChange={event => changeAudioMode(event.currentTarget.checked)}
						/>
						<ButtonSpan></ButtonSpan>
					</ButtonLabel>
				</ListItem>

				<ListItem>
					<ItemIcon>
						<img src={iconVisualEffects} alt='Audio' />
					</ItemIcon>
					<ItemBody>
						<h2>TURBO</h2>
					</ItemBody>
					<ButtonLabel style={isTurboOn ? {} : { background: '#535858' }}
					>
						<ButtonInput
							type='checkbox'
							checked={isTurboOn}
							onChange={event => changeTurboMode(event.currentTarget.checked)}
						/>
						<ButtonSpan></ButtonSpan>
					</ButtonLabel>
				</ListItem>

				<ListItem>
					<ItemIcon>
						<img src={iconAditionalOptions} alt='Audio' />
					</ItemIcon>
					<ItemBody>
						<h2>LEFT HANDED MODE</h2>
					</ItemBody>
					<ButtonLabel
						style={isLeftHandModeOn ? {} : { background: '#535858' }}
					>
						<ButtonInput
							type='checkbox'
							checked={isLeftHandModeOn}
							onChange={event => changeLeftHandMode(event.currentTarget.checked)}
						/>
						<ButtonSpan></ButtonSpan>
					</ButtonLabel>
				</ListItem>
			</List>
		</Wrapper>
	);
};

export default SettingsModal;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #151515;
	border-radius: 10px;
	border: 1px solid #2a2c2b;
	padding: 8px;
	color: #fff;
	height: 100%;
	${respondFrom.sm`
		padding: 12px;
	`}
`;

const MobileCloseButton = styled.div`
	right: -6px;
	top: -6px;
	position: absolute;
	border-radius: 50%;
	height: 30px;
	width: 30px;
	background: rgba(21, 21, 21, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	cursor: pointer;
	img {
		height: 10px;
		width: 10px;
		display: inline-block;
	}
	img:last-child {
		display: none;
	}
	&:hover {
		.nav-btn-hover {
			background: #e2f4f2;
			border: 1px solid rgba(21, 21, 21, 0.8);
			img {
				display: none;
			}
			img:last-child {
				transition: all ease 0.5s;
				display: inline-block;
			}
		}
	}
	${respondTo.lg`
		display: none;
	`}
`;

const MobileCloseButtonBody = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 24px;
	width: 24px;
	border-radius: 50%;
	background: #151515;
`;

const MobileCloseButtonBorder = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	height: 26px;
	width: 26px;
	background: conic-gradient(
		from 180deg at 50% 50%,
		#af70c1 0deg,
		#45d1bd 125.63deg,
		#f9ad29 266.25deg,
		#af70c1 360deg
	);
`;

const Title = styled.h2`
	text-align: center;
	color: #e2f4f2;
	font-weight: 700;
	font-size: 28px;
	line-height: 34px;
`;

const List = styled.div`
	height: 100%;
	overflow: auto;
	${respondFrom.sm`
		padding-top: 7px;
	`}
`;

const ListItem = styled.div`
	padding-top: 8px;
	display: flex;
	align-items: center;
	max-width: 250px;
	${respondHeightFrom.base`
		padding-top: 6px;
		max-height: 50px;
		`}
	${respondHeightFrom.baseMin`
		padding-top: 3px;
		max-height: 40px;
	`}
`;

const ItemIcon = styled.div`
	height: 60px;
	width: 60px;
`;

const ItemBody = styled.div`
	flex: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-right: 10px;
	h2 {
		font-weight: 700;
		font-size: 13px;
		line-height: 17px;
	}
`;

const ButtonInput = styled.input.attrs({ type: 'checkbox' })`
	opacity: 0;
	width: 0;
	height: 0;
`;

const ButtonLabel = styled.label`
	position: relative;
	display: inline-block;
	width: 53px;
	padding: 4px;
	height: 29px;
	border-radius: 60px;
	background: conic-gradient(
		from 180deg at 50% 50%,
		#af70c1 0deg,
		#45d1bd 125.63deg,
		#f9ad29 266.25deg,
		#af70c1 360deg
	);
	input:checked + span::before {
		-webkit-transform: translateX(22px);
		-ms-transform: translateX(22px);
		transform: translateX(22px);
		background-color: #e2f4f2;
	}
`;

const ButtonSpan = styled.span`
	position: absolute;
	cursor: pointer;
	border-radius: 60px;
	margin: 2px 2px 2px 2px;
	width: 49px;
	height: 25px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #151515;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	:before {
		position: absolute;
		content: '';
		height: 19px;
		width: 19px;
		left: 3px;
		bottom: 3px;
		background-color: #535858;
		-webkit-transition: 0.4s;
		border-radius: 50%;
		transition: 0.4s;
	}
`;
