import {Game} from "../Game";
import {loadSymbols} from "./SymbolsLoader";
import {loadUiAssets} from "./UiAssets";
import {loadButtonsAssets} from "./ButtonsLoader";
import {loadModalAssets} from "./modalLoader";
import { multiplierLoader } from "./multiplierLoader";
import { miniGameLoader } from "./miniGameLoader";
import { MinigameLettersLoader } from "./minigameLettersLoader";
import {EventType, getEvent} from "../../GameEventBus";


export const assetsLoader  = (game: Game) => {
    return new Promise((resolve) => {
        const loader = game.app.loader
        loadSymbols(loader)
        loadUiAssets(loader)
        loadButtonsAssets(loader)
        loadModalAssets(loader)
        multiplierLoader(loader)
        miniGameLoader(loader)
        MinigameLettersLoader(loader)
        loader.load()
        loader.onProgress.add((value) => {
            getEvent(EventType.ASSETS_PROGRESS).send(value.progress)
        })
        loader.onComplete.add(() => {
            game.isAssetsLoaded = true
            return resolve(true)
        })
    })
}
