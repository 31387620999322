import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppVersion from "./components/AppVersion";
import AuthorizationWindow from './features/Authorization';
import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";

document.addEventListener('gesturestart', event => {
    event.preventDefault();
    document.body.style.transform = 'scale(1)';
});

document.addEventListener('gesturechange', event =>  {
    event.preventDefault();
    document.body.style.transform = 'scale(1)';
});

document.addEventListener('gestureend', event =>  {
    event.preventDefault();
    document.body.style.transform = 'scale(1)';
});


const router = createBrowserRouter([
    {
        path: "/",
        element: <App />
    },
    {
        path: "/auth",
        element: <AuthorizationWindow />,
    }
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <AppVersion />
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
