import * as PIXI from "pixi.js";
import { Texture } from "pixi.js";
import { Game } from "../../Game";
import {MODAL_ASSETS} from "../../assetsLoader/modalLoader";
import {BUTTONS_ASSETS} from "../../assetsLoader/ButtonsLoader";
import {SYMBOLS_ASSETS} from "../../assetsLoader/SymbolsLoader";

class FreeSpinsSummary {
    game: Game;
    container: PIXI.Container;
    overlay: PIXI.Graphics;
    fssContainer: PIXI.Container;
    textContainer: PIXI.Container;
    bg: PIXI.Sprite;
    btn: PIXI.Sprite;
    youWon: PIXI.Sprite;
    numbers: PIXI.Texture[];
    winAmount: number;
    isModalActive: boolean;

    constructor(game: Game) {
        this.game = game;
        this.container = new PIXI.Container();
        this.overlay = new PIXI.Graphics();
        this.fssContainer = new PIXI.Container();
        this.textContainer = new PIXI.Container();
        this.winAmount = 0

        this.bg = new PIXI.Sprite(Texture.from(MODAL_ASSETS.MODAL_BACKGROUND));
        this.btn = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.OK_BTN));
        this.youWon = new PIXI.Sprite(Texture.from(MODAL_ASSETS.YOU_WON));
        this.numbers = [
            Texture.from(SYMBOLS_ASSETS.ZERO),
            Texture.from(SYMBOLS_ASSETS.ONE),
            Texture.from(SYMBOLS_ASSETS.TWO),
            Texture.from(SYMBOLS_ASSETS.THREE),
            Texture.from(SYMBOLS_ASSETS.FOUR),
            Texture.from(SYMBOLS_ASSETS.FIVE),
            Texture.from(SYMBOLS_ASSETS.SIX),
            Texture.from(SYMBOLS_ASSETS.SEVEN),
            Texture.from(SYMBOLS_ASSETS.EIGHT),
            Texture.from(SYMBOLS_ASSETS.NINE),
        ]
        this.isModalActive = false;
    }

    init = () => {
        this.setupContainer();
        this.setupOverlay();
        this.setupFSSContainer();
        this.setupBG();
        this.setupYouWon();
        this.setupBtn();
        this.showModal(false);

        this.game.app.stage.addChild(this.overlay);
        this.game.app.stage.addChild(this.container);

        this.resize();
        window.addEventListener('load', this.resize)
        window.addEventListener('resize', this.resize)
    }

    setupContainer = () => {
        this.container.width = 1920;
        this.container.height = 1080;
        this.container.pivot.set(960, 540);
        this.container.name = "FREE_SPINS_SUMMARY"
    }

    setupOverlay = () => {
        this.overlay.beginFill(0x151515);
        this.overlay.drawRect(0, 0, window.innerWidth, window.innerHeight);
        this.overlay.endFill();
        this.overlay.alpha = 0.8;
    }

    setupFSSContainer = () => {
        this.fssContainer.width = 970;
        this.fssContainer.height = 674;
        this.fssContainer.pivot.set(485, 337);
        this.fssContainer.x = 960;
        this.fssContainer.y = 540;
        this.fssContainer.zIndex = 1000;
        this.fssContainer.name = "FREE_SPINS_SUMMARY_MODAL";
        this.container.addChild(this.fssContainer)
    }

    setupBG = () => {
        this.fssContainer.addChild(this.bg)
    }

    setupYouWon = () => {
        this.youWon.x = 485 - this.youWon.width / 2;
        this.youWon.y = 100;
        this.fssContainer.addChild(this.youWon)
    }

    setupBtn = () => {
        this.btn.x = 485 - this.btn.width / 2 + 5;
        this.btn.y = 525;
        this.btn.interactive = true;
        this.btn.cursor = 'pointer';
        this.btn.on('click', this.doOnClick)
        this.btn.on('touchstart', this.doOnClick)
        this.fssContainer.addChild(this.btn);
    }

    setSpinSummary = (number: number) => {
        this.game.slotMachine?.changeBalance(number);
        const summary = number.toFixed(2).split('');
        const width = summary.length * 110;
        this.textContainer.width = width;
        this.textContainer.x = 485 - width / 2 - 25;
        this.textContainer.y = 270;

        for (let i = 0; i < summary.length; i++) {
            let index: number = Number(summary[i]);
            if(summary[i] === '.') {
                let numToAdd = new PIXI.Sprite(Texture.from(SYMBOLS_ASSETS.DOT))
                numToAdd.scale.set(2, 2)
                numToAdd.x = 120 * i + 10;
                numToAdd.y = 120
                this.textContainer.addChild(numToAdd);
            } else {
                let numToAdd = new PIXI.Sprite(this.numbers[index]);
                numToAdd.x = numToAdd.width * i + 20;
                this.textContainer.addChild(numToAdd);
            }

        }

        this.fssContainer.addChild(this.textContainer);
    }

    doOnClick = () => {
        if(!this.game.slotMachine) return
        this.isModalActive = false;
        this.game.assetsManager.gameContainer.interactiveChildren = true;
        this.fssContainer.visible = false;
        this.overlay.visible = false;
        this.removeDigits();
        this.game.slotMachine.isFsRunning = false
    }

    removeDigits = () => {
        this.textContainer.removeChildren();
    }

    showModal = (status: boolean, amount: number = 0) => {
        if (status) {
            this.setSpinSummary(amount);
            this.winAmount = amount
            this.overlay.visible = true;
            this.fssContainer.visible = true;
            this.game.assetsManager.gameContainer.interactiveChildren = false;
            this.isModalActive = true;
        } else {
            this.overlay.visible = false;
            this.fssContainer.visible = false;
            this.removeDigits();
            this.winAmount = 0
        }
    }

    resize = () => {
        if (!this.container || !this.game.assetsManager.gameField?.container) return

        this.container.width = window.innerWidth;
        this.container.height = window.innerHeight;
        this.overlay.width = window.innerWidth;
        this.overlay.height = window.innerHeight;
        this.container.scale.x = window.innerWidth / 1920;
        this.container.scale.y = window.innerWidth / 1920;

        this.container.x = window.innerWidth / 2;
        this.container.y = window.innerHeight / 1920;


        if (window.innerWidth / window.innerHeight >= 1.7066666666666668) {
            this.container.scale.x = window.innerHeight / 1080 - 0.012;
            this.container.scale.y = window.innerHeight / 1080 - 0.012;
        }

        if (window.innerWidth / window.innerHeight <= 0.76) {
            this.fssContainer.scale.set(1.7595);
            this.fssContainer.y = 1418;
        } else if (window.innerWidth <= 1800) {
            this.fssContainer.scale.set(1.035)
            this.fssContainer.y = 1057;
        } else {
            this.fssContainer.scale.set(1.005)
            this.fssContainer.y = 1040;
        }

    }

}

export default FreeSpinsSummary;
