import * as PIXI from 'pixi.js'
import { Texture } from 'pixi.js';
import { UI_ASSETS } from '../../assetsLoader/UiAssets';
import { Game } from "../../Game";
import { logoTextStyles } from '../../styles';

export default class Logo {
    game: Game;
    logoText: PIXI.Text;
    logoBG: PIXI.Graphics;
    logo: PIXI.Container
    logoPortrait?: PIXI.Sprite

    constructor(game: Game) {
        this.game = game;
        this.logoText = new PIXI.Text('logo', logoTextStyles);
        this.logoBG = new PIXI.Graphics();
        this.logo = new PIXI.Container();

    }

    init = () => {
        this.setupLogoContainer();
        this.setupLogoBG();
        this.setupLogoText();
        this.game.assetsManager.gameContainer.addChild(this.logo);
        this.game.resizeCallbacks.push(this.resize)
        this.logoPortrait = new PIXI.Sprite(Texture.from(UI_ASSETS.LOGO_PORTRAIT))
        this.logoPortrait!.width = 300
        this.logoPortrait!.height = 140
        this.game.assetsManager.gameContainer.addChild(this.logoPortrait);
    }

    setupLogoContainer = () => {
        this.logo.width = 190;
        this.logo.height = 135;
        this.logo.x = 113;
        this.logo.y = 48;
        this.logo.alpha = 0.5
        this.logo.name = 'LOGO';
    }

    setupLogoBG = () => {
        this.logoBG.beginFill(0x496526);
        this.logoBG.drawRect(0, 0, 200, 150);
        this.logoBG.endFill();
        this.logo.addChild(this.logoBG);
    }

    setupLogoText = () => {
        this.logoText.anchor.set(0.5);
        this.logoText.x = 100;
        this.logoText.y = 75;
        this.logo.addChild(this.logoText);
    }

    resize = () => {
        if (window.innerWidth / window.innerHeight <= 0.76) {
            this.logo.removeChildren();
            this.logoPortrait!.scale.set(1.6)

            this.logoPortrait!.x = 720 
            this.logoPortrait!.y = -35;
            this.setupLogoBG();
            this.setupLogoText();
            this.logoPortrait!.visible = true
            this.logo.visible = false
        } else {
            this.logoPortrait!.visible = false
            this.logo.visible = true
            if (window.innerWidth <= 1080) {
                this.logo.scale.set(1)
                this.logo.removeChildren();
                this.logo.x = 875;
                this.logo.y = 16;
                this.logo.height = this.logo.height / 1.5;

                const smallLogoBg = new PIXI.Graphics();
                smallLogoBg.beginFill(0x005826)
                smallLogoBg.drawRect(0, 0, 200, 150);

                const smallLogoText = new PIXI.Text('LOGO', {
                    fontFamily: 'Rubik',
                    fontSize: 70,
                    fill: 'white',
                    fontWeight: '700'
                })
                smallLogoText.x = 105 - smallLogoText.width / 2;
                smallLogoText.y = 75 - smallLogoText.height / 2;


                this.logo.addChild(smallLogoBg)
                this.logo.addChild(smallLogoText)
            } else {
                this.logo.scale.set(1)
                this.logo.removeChildren();
                
                this.setupLogoBG();
                this.setupLogoText();
                this.logo.width = 190;
                this.logo.height = 135;
                this.logo.x = 105;
                this.logo.y = 8;
            }
        }


    }
}