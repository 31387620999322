import * as PIXI from "pixi.js"
import { Texture } from "pixi.js";
import { MINIGAME_ASSETS } from "../assetsLoader/miniGameLoader";
import { EventType, getEvent } from "../../GameEventBus";
import MiniGame from "./miniGame";


class Chest {

    container: PIXI.Container;
    closedChest: PIXI.Sprite;
    openedChest: PIXI.Sprite;
    featureName: string;
    status: boolean;
    minigame: MiniGame

    constructor(minigame: MiniGame) {
        this.container = new PIXI.Container();
        this.openedChest = new PIXI.Sprite(Texture.from(MINIGAME_ASSETS.CHEST_OPENED));
        this.closedChest = new PIXI.Sprite(Texture.from(MINIGAME_ASSETS.CHEST_CLOSED));
        this.featureName = 'Feature name';
        this.minigame = minigame
        this.status = false;
    }

    init = () => {
        this.setupChest();
        this.setupDefaultGraphics();
    }

    setupChest = () => {
        this.container.width = 200;
        this.container.height = 200;
        this.container.interactive = true;
        this.container.name = 'CHEST';
        this.container.cursor = 'pointer';
        this.container.on('pointerdown', this.open)
    }

    setupOpenedChest = () => {
        this.container.interactive = false;
        this.container.cursor = 'default';
        this.container.removeChildren();
        this.setupOpenGraphics();
    }

    setupDefaultGraphics = () => {
        this.container.addChild(this.closedChest);
    }

    setupOpenGraphics = () => {
        this.openedChest.alpha = 0.5;
        this.container.addChild(this.openedChest);
    }

    open = () => {
        getEvent(EventType.CHEST_INTERACTION).send(true);
        if (!this.status) {
            const featuresCount = this.minigame.receivedFeatures.length
            const indexOfFeature = Math.floor(Math.random()*featuresCount)
            const randomFeature = this.minigame.receivedFeatures[indexOfFeature]
            this.minigame.receivedFeatures.splice(indexOfFeature, 1)
            this.minigame.features.push(randomFeature)
            this.status = true;
            this.setupOpenedChest();
            // getEvent(EventType.FEATURE_OBTAINED).send([this.featureName, 1])
            getEvent(EventType.OPEN_CHEST).send(true);
            getEvent(EventType.FEATURE_OBTAINED).send([randomFeature.name, randomFeature.value])
        }
    }
}

export { Chest }
