import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import { Game } from '../../Game';
import { Texture } from 'pixi.js';
import { BUTTONS_ASSETS } from '../../assetsLoader/ButtonsLoader';

class BurgerMenuBtn {
    game: Game;
    btn: PIXI.Container;
    btnDefault: PIXI.Sprite;
    btnHover: PIXI.Sprite;
    pointerIsDown: boolean;
    pointerIsOver: boolean;
    btnInactive: PIXI.Sprite;
    isMenuOpen: boolean;

    constructor(game: Game) {
        this.game = game;
        this.isMenuOpen = false;
        this.btn = new PIXI.Container();
        this.btnDefault = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.BURGER_MENU_DEFAULT_BTN));
        this.btnHover = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.BURGER_MENU_ACTIVE_BTN));
        this.pointerIsDown = false;
        this.pointerIsOver = false;
        this.btnInactive = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.BURGER_MENU_INACTIVE_BTN));
    }

    init = () => {
        this.setupBtnContainer();
        this.setupBtnDefault();
        this.setupBtnHover();
        this.setupBtnInactive();

        this.game.assetsManager.gameContainer.addChild(this.btn);
        this.game.resizeCallbacks.push(this.resize);
    }

    changeMenuState(isOpen: boolean) {
		this.isMenuOpen = isOpen;
	}

    setupBtnContainer = () => {
        this.btn.x = 90;
        this.btn.y = 770;
        this.btn.interactive = true;
        this.btn.name = "BURGER_MENU";
        this.btn.cursor = 'pointer';
        this.btn
            .on('pointerup', this.DoPointerUp)
            .on('pointerupoutside', this.DoPointerUpOutside)
            .on('touchstart', this.DoPointerDown)
            .on('click', this.DoPointerDown)
            .on('pointerover', this.DoPointerOver)
            .on('pointerout', this.DoPointerOut)
            .on('mousedown', this.DoPointerDown)
        this.btn.visible = false;
    }

    setupBtnDefault = () => {
        this.btn.addChild(this.btnDefault);
    }

    setupBtnHover = () => {
        this.btnHover.alpha = 0;
        this.btn.addChild(this.btnHover);
    }

    setupBtnInactive = () => {
        this.btnInactive.visible = false;
        this.btn.addChild(this.btnInactive);
    }
    DoPointerUp = () => {
        if (this.pointerIsOver) {
            let wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.btnHover,
                0,
                { alpha: 0 },
                { alpha: 1, repeat: 0 }
            );
            wiggleTween.fromTo(
                this.btnDefault,
                0,
                { alpha: 1 },
                { alpha: 0, repeat: 0 }
            );
        } else {
            let wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.btnDefault,
                0,
                { alpha: 0 },
                { alpha: 1, repeat: 0 }
            );
        }
        this.pointerIsDown = false;
    }

    DoPointerUpOutside = () => {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.btnDefault,
            0,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        this.pointerIsDown = false;
        this.pointerIsOver = false;
    }

    DoPointerDown = () => {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.btnHover,
            0,
            { alpha: 1 },
            { alpha: 0, repeat: 0 }
        );
        wiggleTween.fromTo(
            this.btnDefault,
            0,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );

        this.pointerIsDown = true;
    }

    DoPointerOver = () => {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.btnHover,
            0.3,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        this.pointerIsOver = true;
    }

    DoPointerOut = () => {
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.btnHover,
            0.3,
            { alpha: 1 },
            { alpha: 0, repeat: 0 }
        );
        wiggleTween.fromTo(
            this.btnDefault,
            0,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        if (!this.pointerIsDown) {
            this.pointerIsOver = false;
        }
    }

    resize = () => {
        if (window.innerWidth / window.innerHeight <= 0.76) {
            this.btn.scale.set(2.38);
            this.btn.visible = true;
            this.btn.y = 2770;
            this.btn.x = 810;
        } else {
            if (window.innerWidth <= 1080) {
                if (this.game.leftHandedMode) {
                    this.btn.x = 1635;
                    this.btn.y = 770;
                    this.btn.scale.set(1.7);
                    this.btn.visible = true;
                } else {
                    this.btn.x = 90;
                    this.btn.y = 770;
                    this.btn.scale.set(1.7);
                    this.btn.visible = true;
                }

            } else {
                this.btn.x = 90;
                this.btn.y = 770;
                this.btn.visible = false;
            }
        }

    }

}

export default BurgerMenuBtn;
