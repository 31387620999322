import { FC } from 'react';
import Clock from '../../features/Header/Components/Clock';

import LogoIcon from '../../assets/img/header/logo-standart.svg';
import { Game } from "../../game/Game";
import styled from "styled-components";
import NavBar from "./Components/NavBar";
import { respondFrom, respondTo } from "../../styles/mixins";
import InfoBar from "./Components/InfoBar";

export interface IGame {
  game: Game;
}

const Header: FC<IGame> = ({ game }) => {
  let betsArray: number[];

  if (!game.slotMachine) {
    betsArray = [];
  } else {
    betsArray = game.slotMachine?.betList;
  }

  return (
    <HeaderContainer>
      <LogoContainer>
          <p style={{color: "#fff"}}>Logo</p>
        <span>|</span>
        <LogoTitleHiden>Ivar the Boneless</LogoTitleHiden>
      </LogoContainer>

      <LogoTitle>Ivar the Boneless</LogoTitle>
      <>
        <InfoDisplay>
          <InfoBar game={game} betsArray={betsArray} />
        </InfoDisplay>
        <NavBar />
      </>

      <Clock />
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.header`
  height: 32px;
  padding: 0 10px;
  background: #151515;
  display: flex;
  align-items: center;
  z-index: 999;
  justify-content: space-between;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  height: 32px;

  div {
    ${respondFrom.lg`
			display: none;
		`}
  }

  span {
    padding: 0 15px;
    color: #e2f4f2;
    ${respondFrom.lg`
			display: none;
		`}
  }
`;

const LogoTitle = styled.div`
  flex: auto;
  text-align: center;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #e2f4f2;
  display: none;
  ${respondFrom.lg`
			display: block;
	`}
`;

const LogoTitleHiden = styled.div`
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #e2f4f2;
  display: none;
  ${respondTo.lg`
			display: block;
	`}
`;

const InfoDisplay = styled.div`
  flex: auto;
  ${respondFrom.lg`
		display: none;
	`}
`;
