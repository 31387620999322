import {FC, useCallback, useState} from 'react';
import { respondFrom } from '../../../styles/mixins';
import SliderButton from "./SliderButton";
import styled from "styled-components";

interface ISlider {
    slideIndex: number;
    setSlideIndex: Function;
    slides: any[]
}

const ModalSlider: FC<ISlider> = ({ slideIndex, setSlideIndex, slides }) => {

    const nextSlide = useCallback(() => {
       if(slideIndex !== slides.length) setSlideIndex(slideIndex + 1)
        else setSlideIndex(1)
    }, [slideIndex, setSlideIndex])

    const prevSlide = useCallback(() => {
        if(slideIndex !== 1) setSlideIndex(slideIndex - 1)
        else setSlideIndex(slides.length)
    }, [slideIndex, setSlideIndex])



    const moveDot = (index: number) => {
        setSlideIndex(index);
    };

    return (
        <>
            <SlidesContainer>
                {slides.map((item, index) => (
                    <Slide
                        className={slideIndex === index + 1 ? 'slide active-anim' : 'slide'}
                        key={index}
                    >
                        <img key={item.img} src={item.img} alt='Slide' draggable={false} />
                    </Slide>
                ))}
            </SlidesContainer>
            <Nav>
                <SliderButton handleCLick={prevSlide} direction={'prev'} />
                {Array.from({ length: slides.length }).map((item, index) => (
                    <NavDot onClick={() => moveDot(index + 1)} key={index}>
                        <NavDotBody
                            className={slideIndex === index + 1 ? 'active' : ''}
                        ></NavDotBody>
                    </NavDot>
                ))}
                <SliderButton handleCLick={nextSlide} direction={'next'} />
            </Nav>
        </>
    );
};

export default ModalSlider;

const SlidesContainer = styled.div`
	.active-anim {
		opacity: 1;
	}
`;

const Slide = styled.div`
	padding-bottom: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0;
	transition: opacity ease-in-out 0.4s;
	img {
		max-width: 100%;
		max-height: 100%;
	}
`;

const Nav = styled.nav`
	position: absolute;
	bottom: 20px;
	width: 100%;
	display: flex;
	justify-content: center;
	padding-right: 6px;
	${respondFrom.sm`
		min-height: 70px;
	 	flex-direction: row;
		padding: 8px 0 10px;
	`}
`;

const NavDot = styled.div`
	border-radius: 50%;
	height: 60px;
	width: 60px;
	background: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	pointer-events: none;
	.active {
		background: conic-gradient(
			from 180deg at 50% 50%,
			#af70c1 0deg,
			#45d1bd 125.63deg,
			#f9ad29 266.25deg,
			#af70c1 360deg
		);
	}
`;

const NavDotBody = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	background: #2a2c2b;
`;
