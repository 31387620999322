import * as PIXI from 'pixi.js'
import { Game } from "../../Game";
import { Feature } from '../../classes/Feature';
import { MINIGAME_ASSETS } from '../../assetsLoader/miniGameLoader';
import { Texture } from 'pixi.js';
import { EventType, getEvent } from '../../../GameEventBus';

export class PortraitFeaturesBar {
    game: Game;
    featureBarFragment: Texture;
    featuresContainer: PIXI.Container;
    hollowFeaturesContainer: PIXI.Container;
    fullFragments: number;
    featuresVisualize: Map<string, Texture>;
    icons: any

    constructor(game: Game) {
        this.game = game;
        this.featuresContainer = new PIXI.Container();
        this.hollowFeaturesContainer = new PIXI.Container();
        this.featureBarFragment = Texture.from(MINIGAME_ASSETS.FB_FRAGMENT)
        this.fullFragments = 0;
        this.featuresVisualize = new Map();
        this.icons = this.game.app.loader.resources.FEATURES_SHEET.spritesheet?.textures
    }

    init = () => {
        this.setupFeatureContainer();
        this.setupFeaturesVisualize();

        this.game.assetsManager.gameContainer.addChild(this.hollowFeaturesContainer);
        this.game.assetsManager.gameContainer.addChild(this.featuresContainer);
        this.game.resizeCallbacks.push(this.resize)

        getEvent<[string, number | boolean]>(EventType.FEATURE_OBTAINED).subscribe(value => {
            this.insertObtaindedFeature(value)
        })
    }

    setupFeatureContainer = () => {
        this.featuresContainer.width = 730;
        this.featuresContainer.height = 196;
        this.featuresContainer.x = 210;
        this.featuresContainer.y = 1370;
        this.featuresContainer.visible = false;
        this.featuresContainer.name = 'PORTRAIT_FEATURE_BAR'

        this.hollowFeaturesContainer.width = 730;
        this.hollowFeaturesContainer.height = 196;
        this.hollowFeaturesContainer.x = 210;
        this.hollowFeaturesContainer.y = 1370;
        this.hollowFeaturesContainer.visible = false;
        this.hollowFeaturesContainer.name = 'PORTRAIT_HOLLOW_FEATURE_BAR'
    }

    clearFeatures = () => {
        this.featuresContainer.removeChildren(0, this.featuresContainer.children.length);
        this.hollowFeaturesContainer.removeChildren(0, this.hollowFeaturesContainer.children.length);
        this.fullFragments = 0;
        this.featuresContainer.y = 1520;
        this.hollowFeaturesContainer.y = 1520;
    }

    setupFeaturesVisualize = () => {
        this.featuresVisualize.set("ExtraFreespins1", this.icons['extra_fs_fb.png']);
        this.featuresVisualize.set("ExtraFreespins2", this.icons['extra_fs2_fb.png']);
        this.featuresVisualize.set("ExtraFreespins3", this.icons['extra_fs3_fb.png']);
        this.featuresVisualize.set("SymbolWildH1", this.icons['h1_to_wild_fb.png']);
        this.featuresVisualize.set("SymbolWildH2", this.icons['h2_to_wild_fb.png']);
        this.featuresVisualize.set("SymbolWildH3", this.icons['h3_to_wild_fb.png']);
        this.featuresVisualize.set("SymbolWildH4", this.icons['h4_to_wild_fb.png']);
        this.featuresVisualize.set("SymbolWildL1", this.icons['l1_to_wild_fb.png']);
        this.featuresVisualize.set("SymbolWildL2", this.icons['l2_to_wild_fb.png']);
        this.featuresVisualize.set("SymbolWildL3", this.icons['l3_to_wild_fb.png']);
        this.featuresVisualize.set("SymbolWildL4", this.icons['l4_to_wild_fb.png']);
        this.featuresVisualize.set("SymbolRemoveL1", this.icons['l1_removed_fb.png']);
        this.featuresVisualize.set("SymbolRemoveL2", this.icons['l2_removed_fb.png']);
        this.featuresVisualize.set("SymbolRemoveL3", this.icons['l3_removed_fb.png']);
        this.featuresVisualize.set("SymbolRemoveL4", this.icons['l4_removed_fb.png']);
        this.featuresVisualize.set("MultiplierIncrease2", this.icons['m_x2_fb.png']);
        this.featuresVisualize.set("MultiplierIncrease3", this.icons['m_x3_fb.png']);
        this.featuresVisualize.set("MultiplierIncrease5", this.icons['m_x5_fb.png']);
        this.featuresVisualize.set("IncreaseMultipliertrue", this.icons['p_mult_fb.png']);
        this.featuresVisualize.set("StickyWildtrue", this.icons['s_wild_fb.png']);
    }

    addFeatures = (featuresCount: number = 3) => {
        for (let i = 0; i < featuresCount; i++) {
            const frag = new PIXI.Sprite(this.featureBarFragment);
            const fragContainer = new PIXI.Container();

            fragContainer.width = frag.width;
            fragContainer.height = frag.height;

            fragContainer.x = frag.width * i;
            fragContainer.y = frag.height;

            fragContainer.addChild(frag);
            this.hollowFeaturesContainer.addChild(fragContainer);
        }
    }

    addFeature = () => {
        const index = this.hollowFeaturesContainer.children.length;
        const frag = new PIXI.Sprite(this.featureBarFragment);
        const fragContainer = new PIXI.Container();

        fragContainer.width = frag.width;
        fragContainer.height = frag.height;

        fragContainer.x = frag.width * index;
        fragContainer.y = frag.height;
        fragContainer.addChild(frag);
        this.hollowFeaturesContainer.addChild(fragContainer);
    }


    insertObtaindedFeature = (feature: [string, number | boolean]) => {
        const index = this.fullFragments;
        const fragment = this.hollowFeaturesContainer.getChildAt(index);
        const obtainedFeature = new Feature(index);

        obtainedFeature.init()
        obtainedFeature.featureContainer.position.copyFrom(fragment);
        let featureIcon = new PIXI.Sprite(this.featuresVisualize.get(`${feature[0]}${feature[1]}`));

        obtainedFeature.featureContainer.addChild(featureIcon);
        this.featuresContainer.addChild(obtainedFeature.featureContainer);

        this.fullFragments += 1;
    }


    resize = () => {
        if (window.innerWidth / window.innerHeight <= 0.76) {
            this.featuresContainer.scale.set(1.6);
            this.hollowFeaturesContainer.scale.set(1.6);
            this.featuresContainer.visible = true;
            this.hollowFeaturesContainer.visible = true;
        } else {
            this.featuresContainer.visible = false;
            this.hollowFeaturesContainer.visible = false;
        }
    }
}

export default PortraitFeaturesBar;
