
export const rulesList = {
	content: `
      <p>
      Bandit's Riches is a video slot with a progressive multiplier feature. The slot is based on the story of two Italian safecrackers. New character characters with amazing special effects are located on 5 reels with 3 rows. 20 fixed lines make it possible to place bets from 1 to 5.
      </p>
      <br />
      <p>
      Bandit's Riches is a video slot with a progressive multiplier feature. The slot is based on the story of two Italian safecrackers. New character characters with amazing special effects are located on 5 reels with 3 rows. 20 fixed lines make it possible to place bets from 1 to 5.
      </p>
      <br />
      <p>
      Bandit's Riches is a video slot with a progressive multiplier feature. The slot is based on the story of two Italian safecrackers. New character characters with amazing special effects are located on 5 reels with 3 rows. 20 fixed lines make it possible to place bets from 1 to 5. Bandit's Riches is a video slot with a progressive multiplier feature. The slot is based on the story of two Italian safecrackers. New character characters with amazing special effects are located on 5 reels with 3 rows. 20 fixed lines make it possible to place bets from 1 to 5.
      </p>
      <br />
      <p>
      Bandit's Riches is a video slot with a progressive multiplier feature. The slot is based on the story of two Italian safecrackers. New character characters with amazing Bandit's Riches is a video slot with a progressive multiplier feature. The slot is based on the story of two Italian safecrackers. New character characters with amazing special effects are located on 5 reels with 3 rows. 20 fixed lines make it possible to place bets from 1 to 5.

      Bandit's Riches is a video slot with a progressive multiplier feature. The slot is based on the story of two Italian safecrackers. New character characters with amazing special effects are located on 5 reels with 3 rows. 20 fixed lines make it possible to place bets from 1 to 5.

      Bandit's Riches is a video slot with a progressive multiplier feature. The slot is based on the story of two Italian safecrackers. New character characters with amazing special effects are located on 5 reels with 3 rows. 20 fixed lines make it possible to place bets from 1 to 5. Bandit's Riches is a video slot with a progressive multiplier feature. The slot is based on the story of two Italian safecrackers. New character characters with amazing special effects are located on 5 reels with 3 rows. 20 fixed lines make it possible to place bets from 1 to 5.

      Bandit's Riches is a video slot with a progressive multiplier feature. The slot is based on the story of two Italian safecrackers. New character characters with amazing 
      </p>`,
};

export const slidesList = [
	{
		img: '',
	},
	{
		img: '',
	},
	{
		img: '',
	},
];
