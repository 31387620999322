import {slotMachine} from "./slotMachine";
import {Game} from "../Game";
import {Container, Graphics, Sprite} from "pixi.js";
import {FeatureDetailed, WinEvent} from "../../api/types";
import {SYMBOL_SIZE} from "../constants/Symbols";
import {EventType, getEvent} from "../../GameEventBus";
import { Timer } from "../../utils/Timer";

export class SlotEvents  {
    slotMachine: slotMachine
    game: Game
    constructor(slotMachine: slotMachine) {
        this.slotMachine = slotMachine
        this.game = slotMachine.game
    }

    placeWinBorder = (container: Container, color = 0x00e63d) => {
        const border = new Graphics();
        border.lineStyle(14, color, 1);
        border.beginFill(131, 0)
        border.drawRect(8, 8, SYMBOL_SIZE - 2, SYMBOL_SIZE - 2);
        border.endFill();
        container.addChild(border);
    }


    startMiniGame = ( features: FeatureDetailed[], extraFreeSpins: number) => {
        if(!this.game.assetsManager.miniGame) return
        this.game.assetsManager.miniGame.receivedFeatures = features
        this.game.assetsManager.winStartPopUp?.showModal(true, `${this.game.slotMachine?.freeSpinCount! - extraFreeSpins}`)
        this.game.assetsManager.miniGame?.toggleMiniGame(true)
        document.addEventListener('keydown', (e) => {
            if (e.code === 'KeyF') this.game.assetsManager.miniGame?.toggleMiniGame(false)
        })
    }


    startRespinMinigame = (features: FeatureDetailed[]) => {
        if(!this.game.assetsManager.miniGame) return
        this.game.assetsManager.miniGame.receivedFeatures = features;
        this.game.assetsManager.miniGame.setupRespinMinigame();
        getEvent(EventType.RESPIN_MINIGAME_ENDED).send(true);
    }

    buildWinLine = (win: number, events: WinEvent[]) => {
        let isWinLines = !!events.length
        return new Promise((resolve, reject) => {
            const winLines = events.filter((event) => event.type !== 'ScatterWin' && event.type !== 'ChestDropped')
            if(!winLines.length) return resolve(true)
            winLines.forEach((event) => {
                event.coords.forEach((cord) => {
                    const symbol = this.slotMachine.reels[cord.x].symbols[3 - cord.y]
                    if(["L1", "L2", "L3", "L4", "H1", "H2", "H3", "WILD"].includes(symbol.name)) {
                        symbol.win(cord.y)
                        if(symbol.name === "WILD") {
                            this.slotMachine.reels[cord.x].container.zIndex = cord.x * 100
                        } else {
                            this.slotMachine.reels[cord.x].container.zIndex = cord.x
                        }
                    } else {
                        this.placeWinBorder(symbol.container)
                    }
                })
            })

            this.changeWin(win)

            const timer = new Timer(() => {
                return resolve(true)
            }, isWinLines ? 2000 : 1000)
            timer.initialize()

        })

    }

    buildSpecialEvents = (events: WinEvent[]) => {
        let isWinLines = !!events.length
        return new Promise((resolve, reject) => {
            const filteredEvents = events?.filter((event) => event.type === 'ScatterWin' || event.type === 'ChestDropped')
            if(!filteredEvents.length) return resolve(true)
            filteredEvents.forEach((event) => {
                // event.

                event.coords.forEach((cord) => {
                    const symbol1 = this.slotMachine.reels[cord.x].symbols[3 - cord.y]
                    const symbol2 = this.slotMachine.reels[cord.x].symbols[7 - cord.y]

                    this.placeWinBorder(symbol1.container, 0xeb4034)
                    this.placeWinBorder(symbol2.container, 0xeb4034)
                })
            })

            const timer = new Timer(() => {
                return resolve(true)
            }, isWinLines ? 1500 : 1)
            timer.initialize()

        })

    }

    changeWin= (win: number) => {
        this.game.assetsManager.infoBar?.winBar.changeValue(win)
        this.game.assetsManager.footerForMobileHorizontal?.changeWin(win)
        this.game.assetsManager.footerForMobileVertical?.changeWin(win)
        getEvent(EventType.CHANGE_WIN).send(win)
    }
}
