import styled from "styled-components";
import { Game } from "../../../../game/Game";

interface IGame {
    game: Game
}

const MinMaxBet = ({game}: IGame) => {

    let betsArray: number[];
    if (!game.slotMachine) {
        betsArray = []
    } else {
        betsArray = game.slotMachine?.betList;
    }
    
    const minBet = betsArray[0];
    const maxBet = betsArray[betsArray.length - 1];

    return (
        <MinMaxBetTextContainer>
            <MinMaxBetDesciption>
                Min Bet: {minBet.toFixed(2)} USD
            </MinMaxBetDesciption>
            <MinMaxBetDesciption>
                Max Bet: {maxBet.toFixed(2)} USD
            </MinMaxBetDesciption>
        </MinMaxBetTextContainer>

    );
}

export default MinMaxBet;

const MinMaxBetTextContainer = styled.div`
    margin: auto;
    margin-top: 51px;
    display: flex;
    align-items: space-between:
    justify-content: center;
    flex-direction: row;
    background: #2A2C2B;
    width: 287px;
    height: 30px;
    border-radius: 7px;
    column-gap: 100px;
    padding: 6px;
`

const MinMaxBetDesciption = styled.p`
    margin: auto;
    font-family: Rubik;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left; 
`