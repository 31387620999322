import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import { Application, Texture } from 'pixi.js';
import { Game } from "../../Game";
import { BUTTONS_ASSETS } from "../../assetsLoader/ButtonsLoader";
import { UI_ASSETS } from "../../assetsLoader/UiAssets";
import { infoLabelStyles, infoValueStyles } from "../../styles";
import { EventType, getEvent } from '../../../GameEventBus';
import { LocalStorage } from '../../../utils/localStorage';

export class BetInput {
    app: Application;
    game: Game;
    label: string;
    width: number;
    value?: string | number;
    input?: PIXI.Container;
    title: PIXI.Text;
    text: PIXI.Text;
    pointerIsDown: boolean;
    pointerIsOver: boolean;
    plusButton: PIXI.Container;
    minusButton: PIXI.Container;
    backgroundInput?: PIXI.Sprite;
    texture?: PIXI.Texture;
    plusButtonDefault: PIXI.Sprite;
    plusButtonHover: PIXI.Sprite;
    minusButtonDefault: PIXI.Sprite;
    minusButtonHover: PIXI.Sprite;
    totalBetList: number[];
    selectedBetIndex: number;
    currentBetValue: number;
    betValue: number;
    isPlusMaxVal?: boolean
    isMinusMinVal?: boolean

    constructor(
        label: string,
        value: string | number,
        width: number,
        game: Game
    ) {
        this.betValue = Number(value);
        this.totalBetList = [0]
        this.selectedBetIndex = LocalStorage.getItem('chosenBet') ? LocalStorage.getItem('chosenBet'): 0;
        this.currentBetValue = this.totalBetList[this.selectedBetIndex];
        this.app = game.app;
        this.game = game;
        this.plusButton = new PIXI.Container();
        this.minusButton = new PIXI.Container();
        this.label = label;
        this.width = width;
        this.pointerIsDown = false;
        this.pointerIsOver = false;
        this.plusButtonDefault = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.PLUS_BTN));
        this.plusButtonHover = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.PLUS_BTN_HOVER));
        this.minusButtonDefault = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.MINUS_BTN));
        this.minusButtonHover = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.MINUS_BTN_HOVER));


        this.value = value === 0 ? value.toFixed(2) : `${Number(value).toFixed(2)} USD`;
        this.texture = Texture.from(UI_ASSETS.INFO_BACKGROUND)
        this.title = new PIXI.Text(`${this.label}`, infoLabelStyles);
        this.text = new PIXI.Text(this.value, infoValueStyles);
    }

    init = () => {

        this.title.resolution = 2;
        this.text.resolution = 2;
        this.backgroundInput = new PIXI.Sprite(this.texture);
        this.backgroundInput.width = this.width;

        this.backgroundInput.height = 60;

        this.input = new PIXI.Container();
        this.input.width = this.width;
        this.input.height = this.backgroundInput.height;
        this.input.addChild(this.backgroundInput, this.title, this.text);

        this.title.anchor.set(0.5, 0.5);
        this.text.anchor.set(0.5, 0.5);

        this.title.x = this.width / 2;
        this.text.x = this.width / 2;

        this.title.y = 18;
        this.text.y = 38;

        this.initializeMinusButton();
        this.initializePlusButton();

        this.changeButtonsState()
        this.game.inactiveElementsWhileSpin.push(this.minusButton);
        this.game.inactiveElementsWhileSpin.push(this.plusButton);

        getEvent<number>(EventType.CHOSEN_BET).subscribe(value => {
            this.doOnGetEventChooseBet(value)
        })

        getEvent(EventType.CHOSEN_BET).send(this.selectedBetIndex)

    };

    initializeMinusButton = () => {
        this.minusButton
            .on('pointerup', () => this.doMinusPointerUp())
            .on('pointerupoutside', () => this.doMinusPointerUpOutside())
            .on('touchstart', () => this.doMinusPointerDown())
            .on('click', (e) => this.leftClickMinus(e))
            .on('pointerover', () => this.doMinusPointerOver())
            .on('pointerout', () => this.doMinusPointerOut())
            .on('mousedown', () => this.doMinusPointerDown());

        this.minusButton.interactive = true;
        this.minusButton.buttonMode = true;

        this.minusButton.width = 33;
        this.minusButton.height = 52;

        this.minusButtonHover.alpha = 0;

        this.backgroundInput?.addChild(this.minusButton);
        this.minusButton.addChild(this.minusButtonDefault!);
        this.minusButton.addChild(this.minusButtonHover!);

        this.minusButton.x = 4;
        this.minusButton.y = 4;

        this.minusButton.name = 'MINUS_BTN';

        if (this.selectedBetIndex === 0) {
            this.minusButton.buttonMode = false
            this.isMinusMinVal = true
            this.minusButtonDefault.alpha = 0.5
        }
    };

    leftClickMinus = (e: any) => {
        if (this.game.slotMachine?.isSpinning === false) {
            if (e.data.button === 0) {
                this.doMinusPointerDown()
            }
            ;
        }
    }

    initializePlusButton = () => {
        this.plusButton
            .on('pointerup', () => this.doPlusPointerUp())
            .on('pointerupoutside', () => this.doPlusPointerUpOutside())
            .on('touchstart', () => this.doPlusPointerDown())
            .on('click', (e) => this.leftClickPlus(e))
            .on('pointerover', () => this.doPlusPointerOver())
            .on('pointerout', () => this.doPlusPointerOut())
            .on('mousedown', () => this.doPlusPointerDown());


        this.plusButton.interactive = true;
        this.plusButton.buttonMode = true;

        this.plusButton.width = 33;
        this.plusButton.height = 52;

        this.plusButtonHover.alpha = 0;

        this.backgroundInput?.addChild(this.plusButton);
        this.plusButton.addChild(this.plusButtonDefault!);
        this.plusButton.addChild(this.plusButtonHover!);

        this.plusButton.x = this.width + 12
        this.plusButton.y = 4;

        this.plusButton.name = "PLUS_BTN";

        if (this.selectedBetIndex === this.totalBetList.length - 1) {
            this.isPlusMaxVal = true
            this.plusButton.interactive = true
            this.plusButtonDefault.alpha = 0.5
            this.plusButton.buttonMode = false
        }
    };

    leftClickPlus = (e: any) => {
        if (e.data.button === 0) {
            this.doPlusPointerDown()
        }
    }

    doMinusPointerUp() {
        if (this.selectedBetIndex === 0) return
        if (this.pointerIsOver) {
            let wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.minusButtonHover,
                0,
                { alpha: 0 },
                { alpha: 1, repeat: 0 }
            );
            wiggleTween.fromTo(
                this.minusButtonDefault,
                0,
                { alpha: 1 },
                { alpha: 0, repeat: 0 }
            );
        } else {
            let wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.minusButtonDefault,
                0,
                { alpha: 0 },
                { alpha: 1, repeat: 0 }
            );
        }
        this.pointerIsDown = false;
        this.changeButtonsState()
    }

    doOnGetEventChooseBet = (index: number) => {
        this.selectedBetIndex = index || 0;
        this.currentBetValue = this.totalBetList[this.selectedBetIndex]
        this.text.text = `${this.currentBetValue?.toFixed(2)} USD`;

        if ((this.totalBetList.length - (this.selectedBetIndex + 1)) > 0) {
            this.minusButton.interactive = true;
            this.minusButton.cursor = 'pointer';
            this.isMinusMinVal = false;
            this.minusButton.alpha = 1;
        }
      
        if (this.totalBetList.length != (this.selectedBetIndex + 1)) {
            this.plusButton.interactive = true;
            this.plusButton.cursor = 'pointer';
            this.isPlusMaxVal = false;
            this.plusButton.alpha = 1;
        }
       
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.minusButtonDefault,
            0,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );

        this.plusButton.interactive = true;
        this.isPlusMaxVal = false;
        wiggleTween.fromTo(
            this.plusButtonDefault,
            0,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        if (index === 0) {
            this.minusButton.interactive = false;
            this.minusButtonDefault.alpha = 0.5;
            this.isMinusMinVal = true;
        }

        if (index === (this.totalBetList.length - 1)) {
            this.plusButtonDefault.alpha = 0.5;
            this.plusButton.interactive = false;
            this.isPlusMaxVal = true;
        }
    }

    doPlusPointerUp() {
        if (this.selectedBetIndex === this.totalBetList.length - 1) return

        if (this.pointerIsOver) {
            let wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.plusButtonHover,
                0,
                { alpha: 0 },
                { alpha: 1, repeat: 0 }
            );
            wiggleTween.fromTo(
                this.plusButtonDefault,
                0,
                { alpha: 1 },
                { alpha: 0, repeat: 0 }
            );
        } else {
            let wiggleTween = gsap.timeline();
            wiggleTween.fromTo(
                this.plusButtonDefault,
                0,
                { alpha: 0 },
                { alpha: 1, repeat: 0 }
            );
        }
        this.pointerIsDown = false;
        this.changeButtonsState()
    }

    doMinusPointerUpOutside() {
        if (this.selectedBetIndex === 0) return

        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.minusButtonDefault,
            0,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        this.pointerIsDown = false;
        this.pointerIsOver = false;
    }

    doPlusPointerUpOutside() {
        if (this.selectedBetIndex === this.totalBetList.length - 1) return

        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.plusButtonDefault,
            0,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        this.pointerIsDown = false;
        this.pointerIsOver = false;
    }

    doMinusPointerDown() {
        if (!this.game.slotMachine) return
        if (this.pointerIsDown) return
        if (this.selectedBetIndex > 0) {
            this.selectedBetIndex--;
        } else if (this.selectedBetIndex === 0) {
            this.selectedBetIndex = 0
        }
        this.plusButtonDefault.alpha = 1
        this.isPlusMaxVal = false
        this.plusButton.buttonMode = true
        this.isPlusMaxVal = false

      
        this.currentBetValue = this.totalBetList[this.selectedBetIndex];


        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.minusButtonHover,
            0,
            { alpha: 1 },
            { alpha: 0, repeat: 0 }
        );
        wiggleTween.fromTo(
            this.minusButtonDefault,
            0,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );

        if (this.currentBetValue <= 0) return
        this.text.text = `${this.currentBetValue.toFixed(2)} USD`;
        this.game.slotMachine.bet = this.currentBetValue

        let wiggleTween2 = gsap.timeline();
        wiggleTween2.fromTo(
            this.minusButtonHover,
            0,
            { alpha: 1 },
            { alpha: 0, repeat: 0 }
        );
        wiggleTween2.fromTo(
            this.minusButtonDefault,
            0,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        if (this.selectedBetIndex === 0) {
            this.minusButton.buttonMode = false
            this.doMinusPointerOut()
            this.isMinusMinVal = true
            this.minusButtonDefault.alpha = 0.5
        }
        this.pointerIsDown = true;
        getEvent(EventType.CHOSEN_BET).send(this.selectedBetIndex)
    }

    doPlusPointerDown() {
        if (this.pointerIsDown || !this.game.slotMachine) return

        if (this.selectedBetIndex < this.totalBetList.length - 1 && this.selectedBetIndex !== this.totalBetList.length - 1) {
            this.selectedBetIndex++;
        } else this.plusButton.buttonMode = false
        this.minusButtonDefault.alpha = 1
        this.isMinusMinVal = false
        this.minusButton.buttonMode = true

        getEvent(EventType.CHOSEN_BET).send(this.selectedBetIndex)

        this.currentBetValue = this.totalBetList[this.selectedBetIndex];


        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.plusButtonHover,
            0,
            { alpha: 1 },
            { alpha: 0, repeat: 0 }
        );
        wiggleTween.fromTo(
            this.plusButtonDefault,
            0,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        this.text.text = `${this.currentBetValue.toFixed(2)} USD`;
        this.game.slotMachine.bet = this.currentBetValue

        let wiggleTween2 = gsap.timeline();
        wiggleTween2.fromTo(
            this.plusButtonHover,
            0,
            { alpha: 1 },
            { alpha: 0, repeat: 0 }
        );
        wiggleTween2.fromTo(
            this.plusButtonDefault,
            0,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        if (this.selectedBetIndex === this.totalBetList.length - 1) {
            this.doPlusPointerOut()
            this.isPlusMaxVal = true
            this.plusButton.interactive = true
            this.plusButtonDefault.alpha = 0.5
            this.plusButton.buttonMode = false

        }
        this.pointerIsDown = true;
    }


    doMinusPointerOver() {
        if (this.selectedBetIndex === 0) return

        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.minusButtonHover,
            0.3,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        this.pointerIsOver = true;
    }

    doPlusPointerOver() {
        if (this.selectedBetIndex === this.totalBetList.length - 1) return
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.plusButtonHover,
            0.3,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        this.pointerIsOver = true;
    }


    doMinusPointerOut() {
        if (this.isMinusMinVal) return

        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.minusButtonHover,
            0.3,
            { alpha: 1 },
            { alpha: 0, repeat: 0 }
        );
        wiggleTween.fromTo(
            this.minusButtonDefault,
            0,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        if (!this.pointerIsDown) {
            this.pointerIsOver = false;
        }
    }


    doPlusPointerOut() {
        if (this.isPlusMaxVal) return
        let wiggleTween = gsap.timeline();
        wiggleTween.fromTo(
            this.plusButtonHover,
            0.3,
            { alpha: 1 },
            { alpha: 0, repeat: 0 }
        );
        wiggleTween.fromTo(
            this.plusButtonDefault,
            0,
            { alpha: 0 },
            { alpha: 1, repeat: 0 }
        );
        if (!this.pointerIsDown) {
            this.pointerIsOver = false;
        }
    }

    changeButtonsState = () => {
        if (this.selectedBetIndex === 0) {
            this.minusButton.alpha = 0.5
            this.minusButton.interactive = false
        } else {
            this.minusButton.alpha = 1
            this.minusButton.interactive = true
        }
        if (this.selectedBetIndex === this.totalBetList.length - 1) {
            this.plusButton.alpha = 0.5
            this.plusButton.interactive = false
        } else {
            this.plusButton.alpha = 1
            this.plusButton.interactive = true
        }
    }

    changeBtnStatus = (status: boolean) => {

        if (!status) {
            this.minusButton.alpha = 0.5
            this.minusButton.interactive = false
            this.plusButton.alpha = 0.5
            this.plusButton.interactive = false
        } else {
            this.minusButton.alpha = 1
            this.minusButton.interactive = true
            this.plusButton.alpha = 1
            this.plusButton.interactive = true
        }
    }

    setBetList = (value: number[]) => {
        this.totalBetList = value;
        this.currentBetValue = this.totalBetList[this.selectedBetIndex];
        this.text.text = `${this.currentBetValue?.toFixed(2)} USD`;
    }

}


