
import {useCallback, useEffect} from "react";
import { EventType } from './events';
import { EventBus } from './EventBus';

export function useEventReceiver<T>(name: EventType, callback: (payload: T) => void) {
  const subscribe = useCallback((callback: (payload: T) => void) => {
    return EventBus.subscribe(name, callback);
  }, [])

  useEffect(() => subscribe(callback), [])
}
