import { EventType } from './events';
import { EventBus } from './EventBus';


export function getEvent<T>(name: EventType) {
  return {
    send(payload: T): void {
      EventBus.send(name, payload);
    },
    subscribe(callback: (payload: T) => void): void {
      return EventBus.subscribe(name, callback);
    },
    unsubscribe(): void {
      EventBus.unsubscribe(name)
    }
  }
}
