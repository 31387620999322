import * as PIXI from 'pixi.js';
import { EventType, getEvent } from '../../../GameEventBus';
import { LocalStorage } from '../../../utils/localStorage';
import { Game } from "../../Game";
import { staticTextFooterVerticalStyles, dynamicTextFooterVerticalStyles } from '../../styles';

class FooterForMobileVertical {
    game: Game;
    footer: PIXI.Container;
    footerBG: PIXI.Graphics;
    footerHeight: number;
    footerWidth: number;
    totalBetContainer: PIXI.Container;
    totalBetText: PIXI.Text;
    totalBetValue: PIXI.Text;
    totalWinContainer: PIXI.Container;
    totalWinText: PIXI.Text;
    totalWinValue: PIXI.Text;
    totalCreditContainer: PIXI.Container;
    totalCreditText: PIXI.Text;
    totalCreditValue: PIXI.Text;
    totalBetList: number[];
    currentBetValue: number;
    selectedIndex: number;

    constructor(game: Game) {
        this.game = game;
        this.totalBetList = [0];
        this.selectedIndex = LocalStorage.getItem('chosenBet')  | 0;
        this.currentBetValue = this.totalBetList[this.selectedIndex]
        this.footer = new PIXI.Container();
        this.footerBG = new PIXI.Graphics();
        this.footerWidth = 1920;
        this.footerHeight = 240;

        this.totalBetContainer = new PIXI.Container();
        this.totalBetText = new PIXI.Text('TOTAL BET', staticTextFooterVerticalStyles);
        this.totalBetValue = new PIXI.Text(`${this.currentBetValue} USD`, dynamicTextFooterVerticalStyles);

        this.totalWinContainer = new PIXI.Container();
        this.totalWinText = new PIXI.Text('WIN', staticTextFooterVerticalStyles);
        this.totalWinValue = new PIXI.Text('0.00 USD', dynamicTextFooterVerticalStyles);

        this.totalCreditContainer = new PIXI.Container();
        this.totalCreditText = new PIXI.Text('CREDIT', staticTextFooterVerticalStyles);
        this.totalCreditValue = new PIXI.Text('8,854.20 USD', dynamicTextFooterVerticalStyles);
    }

    init = async () => {
        await this.setupFooterContainer();
        await this.setupFooterBG();
        await this.setupTotalBetText();
        await this.setupWinText();
        await this.setupCreditText();

        await this.game.assetsManager.gameContainer.addChild(this.footer);
        await this.game.resizeCallbacks.push(this.resize);

        getEvent<number>(EventType.CHOSEN_BET).subscribe(value => {
            this.setTotalBet(value, 'USD')
        })


    }

    setupFooterContainer = () => {
        this.footer.width = this.footerWidth;
        this.footer.height = this.footerHeight;
        this.footer.pivot.set(this.footerWidth / 2, this.footerHeight / 2);
        this.footer.x = this.footerWidth / 2;
        this.footer.y = 2120;
        this.footer.name = 'FOOTER_FOR_MOBILE_VERTICAL';
    }

    setupFooterBG = () => {
        this.footerBG.beginFill(0x000000);
        this.footerBG.drawRect(0, 0, this.footerWidth, this.footerHeight);
        this.footerBG.endFill();
        this.footer.addChild(this.footerBG);
    }

    setupTotalBetText = () => {
        this.totalBetContainer.width = this.footerWidth * 0.15;
        this.totalBetContainer.height = this.footerHeight;
        this.totalBetContainer.pivot.set(this.footerWidth * 0.15 / 2, this.footerHeight / 2)
        this.totalBetContainer.x = this.footerWidth * 0.05;
        this.totalBetContainer.y = this.footerHeight / 1.69999;

        this.totalBetText.x = this.footerWidth * 0.15 / 2;
        this.totalBetText.y = this.footerHeight * 0.03;

        this.totalBetValue.x = this.footerWidth * 0.15 / 2;
        this.totalBetValue.y = this.footerHeight * 0.448;

        this.totalBetContainer.addChild(this.totalBetText);
        this.totalBetContainer.addChild(this.totalBetValue);
        this.footer.addChild(this.totalBetContainer);
    }

    setupWinText = () => {
        this.totalWinContainer.width = this.footerWidth * 0.15;
        this.totalWinContainer.height = this.footerHeight;
        this.totalWinContainer.pivot.set(this.footerWidth * 0.15 / 2, this.footerHeight / 2)
        this.totalWinContainer.x = this.footerWidth / 2;
        this.totalWinContainer.y = this.footerHeight / 2 * 1.399999;

        this.totalWinText.anchor.set(0.5)
        this.totalWinText.x = this.footerWidth * 0.15 / 2;
        this.totalWinText.y = this.footerHeight * 0.1;

        this.totalWinValue.anchor.set(0.5);
        this.totalWinValue.x = this.footerWidth * 0.15 / 2;
        this.totalWinValue.y = this.footerHeight * 0.5;

        this.totalWinContainer.addChild(this.totalWinText);
        this.totalWinContainer.addChild(this.totalWinValue);
        this.footer.addChild(this.totalWinContainer);
    }


    setupCreditText = () => {
        this.totalCreditContainer.width = this.footerWidth * 0.15;
        this.totalCreditContainer.height = this.footerHeight;
        this.totalCreditContainer.pivot.set(this.footerWidth * 0.15 / 2, this.footerHeight / 2)
        this.totalCreditContainer.x = this.footerWidth * 0.95;
        this.totalCreditContainer.y = this.footerHeight * 0.7;

        this.totalCreditText.anchor.set(1, 0.5)
        this.totalCreditText.x = this.footerWidth * 0.15 / 2;
        this.totalCreditText.y = this.footerHeight * 0.1;

        this.totalCreditValue.anchor.set(1, 0.5)
        this.totalCreditValue.x = this.footerWidth * 0.15 / 2;
        this.totalCreditValue.y = this.footerHeight * 0.5;

        this.totalCreditContainer.addChild(this.totalCreditText);
        this.totalCreditContainer.addChild(this.totalCreditValue);
        this.footer.addChild(this.totalCreditContainer);
    }

    setTotalBet = (value: number, quantity: string) => {
        this.currentBetValue = this.totalBetList[value];
        this.totalBetValue.text = `${this.currentBetValue?.toFixed(2)} ${quantity}`;
    }

    changeBalance = (value: number) => {
        this.totalCreditValue.text = `${value?.toFixed(2)} USD`;
    }

    setBetList = (value: number[]) => {
        this.totalBetList = value;
        this.currentBetValue = this.totalBetList[this.selectedIndex];
        this.totalBetValue.text = `${this.currentBetValue?.toFixed(2)} USD`;
    }

    changeWin = (value: number) => {
        this.totalWinValue.text = `${value.toFixed(2)} USD`
    }

    resize = () => {
        let ratio = window.innerWidth / window.innerHeight;
        if (ratio <= 0.76) {
            this.footer.visible = true;
            return;
        }
        this.footer.visible = false;
    }
}

export default FooterForMobileVertical;
