import { FC } from 'react';

import iconPrev from '../../../assets/icons/arrow-left.svg';
import iconPrevHover from '../../../assets/icons/arrow-left-hover.svg';
import iconNext from '../../../assets/icons/arrow-right.svg';
import iconNextHover from '../../../assets/icons/arrow-right-hover.svg';
import styled from "styled-components";

interface ComponentProps {
    direction: string;
    handleCLick: () => void;
}

const SliderButton: FC<ComponentProps> = ({ direction, handleCLick }) => {
    return (
        <NavButton>
            <NavButtonBorder>
                <NavButtonBody className={'nav-btn-hover'} onClick={handleCLick}>
                    <img
                        src={direction === 'next' ? iconNext : iconPrev}
                        alt={'Prev'}
                        draggable={false}
                    />
                    <img
                        src={direction === 'next' ? iconNextHover : iconPrevHover}
                        alt={'Prev'}
                        draggable={false}
                    />
                </NavButtonBody>
            </NavButtonBorder>
        </NavButton>
    );
};

export default SliderButton;

const NavButton = styled.div`
	border-radius: 50%;
	height: calc(100vw / (1920 / 60));
	width: calc(100vw / (1920 / 60));
	background: rgba(21, 21, 21, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	cursor: pointer;
	img {
		display: inline-block;
		height: calc(100vw / (1920 / 23));
		width: calc(100vw / (1920 / 27));
	}
	img:last-child {
		display: none;
	}
	&:hover {
		.nav-btn-hover {
			background: #e2f4f2;
			border: 2px solid rgba(21, 21, 21, 0.8);
			img {
				display: none;
			}
			img:last-child {
				transition: all ease 0.5s;
				display: inline-block;
			}
		}
	}
`;

const NavButtonBorder = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	height: calc(100vw / (1920 / 52));
	width: calc(100vw / (1920 / 52));
	background: conic-gradient(
		from 180deg at 50% 50%,
		#af70c1 0deg,
		#45d1bd 125.63deg,
		#f9ad29 266.25deg,
		#af70c1 360deg
	);
`;

const NavButtonBody = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100vw / (1920 / 48));
	width: calc(100vw / (1920 / 48));
	border-radius: 50%;
	background: #151515;
`;
