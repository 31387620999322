import {Loader} from "pixi.js";
import Chest from '../assets/symbols/CHEST.png'

import one from "../assets/free_spins_summary/numbers/1.png"
import two from "../assets/free_spins_summary/numbers/2.png"
import three from "../assets/free_spins_summary/numbers/3.png"
import four from "../assets/free_spins_summary/numbers/4.png"
import five from "../assets/free_spins_summary/numbers/5.png"
import six from "../assets/free_spins_summary/numbers/6.png"
import seven from "../assets/free_spins_summary/numbers/7.png"
import eight from "../assets/free_spins_summary/numbers/8.png"
import nine from "../assets/free_spins_summary/numbers/9.png"
import zero from "../assets/free_spins_summary/numbers/0.png"
import dot from '../assets/free_spins_summary/numbers/dot.png'



export enum SYMBOLS_ASSETS {
    ONE_LOW = 'ONE_LOW',
    TWO_LOW = 'TWO_LOW',
    THREE_LOW = 'THREE_LOW',
    FOUR_LOW = 'FOUR_LOW',
    ONE_HIGH = 'ONE_HIGH',
    TWO_HIGH = 'TWO_HIGH',
    THREE_HIGH = 'THREE_HIGH',
    FOUR_HIGH = 'FOUR_HIGH',
    WILD = "WILD",
    CHEST = "CHEST",
    SCATTER = "SCATTER",
    ONE = "ONE",
    TWO = "TWO",
    THREE = "THREE",
    FOUR = "FOUR",
    FIVE = "FIVE",
    SIX = "SIX",
    SEVEN = "SEVEN",
    EIGHT = "EIGHT",
    NINE = "NINE",
    ZERO = "ZERO",
    DOT = "DOT"
}

export const loadSymbols = (loader: Loader) => {
    loader.add(SYMBOLS_ASSETS.ONE_LOW, "/symbols/L1/L1.json")
    loader.add(SYMBOLS_ASSETS.TWO_LOW, "/symbols/L2/L2.json")
    loader.add(SYMBOLS_ASSETS.THREE_LOW, "/symbols/L3/L3.json")
    loader.add(SYMBOLS_ASSETS.FOUR_LOW, "/symbols/L4/L4.json")
    loader.add(SYMBOLS_ASSETS.ONE_HIGH, '/symbols/H1/H1.json')
    loader.add(SYMBOLS_ASSETS.TWO_HIGH, '/symbols/H2/H2.json')
    loader.add(SYMBOLS_ASSETS.THREE_HIGH, '/symbols/H3/H3.json')
    loader.add(SYMBOLS_ASSETS.FOUR_HIGH, '/symbols/H4/H4.json')
    loader.add(SYMBOLS_ASSETS.SCATTER, '/symbols/Scatter/Scatter.json')
    loader.add(SYMBOLS_ASSETS.WILD, '/symbols/WILD/Wild.json')
    loader.add(SYMBOLS_ASSETS.CHEST, Chest)
    loader.add(SYMBOLS_ASSETS.ONE, one)
    loader.add(SYMBOLS_ASSETS.TWO, two)
    loader.add(SYMBOLS_ASSETS.THREE, three)
    loader.add(SYMBOLS_ASSETS.FOUR, four)
    loader.add(SYMBOLS_ASSETS.FIVE, five)
    loader.add(SYMBOLS_ASSETS.SIX, six)
    loader.add(SYMBOLS_ASSETS.SEVEN, seven)
    loader.add(SYMBOLS_ASSETS.EIGHT, eight)
    loader.add(SYMBOLS_ASSETS.NINE, nine)
    loader.add(SYMBOLS_ASSETS.ZERO, zero)
    loader.add(SYMBOLS_ASSETS.DOT, dot)
}
