import * as PIXI from "pixi.js"
import { Texture } from "pixi.js";
import { MODAL_ASSETS } from "../../assetsLoader/modalLoader"
import { Game } from "../../Game";
import { Timer } from "../../../utils/Timer";

class WinModal {
    game: Game;
    overlay: PIXI.Graphics;
    container: PIXI.Container;

    modalContainer: PIXI.Container;

    numbers: PIXI.Container;

    bigWin: PIXI.Sprite;
    megaWin: PIXI.Sprite;
    superWin: PIXI.Sprite;

    modalNumbersTextures: any;
    modalNumbers: Map<string, Texture>;
    valueAnimIsFinished: boolean;
    isSetWinAmountStated: boolean;

    constructor(game: Game) {
        this.game = game;
        this.overlay = new PIXI.Graphics();
        this.container = new PIXI.Container();

        this.modalContainer = new PIXI.Container();

        this.numbers = new PIXI.Container();

        this.bigWin = new PIXI.Sprite(Texture.from(MODAL_ASSETS.BIG_WIN));
        this.megaWin = new PIXI.Sprite(Texture.from(MODAL_ASSETS.MEGA_WIN));
        this.superWin = new PIXI.Sprite(Texture.from(MODAL_ASSETS.SUPER_WIN));

        this.modalNumbersTextures = this.game.app.loader.resources.BW_SHEET.spritesheet?.textures;
        this.modalNumbers = new Map();

        this.valueAnimIsFinished = false;
        this.isSetWinAmountStated = false;
    }

    init = () => {
        this.setupOverlay();
        this.setupContainer();
        this.setupModalContainer();
        this.setupModalText();
        this.setupNumbers();

        this.game.app.stage.addChild(this.overlay);
        this.game.app.stage.addChild(this.container);

        this.resize();
        window.addEventListener('load', this.resize)
        window.addEventListener('resize', this.resize)
    }


    setupOverlay = () => {
        this.overlay.beginFill(0x151515);
        this.overlay.drawRect(0, 0, window.innerWidth, window.innerHeight);
        this.overlay.endFill();
        this.overlay.alpha = 0.65;

        this.overlay.interactive = true;
        this.overlay.cursor = 'pointer';

        this.overlay.on('click', () => this.closeModal());
        this.overlay.on('touchstart', () => this.closeModal());

        this.overlay.visible = false;
    }

    setupContainer = () => {
        this.container.width = 1920;
        this.container.height = 1080;
        this.container.pivot.set(950, 540);
        this.container.name = "WIN_MODALS";

        this.container.interactive = true;
        this.container.cursor = 'pointer';

        this.container.on('click', () => this.closeModal());
        this.container.on('touchstart', () => this.closeModal());
        this.container.visible = false;

    }


    setupModalContainer = () => {
        this.modalContainer.height = 755;
        this.modalContainer.width = 756;
        this.modalContainer.y = 350;
        this.modalContainer.x = 960;
        this.modalContainer.name = "WIN_MODALS_CONTAINER";



        this.numbers.name = "WIN_NUMBERS"

        this.modalContainer.addChild(this.numbers);


        this.container.addChild(this.modalContainer);

    }

    setupModalText = () => {
        this.bigWin.pivot.set(this.bigWin.width / 2, this.bigWin.height / 2);
        this.bigWin.name = "BIG_WIN"

        this.megaWin.pivot.set(this.megaWin.width / 2, this.megaWin.height / 2);
        this.megaWin.name = "MEGA_WIN"

        this.superWin.pivot.set(this.superWin.width / 2, this.superWin.height / 2);
        this.superWin.y = -40;
        this.superWin.name = "SUPER_WIN";

        this.bigWin.visible = false;
        this.megaWin.visible = false;
        this.superWin.visible = false;

        this.modalContainer.addChild(this.bigWin);
        this.modalContainer.addChild(this.superWin);
        this.modalContainer.addChild(this.megaWin);

    }

    setupNumbers = () => {
        this.modalNumbers.set("1", this.modalNumbersTextures["1_bw.png"])
        this.modalNumbers.set("2", this.modalNumbersTextures["2_bw.png"])
        this.modalNumbers.set("3", this.modalNumbersTextures["3_bw.png"])
        this.modalNumbers.set("4", this.modalNumbersTextures["4_bw.png"])
        this.modalNumbers.set("5", this.modalNumbersTextures["5_bw.png"])
        this.modalNumbers.set("6", this.modalNumbersTextures["6_bw.png"])
        this.modalNumbers.set("7", this.modalNumbersTextures["7_bw.png"])
        this.modalNumbers.set("8", this.modalNumbersTextures["8_bw.png"])
        this.modalNumbers.set("9", this.modalNumbersTextures["9_bw.png"])
        this.modalNumbers.set("0", this.modalNumbersTextures["0_bw.png"])
        this.modalNumbers.set(".", this.modalNumbersTextures["dot_bw.png"])
        this.modalNumbers.set(",", this.modalNumbersTextures["comma_bw.png"])
    }

    setWin = (digits: string, letterSpacing: number = 20) => {
        let xPos = new PIXI.Container();
        xPos.x = 0;
        xPos.y = 0;
        this.numbers.removeChildren()
        for (let i = 0; i < digits.length; i++) {
            let letter = new PIXI.Sprite(this.modalNumbers.get(digits[i].toLowerCase()))
            letter.position.copyFrom(xPos);
            letter.x += xPos.width + letterSpacing;
            letter.y = 0;
            this.numbers.addChild(letter)
            xPos = letter;
        }

        this.switchPos(this.numbers, digits.length)
        window.addEventListener('resize', () => this.switchPos(this.numbers, digits.length))

        xPos = new PIXI.Container();
        xPos.destroy();
    }

    closeModal = () => {
        this.overlay.interactive = false;
        this.container.interactive = false;
        this.valueAnimIsFinished = true;
        let timer = new Timer(() => {
            this.overlay.visible = false;
            this.container.visible = false;
            this.game.assetsManager.gameContainer.interactiveChildren = true;
            this.valueAnimIsFinished = false;
            this.clearNumbers();
        }, 1000)
        timer.initialize();
    }


    switchPos = (numbers: PIXI.Container, lineLength: number) => {
        switch (lineLength) {
            case 3:
                if (window.innerWidth / window.innerHeight <= 0.76) {
                    numbers.x = -135;
                } else {
                    numbers.x = -190;
                }

                break;
            case 4:
                if (window.innerWidth / window.innerHeight <= 0.76) {
                    numbers.x = -165;
                } else {
                    numbers.x = -230;
                }

                break;
            case 5:
                if (window.innerWidth / window.innerHeight <= 0.76) {
                    numbers.x = -195;
                } else {
                    numbers.x = -295;
                }

                break;
            case 6:
                if (window.innerWidth / window.innerHeight <= 0.76) {
                    numbers.x = -245;
                } else {
                    numbers.x = -350;
                }

                break;
            case 7:
                if (window.innerWidth / window.innerHeight <= 0.76) {
                    numbers.x = -290;
                } else {
                    numbers.x = -415;
                }

                break;
            case 8:
                if (window.innerWidth / window.innerHeight <= 0.76) {
                    numbers.x = -330;
                } else {
                    numbers.x = -475;
                }

                break;
            case 9:
                if (window.innerWidth / window.innerHeight <= 0.76) {
                    numbers.x = -365;
                } else {
                    numbers.x = -515;
                }

                break;
            case 10:
                if (window.innerWidth / window.innerHeight <= 0.76) {
                    numbers.x = -425;
                } else {
                    numbers.x = -590;
                }

                break;
            case 11:
                if (window.innerWidth / window.innerHeight <= 0.76) {
                    numbers.x = -455;
                } else {
                    numbers.x = -650;
                }
                break;
            default:
                numbers.x = 0;
                break;
        }
    }

    setWinAmount = (value: number) => {
        let animatedValue = 0;
        this.isSetWinAmountStated = true;
        const animValue = (start: number, end: number, duration: number) => {
            let startTimestamp: any = null;
            const step = (timestamp: any) => {
                if (!startTimestamp) startTimestamp = timestamp;
                let progress = Math.min((timestamp - startTimestamp) / duration, 1)
                animatedValue = Number((progress * (end - start) + start).toFixed(2))
                if ((progress < 1) && !this.valueAnimIsFinished) {
                    window.requestAnimationFrame(step)
                } else {
                    animatedValue = value;
                    let timer = new Timer(() => {
                        let timer = new Timer(() => {
                            this.closeModal();
                        }, 1000)
                        timer.initialize();

                    }, 1)
                    timer.initialize();
                }

                this.setWin(`${Number(animatedValue).toFixed(2)}`);

            }
            window.requestAnimationFrame(step);
        }
        animValue(0, value, 5000);
    }

    openModal = (winType: string, amount: number ) => {
        this.setWinType(winType)
        this.setWinAmount(amount)
        this.game.assetsManager.gameContainer.interactiveChildren = false;
        this.overlay.interactive = true;
        this.container.interactive = true;
        this.overlay.visible = true;
        this.container.visible = true;
        this.clearNumbers();
    }

    setWinType = (winType: string) => {
        if (winType === "Big Win") {
            this.bigWin.visible = true;
            this.megaWin.visible = false;
            this.superWin.visible = false;
        }

        if (winType === 'Mega Win') {
            this.bigWin.visible = false;
            this.megaWin.visible = true;
            this.superWin.visible = false;
        }

        if (winType === 'Super Win') {
            this.bigWin.visible = false;
            this.megaWin.visible = false;
            this.superWin.visible = true;
        }
    }
    clearNumbers = () => {
        let timer = new Timer(() => {
            this.numbers.removeChildren();
        }, 100)
        timer.initialize();
    }

    resize = () => {
        this.container.width = window.innerWidth;
        this.container.height = window.innerHeight;
        this.overlay.width = window.innerWidth;
        this.overlay.height = window.innerHeight;
        this.container.scale.x = window.innerWidth / 1920;
        this.container.scale.y = window.innerWidth / 1920;
        this.container.x = window.innerWidth / 2;
        this.container.y = window.innerHeight / 1920;


        if (window.innerWidth / window.innerHeight >= 1.7066666666666668) {
            this.container.scale.x = window.innerHeight / 1080 - 0.039;
            this.container.scale.y = window.innerHeight / 1080 - 0.039;
        }


        if (window.innerWidth / window.innerHeight <= 0.76) {
            this.modalContainer.scale.set(1.75);
            this.numbers.scale.set(0.7)
            this.modalContainer.y = 1280;
            this.superWin.y = -60;
            this.numbers.y = 350;


        } else {
            if (window.innerWidth <= 1080) {
                this.modalContainer.scale.set(0.8)
                this.numbers.scale.set(1)
                this.modalContainer.y = 1000;
                this.superWin.y = -40;
                this.numbers.y = 335;
            } else {
                this.modalContainer.scale.set(1)
                this.numbers.scale.set(1)
                this.modalContainer.y = 900;
                this.superWin.y = -40;
                this.numbers.y = 335;
            }
        }
    }


}

export default WinModal;
