import * as PIXI from "pixi.js"
import { MINIGAME_ASSETS } from "../assetsLoader/miniGameLoader";
import { Texture } from "pixi.js";

class Feature {
    featureID: number;
    featureContainer: PIXI.Container;
    featureBG: Texture;
    // fetureMask: Texture;

    icon: PIXI.Sprite;

    constructor (id: number) {
        this.featureID = id;
        this.featureContainer = new PIXI.Container();
        this.icon = new PIXI.Sprite();
        this.featureBG = Texture.from(MINIGAME_ASSETS.FB_FRAGMENT);
        // this.fetureMask = Texture.from(MINIGAME_ASSETS.FB_MASK);
    }

    init = async () => {
        this.setupFeatureContainer();
        this.setupFeature();
        // this.setupMask()
    }

    changeIcon = () => {
    }

    setupFeatureContainer = () => {
        this.featureContainer.width = 196;
        this.featureContainer.height = 146;
        this.featureContainer.name = "FEATURE_CONTAINER";
    }

    setupFeature = () => {
        const bg = new PIXI.Sprite(this.featureBG);
        this.featureContainer.addChild(bg);

    }

    // setupMask = () => {
    //     const mask = new PIXI.Sprite(this.fetureMask);
    //     this.featureContainer.mask = mask
    //     this.featureContainer.addChild(mask);
    // }

}

export { Feature }
