import {Loader} from "pixi.js";
import arrowDownIcon from '../assets/modal/auto-play-modal-arrow-small.svg'
import autoPLayStatus from '../assets/modal/auto-play-status.png'
import bg from "../assets/free_spins_summary/free_spins_summary_bg.png";
import youWon from "../assets/free_spins_summary/you_won.png"
import winStartModalText from "../assets/modal/win-start-modal-text.png";
import bigWin from "../assets/modal/big_win.png"
import megaWin from "../assets/modal/mega_win.png"
import superWin from "../assets/modal/super_win.png"

export enum MODAL_ASSETS {
    ARROW_DOWN_ICON = 'ARROW_DOWN_ICON',
    STATUS_ICON = 'STATUS_ICON',
    WIN_START_MODAL_TEXT = "WIN_START_MODAL_TEXT",
    MODAL_BACKGROUND = "MODAL_BACKGROUND",
    YOU_WON = "YOU_WON",
    BIG_WIN = "BIG_WIN",
    MEGA_WIN = "MEGA_WIN",
    SUPER_WIN = "SUPER_WIN",
    BW_SHEET = "BW_SHEET",
}

export const loadModalAssets = (loader: Loader) => {
    loader.add(MODAL_ASSETS.ARROW_DOWN_ICON, arrowDownIcon)
    loader.add(MODAL_ASSETS.STATUS_ICON, autoPLayStatus)
    loader.add(MODAL_ASSETS.MODAL_BACKGROUND, bg)
    loader.add(MODAL_ASSETS.YOU_WON, youWon)
    loader.add(MODAL_ASSETS.WIN_START_MODAL_TEXT, winStartModalText)
    loader.add(MODAL_ASSETS.BIG_WIN, bigWin)
    loader.add(MODAL_ASSETS.MEGA_WIN, megaWin)
    loader.add(MODAL_ASSETS.SUPER_WIN, superWin)
    loader.add(MODAL_ASSETS.BW_SHEET, "/bw/bw_numbers.json")
}
