import * as PIXI from 'pixi.js'
import { Game } from "../../Game";
import {Sprite, Texture} from "pixi.js";
import { UI_ASSETS } from "../../assetsLoader/UiAssets";

export class GameField {
    game: Game
    container: PIXI.Container
    background?: PIXI.Sprite
    border?: PIXI.Sprite
    backgroundPortrait?: PIXI.Sprite
    borderPortrait?: PIXI.Sprite
    width: number
    height: number
    mask?: Sprite
    constructor(game: Game) {
        this.game = game
        this.width = 1152
        this.height = 926
        this.container = new PIXI.Container();

    }

    init = () => {
        this.background = new PIXI.Sprite(Texture.from(UI_ASSETS.GAME_FIELD_BG))
        this.background.width = 1125
        this.background.height = 921
        this.background.x = 0
        this.background.y = 20

        this.border = new PIXI.Sprite(Texture.from(UI_ASSETS.GAME_FIELD))
        this.border.width = 1377
        this.border.height = 1021
        this.border.x = -125
        this.border.y = -45

        this.backgroundPortrait = new PIXI.Sprite(Texture.from(UI_ASSETS.GAME_FIELD_BG_PORTRAIT))
        this.backgroundPortrait.width = 1180
        this.backgroundPortrait.height = 977
        this.backgroundPortrait.x = -20
        this.backgroundPortrait.y = 0

        this.borderPortrait = new PIXI.Sprite(Texture.from(UI_ASSETS.GAME_FIELD_PORTRAIT))
        this.borderPortrait.width = 1212
        this.borderPortrait.height = 986
        this.borderPortrait.x = -41
        this.borderPortrait.y = -10

        this.container.width = this.width
        this.container.height = this.height
        this.container.addChild(this.background)
        this.container.addChild(this.border)
        this.container.addChild(this.backgroundPortrait)
        this.container.addChild(this.borderPortrait)
        this.container.x = 248
        this.container.y = 10
        this.container.name = 'GAME_FIELD'
        this.game.assetsManager.gameContainer.addChild(this.container)
        this.game.resizeCallbacks.push(this.resize)
    }

    setupMask = () => {
        if(!this.game.slotMachine) return
        this.mask = new PIXI.Sprite(Texture.from(UI_ASSETS.SHEET_MASK))
        this.mask.width = 1114
        this.mask.height = 900
        this.mask.x = 20
        this.mask.y = 16
        this.mask.zIndex = 1000

        this.game.slotMachine.reelsContainer.mask = this.mask
        this.game.slotMachine.reelsContainer.addChild(this.mask)
    }

    /**
     * function for adaptive description
     */

    resize = () => {
        if (!this.container || !this.background) return
        if (window.innerWidth / window.innerHeight <= 0.76) {
            this.container.scale.set(1.56)
            this.container.x = 80
            this.container.y = 235;
            this.background.alpha = 0
            this.border!.alpha = 0
            this.backgroundPortrait!.alpha = 1
            this.borderPortrait!.alpha = 1
        } else {
            this.background.alpha = 1
            this.border!.alpha = 1
            this.backgroundPortrait!.alpha = 0
            this.borderPortrait!.alpha = 0
            if (window.innerWidth <= 1080) {
                this.container.scale.set(0.91);
                this.container.y = 167;
                this.container.x = 450;
            } else {

                this.container.scale.set(1);
                this.container.x = 402
                this.container.y = 20
            }
        }

    }
}
