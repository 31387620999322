import styled from "styled-components";
import TokenStorage from "../../helpers/TokenStorage";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthorizationWindow = () => {
    const navigate = useNavigate();
    const [token, setToken] = useState('');

    const handleInput = useCallback((value: string) => {
        setToken(value)
    }, [setToken]);

    const handleLogin = (token: string) => {
        TokenStorage.setToken(token);
        navigate('/')
        window.location.reload();
    };

    return (
        <Container>
            <AuthorizationFormContainer>
                <Title>
                    Login
                </Title>
                <FormContainer>
                    <Form>
                        <Input id="input" type={'text'} placeholder={'Token'} value={token} onChange={(e) => handleInput(e.target.value)} name="token" />
                        <Button className="btn" onClick={() => handleLogin(token)}> <span style={{ margin: 'auto' }}>Login</span></Button>
                    </Form>
                </FormContainer>
            </AuthorizationFormContainer>

        </Container>
    );
}

export default AuthorizationWindow;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    background: white;
    left: 0;
    top: 0;
    `

const AuthorizationFormContainer = styled.div`
    margin: auto;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content-center;
    flex-direction: column;
    width: 384px;
    height: 288px;
    background: #fffffc;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    @media (max-width: 384px) {
        width: 90%;
      
    }
`

const Title = styled.h1`
    margin: auto;
    margin-top: 45px;
    font-size: 45px;
    font-family: Rubik, sans-serif;

    @media (max-width: 384px) {
       font-size: 40px;
    }

    @media (max-width: 150px) {
        font-size: 30px;
    }

     @media (max-width: 100px) {
        font-size: 20px;
    }
`

const FormContainer = styled.div`
    margin-top: 0;
    display: flex;
    width: 384px;
    height: 192px;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 384px) {
        width: 90%;
      
    }
`

const Form = styled.div`
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 384px) {
        width: 90%;
    }
`

const Input = styled.input`
    margin: auto;
    border: none;
    border-bottom: 1px solid rgba(206, 212, 218, 0.8);
    width: 288px;
    color: #acb5bd;
    font-family: Rubik;
    font-weight: 700;
    font-size: 15px;
    padding-bottom: 10px;
    margin-bottom: 35px;

    &::placeholder {
        color: #acb5bd;
        font-family: Rubik;
        font-weight: 700;
        font-size: 15px;
    }

    @media (max-width: 384px) {
        width: 90%;
    }
`


const Button = styled.div`
    border-radius: 5px;
    background: #228be6;
    position: relative;
    display: flex;
    juctify-content: center;
    cursor: pointer;
    width: 288px;
    padding: 10px;
    color: white;
    font-family: 'Rubik';
    font-weight: 600;
    font-size: 15px;

    &.btn:active {
        transform: translate(0em, 0.1em);
    }
    &.btn:active::before {
        transform: translate3d(0, 0, -1em);
    }

    @media (max-width: 384px) {
        width: 90%;
    }

`