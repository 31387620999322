import { FC } from 'react';
import styled from 'styled-components';

import infinityIcon from '../../../assets/icons/infinity.svg';
import infinityIconHover from '../../../assets/icons/infinity-hover.svg';
import {numberOfSpinsList} from "../constants";

interface INumberOfSpins {
    spinCount: string | number;
    changeSpinCount: Function;
}

const NumberOfSpins: FC<INumberOfSpins> = ({ spinCount, changeSpinCount }) => {
    return (
        <Wrapper>
            <Title>NUMBER OF SPINS</Title>
            <ButtonWrapper>
                {numberOfSpinsList.map(item => (
                    <Button
                        className={spinCount === item.spinsNumber ? 'active' : ''}
                        key={item.spinsNumber}
                        onClick={() => changeSpinCount(item.spinsNumber)}
                    >
                        {item.spinsNumber === 'ထ' ? (
                            <img src={infinityIcon} alt='Infinity' />
                        ) : (
                            item.spinsNumber
                        )}
                    </Button>
                ))}
            </ButtonWrapper>
        </Wrapper>
    );
};

export default NumberOfSpins;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	padding-top: 16px;
`;

const Title = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 30px;
	background: #2a2c2b;
	border-radius: 5px;
	font-family: 'Rubik';
	font-weight: 700;
	font-size: 13px;
	line-height: 13px;
	color: #7c8584;
	margin-bottom: 8px;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	.active {
		color: #e2f4f2;
		border-color: #e2f4f2;
		img {
			content: url(${infinityIconHover});
		}
	}
`;

const Button = styled.button`
	border: 2px solid #535858;
	border-radius: 5px;
	width: 43px;
	height: 32px;
	color: #535858;
	background: #151515;
	font-family: 'Rubik';
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	font-size: 13px;
	line-height: 13px;
	cursor: pointer;
	:hover {
		color: #e2f4f2;
		border-color: #e2f4f2;
		img {
			content: url(${infinityIconHover});
		}
	}
`;

