import gsap from 'gsap';
import * as PIXI from 'pixi.js';
import { Application, Texture } from 'pixi.js';
import { Game } from '../../Game';
import { labelHoverStyles, labelStyles } from './style';
import { BUTTONS_ASSETS } from "../../assetsLoader/ButtonsLoader";

class CustomButton {
	app: Application;
	game: Game;
	width: number;
	height: number;
	button: PIXI.Container;
	pointerIsDown: boolean;
	pointerIsOver: boolean;
	buttonDefault: PIXI.Sprite;
	buttonHover: PIXI.Sprite;
	buttonDisabled: PIXI.Sprite;
	label?: PIXI.Text;
	labelHover?: PIXI.Text;

	constructor(
		game: Game,
		app: Application,
		width: number,
		height: number,
		label?: string
	) {
		this.game = game;
		this.app = app;
		this.width = width;
		this.height = height;
		this.pointerIsDown = false;
		this.pointerIsOver = false;
		this.buttonDefault = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.CONFIRM_BTN_DEFAULT))
		this.buttonHover = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.CONFIRM_BTN_HOVER))
		this.buttonDisabled = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.CONFIRM_BTN_DISABLED))
		this.label = new PIXI.Text(label, labelStyles);
		this.labelHover = new PIXI.Text(label, labelHoverStyles);
		this.button = new PIXI.Container();
	}

	initialize = () => {
		this.button.width = this.width;
		this.button.height = this.height;

		this.buttonDefault.width = this.width;
		this.buttonDefault.height = this.height;

		this.buttonHover.width = this.width;
		this.buttonHover.height = this.height;

		this.buttonDisabled.width = this.width;
		this.buttonDisabled.height = this.height;

		this.button.interactive = true;
		this.button.buttonMode = true;

		this.label!.x = this.width / 2 - this.label?.width! / 2;
		this.label!.y = this.height / 2 - this.label?.height! / 2;

		this.labelHover!.x = this.width / 2 - this.label?.width! / 2;
		this.labelHover!.y = this.height / 2 - this.label?.height! / 2;

		this.labelHover!.alpha = 0;
		this.buttonHover.alpha = 0;
		this.buttonDisabled.alpha = 0;

		this.button.addChild(this.buttonDefault!);
		this.button.addChild(this.buttonHover!);
		this.button.addChild(this.buttonDisabled!);
		this.buttonDefault.addChild(this.label!);
		this.buttonHover.addChild(this.labelHover!);

		this.button
			.on('pointerup', () => this.doPointerUp(this.app))
			.on('pointerupoutside', () => this.doPointerUpOutside(this.app))
			.on('touchstart', () => this.doPointerDown(this.app))
			.on('click', (e) => this.leftClick(e))
			.on('pointerover', () => this.doPointerOver(this.app))
			.on('pointerout', () => this.doPointerOut(this.app));
	};

	leftClick = (e:any) => {
		if(e.data.button === 0){
			this.doPointerDown(this.app)
		};
	}

	doPointerUp(app: Application) {
		if (this.pointerIsOver) {
			let wiggleTween = gsap.timeline();
			wiggleTween.fromTo(
				this.labelHover!,
				0,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
			wiggleTween.fromTo(this.label!, 0, { alpha: 1 }, { alpha: 0, repeat: 0 });
			wiggleTween.fromTo(
				this.buttonHover,
				0,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
			wiggleTween.fromTo(
				this.buttonDefault,
				0,
				{ alpha: 1 },
				{ alpha: 0, repeat: 0 }
			);
		} else {
			let wiggleTween = gsap.timeline();
			wiggleTween.fromTo(
				this.buttonDefault,
				0,
				{ alpha: 0 },
				{ alpha: 1, repeat: 0 }
			);
			wiggleTween.fromTo(this.label!, 0, { alpha: 0 }, { alpha: 1, repeat: 0 });
		}
		this.pointerIsDown = false;
	}

	doPointerUpOutside(app: Application) {
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.buttonDefault,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		wiggleTween.fromTo(this.label!, 0, { alpha: 0 }, { alpha: 1, repeat: 0 });
		this.pointerIsDown = false;
		this.pointerIsOver = false;
	}

	doPointerDown(app: Application) {
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.labelHover!,
			0,
			{ alpha: 1 },
			{ alpha: 0, repeat: 0 }
		);
		wiggleTween.fromTo(this.label!, 0, { alpha: 0 }, { alpha: 1, repeat: 0 });
		wiggleTween.fromTo(
			this.buttonHover,
			0,
			{ alpha: 1 },
			{ alpha: 0, repeat: 0 }
		);
		wiggleTween.fromTo(
			this.buttonDefault,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		this.pointerIsDown = true;
	}

	doPointerOver(app: Application) {
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.buttonHover,
			0.3,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		wiggleTween.fromTo(
			this.labelHover!,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		this.pointerIsOver = true;
	}

	doPointerOut(app: Application) {
		let wiggleTween = gsap.timeline();
		wiggleTween.fromTo(
			this.labelHover!,
			0,
			{ alpha: 1 },
			{ alpha: 0, repeat: 0 }
		);
		wiggleTween.fromTo(this.label!, 0, { alpha: 0 }, { alpha: 1, repeat: 0 });
		wiggleTween.fromTo(
			this.buttonHover,
			0.3,
			{ alpha: 1 },
			{ alpha: 0, repeat: 0 }
		);
		wiggleTween.fromTo(
			this.buttonDefault,
			0,
			{ alpha: 0 },
			{ alpha: 1, repeat: 0 }
		);
		if (!this.pointerIsDown) {
			this.pointerIsOver = false;
		}
	}
}

export default CustomButton;
