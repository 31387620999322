import * as PIXI from "pixi.js";
import Feature from "../FeaturesBar/FeaturesBar";
import { Game } from "../../Game";
import { EventType, getEvent } from "../../../GameEventBus";
import { BUTTONS_ASSETS } from "../../assetsLoader/ButtonsLoader";
import { Texture } from "pixi.js";
import { Timer } from "../../../utils/Timer";

class MinigameSummaryModal {
    game: Game;
    overlay: PIXI.Graphics;
    container: PIXI.Container;
    modalContainer: PIXI.Container;
    feature?: Feature;
    titleText: PIXI.Text;
    modal: PIXI.Graphics;
    btn: PIXI.Container;
    btnDefault: PIXI.Sprite;
    btnHover: PIXI.Sprite
    btnText: PIXI.Text;
    featuresHollow: PIXI.Container;
    featuresObtained: PIXI.Container;
    isRespinMinigame: boolean

    constructor(game: Game) {
        this.game = game;
        this.overlay = new PIXI.Graphics();
        this.container = new PIXI.Graphics();

        this.modalContainer = new PIXI.Container();
        this.titleText = new PIXI.Text('CONGRATULATIONS!', {
            fontFamily: 'Rubik',
            fontSize: 45,
            fontWeight: '700',
            fill: 0xe4f2f4
        })
        this.modal = new PIXI.Graphics();

        this.btn = new PIXI.Container();
        this.btnDefault = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.CONFIRM_BTN_DEFAULT))
        this.btnHover = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.CONFIRM_BTN_HOVER))
        this.btnText = new PIXI.Text('START', {
            fontFamily: 'Rubik',
            fontSize: 25,
            fontWeight: '700',
            fill: 0xe4f2f4
        });

        this.featuresHollow = new PIXI.Container();
        this.featuresObtained = new PIXI.Container();
        this.isRespinMinigame = false;
    }

    init = () => {
        this.setupOverlay();
        this.setupContainer();
        this.setupModalContainer();
        this.setupModal();
        this.setupText();
        this.setupHollowFeaturesContainer();
        this.setupObtainedFeaturesContainer();
        this.setupHollowSpots();
        this.setupModalBtn();
        this.setupBtnDeafult();
        // this.setupBtnHover();
        this.setupBtnText();

        this.game.app.stage.addChild(this.overlay);
        this.game.app.stage.addChild(this.container);
        this.resize();
        window.addEventListener('load', this.resize)
        window.addEventListener('resize', this.resize)

        getEvent<boolean>(EventType.RESPIN_MINIGAME_ENDED).subscribe(value => {
            if (value) {
                this.overlay.visible = true;
                this.container.visible = true;
            }
            this.isRespinMinigame = value;
        })

        getEvent<boolean>(EventType.MINIGAME_ENDED).subscribe(value => {
            const timer = new Timer(() => {
                if (value) {

                    this.overlay.visible = true;
                    this.container.visible = true;
                }
            }, 1000)
            timer.initialize()

        })
        getEvent<[string, any]>(EventType.FEATURE_OBTAINED).subscribe(value => {
            this.addObtainedFeature(value)
        })
    }

    setupOverlay = () => {
        this.overlay.beginFill(0x151515);
        this.overlay.drawRect(0, 0, window.innerWidth, window.innerHeight);
        this.overlay.endFill();
        this.overlay.alpha = 0.8;
        this.overlay.visible = false;
    }

    setupContainer = () => {
        this.container.width = 1920;
        this.container.height = 1080;
        this.container.pivot.set(950, 540)
        this.container.visible = false;
    }

    setupModalContainer = () => {
        this.modalContainer.width = 750;
        this.modalContainer.height = 550;
        this.modalContainer.pivot.set(375, 225);
        this.modalContainer.x = 960;
        this.modalContainer.y = 540;
        this.container.addChild(this.modalContainer)
    }

    setupModal = () => {
        this.modal.lineStyle(1, 0x000, 0.2)
        this.modal.beginFill(0x151515);
        this.modal.drawRoundedRect(0, 0, 750, 550, 10);
        this.modal.endFill();
        this.modalContainer.addChild(this.modal);
    }

    setupText = () => {
        this.titleText.x = 375 - this.titleText.width / 2
        this.titleText.y = 35;
        this.modalContainer.addChild(this.titleText);
    }

    setupHollowFeaturesContainer = () => {
        this.featuresHollow.width = 750;
        this.featuresHollow.height = 350;
        this.featuresHollow.y = 100;
        this.modalContainer.addChild(this.featuresHollow)
    }

    setupObtainedFeaturesContainer = () => {
        this.featuresObtained.width = 750;
        this.featuresObtained.height = 350;
        this.featuresObtained.y = 100;
        this.modalContainer.addChild(this.featuresObtained)
    }

    setupHollowSpots = () => {
        for (let i = 0; i < 5; i++) {
            const hollowFeatureContainer = new PIXI.Container();
            const hollowFeature = new PIXI.Graphics();
            hollowFeature.beginFill(0xe4f2f4)
            hollowFeature.drawCircle(0, 0, 30);
            hollowFeature.endFill();
            hollowFeature.alpha = 0.3;
            hollowFeatureContainer.width = 35;
            hollowFeatureContainer.height = 35;
            hollowFeatureContainer.y = 70 * i + 35;
            hollowFeatureContainer.x = 100;
            hollowFeatureContainer.addChild(hollowFeature)
            this.featuresHollow.addChild(hollowFeatureContainer);
        }
    }

    setupModalBtn = () => {
        this.btnDefault.scale.set(1.2);
        this.btn.width = this.btnDefault.width;
        this.btn.height = this.btnDefault.height;
        this.btn.x = 375 - this.btnDefault.width / 2;
        this.btn.y = 455;
        this.btn.interactive = true;
        this.btn.cursor = 'pointer';
        this.btn.on('pointerdown', this.endMiniGame)
        this.modalContainer.addChild(this.btn);
    }

    setupBtnDeafult = () => {
        this.btn.addChild(this.btnDefault);
    }

    setupBtnHover = () => {
        this.btnHover.scale.set(1.2);
        this.btn.addChild(this.btnHover);
    }


    setupBtnText = () => {
        this.btnText.x = this.btnDefault.width / 2 - this.btnText.width / 2;
        this.btnText.y = this.btnDefault.height / 2 - this.btnText.height / 2;
        this.btn.addChild(this.btnText)
    }

    addObtainedFeature = (feature: [string, number | boolean]) => {
        const index = this.featuresObtained.children.length;
        const hollowFetureCords = this.featuresHollow.getChildAt(index)

        const obtainedFeatureContainer = new PIXI.Container();
        const obtainedFeature = new PIXI.Graphics();

        const featureName = new PIXI.Text(`${feature[0]} ${feature[1]}`, {
            fontFamily: 'Rubik',
            fontSize: 25,
            fill: 0xe4f2f4
        })

        featureName.x = 55;
        featureName.y = -15;

        obtainedFeatureContainer.width = 700;
        obtainedFeatureContainer.height = 35;

        obtainedFeature.beginFill(0xffd700);
        obtainedFeature.drawCircle(0, 0, 30);

        obtainedFeatureContainer.addChild(obtainedFeature);
        obtainedFeatureContainer.addChild(featureName)
        obtainedFeatureContainer.position.copyFrom(hollowFetureCords);
        this.featuresObtained.addChild(obtainedFeatureContainer)
    }

    endMiniGame = () => {
        this.featuresObtained.removeChildren();
        this.game.assetsManager.miniGame?.toggleMiniGame(false)
        this.overlay.visible = false;
        this.container.visible = false;
        this.game.assetsManager.miniGame!.pFeatureBar.hollowFeaturesContainer.y = 1520;
        this.game.assetsManager.miniGame!.pFeatureBar.featuresContainer.y = 1520;
        const timer = new Timer(() => {
            this.game.assetsManager.autoPlayBtn!.changeFSMode(true)
            this.game.assetsManager.playBtn!.changeButtonMode(this.isRespinMinigame ? 'default' : 'fs')
            this.game.assetsManager?.playBtn?.setSpinCounter(this.game.slotMachine?.freeSpinCount!);
            this.game.slotMachine?.onSpin()
        }, 300)
        timer.initialize()
    }

    resize = () => {
        this.container.width = window.innerWidth;
        this.container.height = window.innerHeight;
        this.overlay.width = window.innerWidth;
        this.overlay.height = window.innerHeight;
        this.container.scale.x = window.innerWidth / 1920;
        this.container.scale.y = window.innerWidth / 1920;

        this.container.x = window.innerWidth / 2;
        this.container.y = window.innerHeight / 1920;


        if (window.innerWidth / window.innerHeight >= 1.7066666666666668) {
            this.container.scale.x = window.innerHeight / 1080 - 0.039;
            this.container.scale.y = window.innerHeight / 1080 - 0.039;
        }


        if (window.innerWidth / window.innerHeight <= 0.76) {
            this.modalContainer.scale.set(1.75);
            this.modalContainer.y = 1414;
        } else {
            this.modalContainer.scale.set(1)
            this.modalContainer.y = 1060;
        }

    }
}

export default MinigameSummaryModal;
