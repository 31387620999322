import {Loader} from "pixi.js";
import chestClosed from "../assets/minigame/minigame_chest_closed.png"
import chestOpened from "../assets/minigame/minigame_chest_opened.png"
import fbFragment from "../assets/minigame/feature_bar/fb_fragment.png"
import fbMask from "../assets/minigame/feature_bar/fb_mask.png"
import minigameBG from "../assets/minigame/minigame_reels_bg.png"
import minigameBGPortrait from "../assets/minigame/minigame_reels_bg_portrait.png"
import bepBtn from "../assets/minigame/modal/buy_extra_pick_button.png"
import shine  from "../assets/minigame/win_feature_modal_elements/shine.png"
import popup_window  from "../assets/minigame/win_feature_modal_elements/popup_window.png"

export enum MINIGAME_ASSETS {
    CHEST_CLOSED = 'CHEST_CLOSED',
    CHEST_OPENED = "CHEST_OPENED",
    FB_FRAGMENT = "FB_FRAGMENT",
    FB_MASK = "FB_MASK",
    MINIGAME_BG = "MINIGAME_BG",
    MINIGAME_BG_PORTRAIT = "MINIGAME_BG_PORTRAIT",
    BEP_BTN = "BEP_BTN",
    FEATURE_SHINE = "FEATURE_SHINE",
    FEATURE_POPUP = "FEATURE_POPUP",
    SHINE_ICONS = "SHINE_ICONS",
    POPUP_ICONS = "POPUP_ICONS",
    FEATURES_SHEET = "FEATURES_SHEET"
}

export const miniGameLoader = (loader: Loader) => {
    loader.add(MINIGAME_ASSETS.CHEST_CLOSED, chestClosed)
    loader.add(MINIGAME_ASSETS.CHEST_OPENED, chestOpened)
    loader.add(MINIGAME_ASSETS.FB_FRAGMENT, fbFragment)
    loader.add(MINIGAME_ASSETS.FB_MASK, fbMask)
    loader.add(MINIGAME_ASSETS.MINIGAME_BG, minigameBG)
    loader.add(MINIGAME_ASSETS.MINIGAME_BG_PORTRAIT, minigameBGPortrait)
    loader.add(MINIGAME_ASSETS.BEP_BTN, bepBtn)
    loader.add(MINIGAME_ASSETS.FEATURE_SHINE, shine)
    loader.add(MINIGAME_ASSETS.FEATURE_POPUP, popup_window)
    loader.add(MINIGAME_ASSETS.POPUP_ICONS, "/minigame/popup.json")
    loader.add(MINIGAME_ASSETS.SHINE_ICONS, "/minigame/shine_icons.json")
    loader.add(MINIGAME_ASSETS.FEATURES_SHEET, "/feature_bar/fb_icons.json")
}
