import {SYMBOLS_ASSETS} from "../assetsLoader/SymbolsLoader";
import {SymbolName} from "../../api/types";

export interface Symbol {
    name: SymbolName,
    asset: string,
    id: string
}

export const TEST_SYMBOLS: SymbolName[][] = [
    ["L1", "L1", "H3", "L1"],
    ["L2", "L2", "H4", "L2"],
    ["WILD", "L3", "H3", "L3"],
    ["H1", "H3", "SCATTER", "H3"],
    ["H2", "L2", "H3", "L2"],
]

export const SYMBOL_SIZE = 216

export const SYMBOLS: Symbol[] = [
    {
        name: "L1",
        asset: SYMBOLS_ASSETS.ONE_LOW,
        id: "1",
    },
    {
        name: "L2",
        asset: SYMBOLS_ASSETS.TWO_LOW,
        id: "2",
    },
    {
        name: "L3",
        asset: SYMBOLS_ASSETS.THREE_LOW,
        id: "3",
    },
    {
        name: "L4",
        asset: SYMBOLS_ASSETS.FOUR_LOW,
        id: "4",
    },
    {
        name: "H1",
        asset: SYMBOLS_ASSETS.ONE_HIGH,
        id: "J",
    },
    {
        name: "H2",
        asset: SYMBOLS_ASSETS.TWO_HIGH,
        id: "Q",
    },
    {
        name: "H3",
        asset: SYMBOLS_ASSETS.THREE_HIGH,
        id: "K",
    },
    {
        name: "H4",
        asset: SYMBOLS_ASSETS.FOUR_HIGH,
        id: "A",
    },
    {
        name: "SCATTER",
        asset: SYMBOLS_ASSETS.SCATTER,
        id: "SCATTER",
    },
    {
        name: "WILD",
        asset: SYMBOLS_ASSETS.WILD,
        id: "WILD",
    },
    {
        name: "CHEST",
        asset: SYMBOLS_ASSETS.CHEST,
        id: "CHEST",
    },
]
