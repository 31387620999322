import {Game} from "../Game";
import {Reel} from "./Reel";
import {TEST_SYMBOLS} from "../constants/Symbols";
import * as PIXI from 'pixi.js'
import {ApiResponse, PlayResponse, SymbolName} from "../../api/types";
import {api} from "../../App";
import {LocalStorage} from "../../utils/localStorage";
import {EventType, getEvent} from "../../GameEventBus";
import {SlotEvents} from "./slotEvents";

let SPIN_DATA: any = undefined

interface SpinParams  {
    params?: {
        stopOnAnyWin: boolean
        stopOnFreeSpin: boolean
        singleWin: null | number
        lossLimit: null | number
        refreshLimit: boolean
    }
}
export class slotMachine {
    game: Game
    reels: Reel[]
    isSpinning: boolean
    reelsContainer: PIXI.Container
    balance: number
    bet: number
    symbols: SymbolName[][]
    spinData?: ApiResponse<PlayResponse>
    currentSpeed: number
    nextCommand: string
    slotEvents?: SlotEvents
    isFsRunning: boolean
    freeSpinCount?: number
    betList: number[]
    betIndex: number;
    requestParams?: SpinParams['params']

    constructor(game: Game) {
        this.game = game
        this.reels = []
        this.isSpinning = false
        this.reelsContainer = new PIXI.Container()
        this.reelsContainer.sortableChildren = true
        this.balance = 0;
        this.betIndex = LocalStorage.getItem('chosenBet') ? LocalStorage.getItem('chosenBet') : 0;
        this.symbols = []
        this.currentSpeed = 1
        this.nextCommand = ""
        this.betList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        this.bet = this.betList[this.betIndex]
        this.slotEvents = new SlotEvents(this)
        this.isFsRunning = false

    }

    init = async () => {
        if (!this.game.assetsManager.gameField) return
        this.reelsContainer.width = this.game.assetsManager.gameField.width
        this.reelsContainer.height = this.game.assetsManager.gameField.height
        this.reelsContainer.name = 'REELS_CONTAINER'
        this.game.assetsManager.gameField.container?.addChild(this.reelsContainer)


        await this.game.assetsManager.gameField.setupMask()

        for (let i = 0; i < TEST_SYMBOLS.length; i++) {
            const symbols = TEST_SYMBOLS[i]
            const reel = new Reel(this.game, i, this.reelsContainer)
            await reel.init()
            await reel.generateReels(symbols)
            await reel.placeSymbols(i)
            this.reels?.push(reel)
        }


        document.addEventListener('keydown', (e) => {
            if (this.isFsRunning) return;
            if (this.game.assetsManager.autoPlayModal?.isAutoPlayRunning) return;
            if (e.code === 'Space') this.onSpin()
        })

        this.changeBalance(0, 7700);
        this.setBetList(this.betList, this.betIndex);
    }


    onSpin = async (command = "" ) => {
        if (this.isSpinning) return
        if (this.game.assetsManager.autoPlayBtn?.isModalActive) return
        if (this.game.assetsManager.fss?.isModalActive) return

        this.isSpinning = true
        // this.game.assetsManager.changeButtonsState(false)
        this.slotEvents?.changeWin(0)


        if (!['minigame', 'buybonusspin', 'buyextrafeature', 'respinminigame', 'respin', 'freegame'].includes(this.nextCommand)) {
            this.changeBalance(-this.bet)
            this.reels.forEach((reel) => {
                reel.onStartAnimate(true)
            })
        }




        try {
            if (this.game.isDebug) {
                const data = localStorage.getItem('debugReels');
                const debugReels = JSON.parse(data!);
                this.spinData = await api.auth.playDebug(this.bet, 'debug', JSON.parse(debugReels.positions))
            } else {
                this.spinData = await api.auth.play(this.bet, this.nextCommand, this.requestParams)
            }
        } catch (e: any) {
            this.nextCommand = e.nextCommand[0]
            this.isSpinning = false
            await this.onSpin()
            return
        }




        if (!this.spinData?.results) {
            return
        }


        if (['respinminigame'].includes(this.nextCommand)) {
            if (!this.spinData.results[0].clientData.featuresDetails) return;
            this.isFsRunning = false;
            this.isSpinning = false;
            getEvent(EventType.SPIN_IN_PROGRESS).send(this.isSpinning);
            this.nextCommand = this.spinData.nextCommands[0];
            this.game.assetsManager.multiplier!.multiplier.renderable = true;
            this.slotEvents?.startRespinMinigame(this.spinData.results[0].clientData.featuresDetails);
            return;
        }


        if (['minigame'].includes(this.nextCommand)) {
            getEvent(EventType.RESPIN_MINIGAME_ENDED).send(false);
            this.freeSpinCount = this.spinData.results[0].clientData.freeSpin.added
            let extraFreeSpins = 0

            this.spinData.results[0].clientData.featuresDetails.forEach((feature) => {
                if (feature.name === 'ExtraFreespins') extraFreeSpins += feature.value
            })


            this.isFsRunning = true
            this.isSpinning = false
            getEvent(EventType.SPIN_IN_PROGRESS).send(this.isSpinning)
            this.nextCommand = this.spinData.nextCommands[0]
            this.game.assetsManager.multiplier!.multiplier.renderable = true;
            this.game.assetsManager.gameBG!.freeGameBG!.visible = true;
            this.game.assetsManager.miniGame?.replaceFeatures(this.spinData.results[0].clientData.featuresDetails)
            this.game.assetsManager.autoPlayBtn!.button.renderable = false;
            this.slotEvents?.startMiniGame(this.spinData.results[0].clientData.featuresDetails, extraFreeSpins)
            return
        }

        this.nextCommand = this.spinData.nextCommands[0]
        // this.nextCommand = ""
        this.symbols = this.spinData?.results[0]?.clientData.reels.board

        const resultsLength = this.spinData.results.length

        if (!this.game.assetsManager.autoPlayModal?.isAutoPlayRunning) {
            getEvent(EventType.SPIN_IN_PROGRESS).send(this.isSpinning)
        }


        this.getCurrentSpeed()
        let freeSpinWin = 0
        let balance = 0
        for (let i = 0; i < resultsLength; i++) {
            if (this.freeSpinCount) {
                this.freeSpinCount--
                this.game.assetsManager?.playBtn?.setSpinCounter(this.freeSpinCount);
                getEvent(EventType.GAME_AUTO_SPIN_COUNT).send(this.freeSpinCount === 0 ? '' : this.freeSpinCount)
            }


            const results = this.spinData.results[i]
            this.game.assetsManager.multiplier?.setupMultiplierValue(results.clientData.multiplier)
            this.symbols = results.clientData.reels.board
            await this.spinAnimation()
            await this.slotEvents?.buildWinLine(results.cashWin, results.clientData.reels.eventsLeft)
            await this.slotEvents?.buildSpecialEvents(results.clientData.reels.eventsLeft)
            freeSpinWin = results.sumOfFreeWin

            balance = results.clientData.account.value
            if (!this.isFsRunning) this.changeBalance(0, results.clientData.account.value)
        }

        if (this.isFsRunning) {
            this.game.assetsManager?.fss?.showModal(true, Number(freeSpinWin.toFixed(2)))
            this.changeBalance(0, balance)
        }

        // this.changeBalance()
        this.stopFs()

        if (command === 'buybonusspin') {
            this.nextCommand = 'minigame'
            await this.onSpin()
        }


        this.isSpinning = false
        if (['minigame', 'respin', 'respinminigame'].includes(this.nextCommand)) {
            await this.onSpin()
        }

        getEvent(EventType.SPIN_IN_PROGRESS).send(this.isSpinning)

        return this.spinData.results[0].isAutoPlayStopped;
    }


    spinAnimation = async () => {
        if (!this.reels?.length) throw 'REELS IS EMPTY'

        const reelRemap = async (reel: Reel, idx: number) => {
            if(idx === 0) this.game.assetsManager.playBtn?.changeButtonMode('stop');
            const newSymbols = this.symbols[reel.id]

            await reel.replaceSymbols(newSymbols)
            await reel.onStartAnimate()

            await reel.replaceSecondSymbols(newSymbols)
            await reel.onEndAnimate()
        }

        await Promise.all(this.reels.map(reelRemap))

        return true
    }

    changeBalance = (changeValue: number = 0, value = this.balance) => {
        this.balance = value + changeValue;
        this.game.assetsManager.infoBar?.balanceBar.changeValue(this.balance);
        this.game.assetsManager.footerForMobileHorizontal?.changeBalance(this.balance);
        this.game.assetsManager.footerForMobileVertical?.changeBalance(this.balance);
        getEvent(EventType.CHANGE_BALANCE).send(this.balance);
    }

    setBetList = (list: number[], index: number) => {
        this.game.assetsManager.footerForMobileHorizontal?.setBetList(list);
        this.game.assetsManager.footerForMobileVertical?.setBetList(list);
        this.game.assetsManager.infoBar?.betBar.setBetList(list);
        getEvent(EventType.SET_BET_LIST).send(list);
        getEvent(EventType.CHOSEN_BET).send(index);
    }

    getCurrentSpeed = (speedUp: boolean = false) => {
        const isTurboMode = LocalStorage.getItem('isTurboEnabled')
        if (isTurboMode) {
            this.currentSpeed = 0.5
        } else {
            this.currentSpeed = 1;
        }

        if(speedUp) {
            this.currentSpeed = 0.5
            getEvent(EventType.ANIMATION_SPEED).send(this.currentSpeed)
        }
    }

    stopFs = () => {
        this.game.assetsManager.autoPlayBtn!.button.renderable = true;
        this.game.assetsManager.autoPlayBtn!.changeFSMode(false)
        this.game.assetsManager.playBtn!.changeButtonMode('default')
        this.isFsRunning = false
        this.game.assetsManager.buyBonusBtn!.btn.visible = true;
        this.game.assetsManager.miniGame?.featureBar.clearFeatures();
        this.game.assetsManager.miniGame?.pFeatureBar.clearFeatures();
        this.game.assetsManager.multiplier!.multiplier.renderable = false;
        this.game.assetsManager.gameBG!.freeGameBG!.visible = false;
    }

}
