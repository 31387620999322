import {IEventBus} from "./types";
import {EventType} from "./events";

const events: IEventBus['events'] = new Map();

const SHOW_EVENTS:EventType[] = [EventType.AUTH_IS_SUCCESS]

const subscribe: IEventBus['subscribe'] = (event, callback) => {
  if (!events.has(event)) {
    const newSet = new Set<Function>();
    events.set(event, newSet);
  }

  const callbacks = events.get(event);
  callbacks?.add(callback);

  return () => {
    callbacks?.delete(callback);

    if (!callbacks?.size) {
      events.get(event)?.delete(callback);
    }

  }
}

const unsubscribe: IEventBus['unsubscribe'] = (event) => {
  if (events.has(event)) {
    events.delete(event)
  }

}

const send: IEventBus['send'] = (event, args) => {

  if (!events.has(event)) {
    return;
  }

  const callbacks = events.get(event);

  if (!callbacks?.size) {

    return
  }

  if(SHOW_EVENTS.includes(event)) {
    console.warn("[EVENT_BUS]:", event, { callbacks: callbacks.size, payload: args })
  }


  // @ts-ignore
  for (const callback of callbacks) {
    callback(args);
  }
}

export const EventBus = {
  subscribe,
  send,
  unsubscribe
}
