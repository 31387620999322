import * as PIXI from 'pixi.js'
import {Application, Container, Texture} from 'pixi.js'
import {SYMBOL_SIZE, SYMBOLS} from "../constants/Symbols";
import {SymbolName} from "../../api/types";
import {Spine} from "pixi-spine";

export class SymbolItem {
    name: SymbolName
    asset: string
    symbolId: string
    sprite?: PIXI.Sprite
    spine?: Spine
    container: Container
    app: Application

    constructor(id: string, name: SymbolName, asset: string, app: Application) {
        this.symbolId = id
        this.name = name
        this.asset = asset
        this.container = new Container()
        this.container.name = `${name}`;
        this.container.sortableChildren = true;
        this.app = app


        this.container.width = SYMBOL_SIZE
        this.container.height = SYMBOL_SIZE
        if (["L1", "L2", "L3", "L4", "H1", "H2", "H3", "H4", "WILD", "SCATTER"].includes(name)) {
            const spineData = this.app.loader.resources[this.asset].spineData!
            if(!spineData) return

            this.spine = new Spine(spineData)

            if(this.spine.state.hasAnimation('placement')) this.spine.state.setAnimation(0,'placement', false)

            this.spine.width = SYMBOL_SIZE
            this.spine.height = SYMBOL_SIZE

            this.spine.position.set(this.spine.width / 2, this.spine.height / 2)
            this.spine.scale.set(1, 1)
            this.container.addChild(this.spine)
            if(this.spine.state.hasAnimation('idle')) this.spine.state.setAnimation(0, 'idle', false)
        } else {
            this.sprite = new PIXI.Sprite(Texture.from(asset))
            this.sprite.width = SYMBOL_SIZE
            this.sprite.height = SYMBOL_SIZE

            this.container.addChild(this.sprite)
        }

        document.addEventListener('keydown', (e) => {
            if(e.code === "KeyB" && ["H4", "SCATTER"].includes(this.name)) this.win()
        })

    }


    win = (symbolIdx?: number) => {
        if (["L1", "L2", "L3", "L4", "H1", "H2", "H3", "H4", "WILD", "SCATTER"].includes(this.name) && this.spine) {
            this.spine.state.setAnimation(0, 'win', false)
            if(symbolIdx) this.container.zIndex = (symbolIdx + 1) * 100

        }
    }


    static getSymbol = (name: string, app: Application) => {
        const symbol = SYMBOLS.find((symbol) => symbol.name === name)
        if (!symbol) {
            return
        }

        return new SymbolItem(symbol.id, symbol.name, symbol.asset, app)
    }
    // METHODS EXAMPLE: WIN_ANIM, LANDING_ANIM, DESTRUCTION_ANIM
}
