import { useCallback } from "react";
import styled from "styled-components";
import { Game } from "../../../../game/Game";
import { EventType, getEvent, useEventReceiver } from "../../../../GameEventBus";
import { LocalStorage } from "../../../../utils/localStorage";


interface IBetModal {
    setBet: (value: number) => void
    game: Game
}

const BetList = ({ setBet, game }: IBetModal) => {
    let betsArray: number[];
    if (!game.slotMachine) {
        betsArray = [];
    } else {
        betsArray = game.slotMachine?.betList;
    }
   
    useEventReceiver<number>(EventType.CHOSEN_BET, value => {
        setBet(betsArray[value]);
        LocalStorage.setItem('chosenBet', value);
    })

    const handleClick = useCallback((bet: number, index: number) => {
        setBet(bet);
        LocalStorage.setItem('chosenBet', index);
        getEvent(EventType.CHOSEN_BET).send(index);
        game.slotMachine!.bet = bet;
    }, [setBet]);


    const handleCheck = (value: number) => {
        return LocalStorage.getItem('chosenBet') === value;
    }

    return (
        <BetListContainer>
            {betsArray.map((bet, index) =>
                <BetListItem
                    onClick={() => handleClick(bet, index)}
                    value={bet}
                    key={bet}
                    className={handleCheck(index) ? 'active-bet' : 'inactive-bet'}>
                    {bet.toFixed(2)} USD
                </BetListItem>)
            }
        </BetListContainer>
    );
}

export default BetList;

const BetListContainer = styled.ul`
    margin: auto;
    margin-top: 89px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: flex-start;
    list-style: none;
    row-gap: 6px;
    overflow-y: scroll;
    max-height: 160px;

    ::-webkit-scrollbar {
        width: 0;
      }
`

const BetListItem = styled.li`
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 101px;
    min-height: 27px;
    background: #151515;
    border: 2px solid #2A2C2B;
    font-family: Rubik;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 7px;
    color: #2A2C2B;
    cursor: pointer;

    &.active-bet {
        color: #e4f2f4;
        border: 2px solid #e4f2f4;
    }

    &.inactive-bet {
        color: #2A2C2B;
        border: 2px solid #2A2C2B;
    }

    &.inactive-bet:hover {
        color: #e4f2f4;
        border: 2px solid #e4f2f4;
    }

`
