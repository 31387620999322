import * as PIXI from 'pixi.js'
import { Game } from "../../Game";
import { MULTIPLIER_ASSETS } from '../../assetsLoader/multiplierLoader';
import { Texture } from 'pixi.js';

export class Multiplier {
    resources: any
    game: Game;
    multiplierBG: PIXI.Sprite;
    multiplier: PIXI.Container;
    multiplierX: PIXI.Sprite;
    texture: PIXI.Texture;

    constructor(game: Game) {
        this.game = game;
        this.resources = this.game.app.loader.resources.NUMBERS;
        this.multiplier = new PIXI.Container();
        this.texture = PIXI.Texture.from(MULTIPLIER_ASSETS.NUMBERS_SHEET);
        this.multiplierX = new PIXI.Sprite(Texture.from(MULTIPLIER_ASSETS.X));
        this.multiplierBG = new PIXI.Sprite(Texture.from(MULTIPLIER_ASSETS.MULTIPLIER_BG));
    }

    init = () => {
        this.setupMultiplier()
        this.setupMultiplierStatic()
        this.setupMultiplierValue();
        this.game.assetsManager.gameContainer.addChild(this.multiplier);
        this.game.resizeCallbacks.push(this.resize)
    }

    setupMultiplierValue = (value: number = 0) => {
        if (this.multiplier.children.length > 2) {
            this.multiplier.removeChildAt(2)
        }
        switch (value) {
            case 9:
                this.texture.frame = new PIXI.Rectangle(0, 0, 200, 200);
                this.multiplier.addChild(new PIXI.Sprite(this.texture))
                break;
            case 8:
                this.texture.frame = new PIXI.Rectangle(200, 0, 200, 200);
                this.multiplier.addChild(new PIXI.Sprite(this.texture))
                break;
            case 7:
                this.texture.frame = new PIXI.Rectangle(400, 0, 200, 200);
                this.multiplier.addChild(new PIXI.Sprite(this.texture))
                break;
            case 6:
                this.texture.frame = new PIXI.Rectangle(600, 0, 200, 200);
                this.multiplier.addChild(new PIXI.Sprite(this.texture))
                break;
            case 5:
                this.texture.frame = new PIXI.Rectangle(800, 0, 200, 200);
                this.multiplier.addChild(new PIXI.Sprite(this.texture))
                break;
            case 4:
                this.texture.frame = new PIXI.Rectangle(1000, 0, 200, 200);
                this.multiplier.addChild(new PIXI.Sprite(this.texture))
                break;
            case 3:
                this.texture.frame = new PIXI.Rectangle(1200, 0, 200, 200);
                this.multiplier.addChild(new PIXI.Sprite(this.texture))
                break;
            case 2:
                this.texture.frame = new PIXI.Rectangle(1400, 0, 200, 200);
                this.multiplier.addChild(new PIXI.Sprite(this.texture))
                break;
            case 1:
                this.texture.frame = new PIXI.Rectangle(1600, 0, 200, 200);
                this.multiplier.addChild(new PIXI.Sprite(this.texture))
                break;
            case 0:
                this.texture.frame = new PIXI.Rectangle(1800, 0, 200, 200);
                this.multiplier.addChild(new PIXI.Sprite(this.texture))
                break;
            default:
                this.texture.frame = new PIXI.Rectangle(1800, 0, 200, 200);
                this.multiplier.addChild(new PIXI.Sprite(this.texture))
                break;
        }
    }

    setupMultiplier = () => {
        this.multiplier.x = 1680;
        this.multiplier.y = 100;
        this.multiplier.width = 200;
        this.multiplier.height = 200;
        this.multiplier.zIndex = 110;
        this.multiplier.name = 'MULTIPLIER'
        this.multiplier.renderable = false;
    }

    setupMultiplierStatic = () => {
        this.multiplierBG.width = 200;
        this.multiplierBG.height = 200;
        this.multiplier.addChild(this.multiplierBG);
        this.multiplier.addChild(this.multiplierX);
    }

    isMultpilerVisible = (value: boolean = false) => {
        return value;
    }

    resize = () => {
        if (window.innerWidth / window.innerHeight <= 0.76) {
            this.multiplier.scale.set(1);
            this.multiplier.y = 0;
            this.multiplier.x = 118;
        } else {
            if (window.innerWidth <= 1080) {
                this.multiplier.scale.set(0.82);
                this.multiplier.y = 1;
                this.multiplier.x = 485;
            } else {
                this.multiplier.visible = true;
                this.multiplier.scale.set(1);
                this.multiplier.x = 1680;
                this.multiplier.y = 100;
            }
        }


    }

}

export default Multiplier;
