import { Base } from './Base';
import { ApiResponse, PlayResponse } from "./types";
import { EventType, getEvent } from '../GameEventBus';
import { LocalStorage } from '../utils/localStorage';

export default class Public extends Base {

    async play(bet: number, command: string = "", limitReq?: {}): Promise<ApiResponse<PlayResponse>> {
        const payload = {
            bet: bet,
            command: command,
            limitReq : limitReq

        }
        return new Promise(async(resolve, reject) => {
            try {
                const response = await this.request.post<ApiResponse<PlayResponse>>(
                    `/user/play`,
                    payload
                );

                return resolve (response.data);
            } catch (e: any) {
                return reject(e.response.data)
            }
        })


    }

    async playDebug(bet: number, command: string = "", positions: Array<Array<any>>): Promise<ApiResponse<PlayResponse>> {
        const payload = {
            bet: bet,
            command: command,
            testReels: {
                screen: positions
            }
        }
        return new Promise(async(resolve, reject) => {
            try {
                const response = await this.request.post<ApiResponse<PlayResponse>>(
                    `/user/debugReels`,
                    payload
                );
                return resolve(response.data);
            } catch (e) {
                return reject(e)
            }
        })


    }

    async initialize() {
        try {
            const response = await this.request.get<any>(
                `/user/initialize`,
            );
            getEvent(EventType.AUTH_IS_SUCCESS).send(true);
            return response.data;
        } catch (e) {
            console.error("INITIALIZE ERROR --->", e)
            getEvent(EventType.AUTH_IS_SUCCESS).send(false);
        }

    }
}
