import {Loader} from "pixi.js";
import multiplierBG from '../assets/multiplier/multiplier_bar_render.png'
import x from  '../assets/multiplier/numbers/x.png'
import numbersSheet from  '../assets/multiplier/numbers/numbers_sheet.png'

export enum MULTIPLIER_ASSETS {
  MULTIPLIER_BG = 'MULTIPLIER_BG',
  X = "X",
  NUMBERS_SHEET = "NUMBERS_SHEET"
}

export const multiplierLoader = (loader: Loader) => {
    loader.add(MULTIPLIER_ASSETS.MULTIPLIER_BG, multiplierBG)
    loader.add(MULTIPLIER_ASSETS.X, x)
    loader.add(MULTIPLIER_ASSETS.NUMBERS_SHEET, numbersSheet)
}

