import styled from "styled-components";
import DecisionButton from "./components/DecisionButton";
import CloseWindowButton from "./components/CloseWindowButton";
import { EventType, getEvent, useEventReceiver } from "../../../GameEventBus";
import { useCallback, useState } from "react";
import { Game } from "../../../game/Game";
import { api } from "../../../App";

interface IGame {
    game: Game
    isAssetsLoaded: boolean
}


const ConfirmationModal = ({game, isAssetsLoaded}: IGame) => {
    const [isModalActive, setModalActive] = useState(false);
    const [price, setPrice] = useState(0)
    const [bet, setCurrentBet] = useState(0)


    useEventReceiver<any>(EventType.OPEN_BUY_EXTRA_FEATURE_MODAL, value => {
        const {bet, count} = value
        const buyPrice = count === 3 ? 20 * bet : 40 * bet
        setPrice(buyPrice)
        setCurrentBet(bet)
        setModalActive(value);
    });

    const handlePurchase = useCallback(async() => {
        setModalActive(false);
        game.assetsManager.miniGame?.baefBtn?.buyExtraFeature();
        game.assetsManager.miniGame?.checkFeatureBar();
        game.slotMachine?.changeBalance(price)
        const data = await api.auth.play(bet, "buyextrafeature");
        if(!data) return
        game.assetsManager.miniGame?.replaceFeatures(data?.results[0].clientData.featuresDetails!)
    },
        [setModalActive, game, bet]
    );

    return (
        isAssetsLoaded ?
        <Wrapper style={{ visibility: isModalActive ? 'visible' : 'hidden' }}>

            <Container>

                <CloseButtonContainer onClick={() => setModalActive(false)}>
                    <CloseWindowButton>
                        {cross}
                    </CloseWindowButton>
                </CloseButtonContainer>

                <HeaderContainer>
                    <Header>Confirmation</Header>
                </HeaderContainer>

                <OfferContainer>
                    <Offer>Do you want to buy an extra feature for {price}$?</Offer>
                </OfferContainer>

                <ButtonContainer>

                    <ButtonCol onClick={handlePurchase}>
                        <DecisionButton> {yes} </DecisionButton>
                    </ButtonCol>

                    <ButtonCol onClick={() => setModalActive(!isModalActive)}>
                        <DecisionButton> {no}</DecisionButton>
                    </ButtonCol>

                </ButtonContainer>

            </Container>

        </Wrapper>
        : null
    );
}

export default ConfirmationModal;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(21, 21, 21, 0.8);
    left: 0;
    top: 0;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    top: 15vw;
    left: 25vw;
    box-sizing: border-box;
    position: fixed;
    width: 42vw;
    height: 21vw;
    background: #151515;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    justify-content: start;

    @media (max-width: 600px) {
        left: 20px;
        margin: auto;
        width: calc(100% - 40px);
    }    
       
`
const Header = styled.h1`
    margin: auto;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 2.29vw;
    line-height: 2.75vw;
    text-align: center;
    color: #E2F4F2;
`
const Offer = styled.h3`
    margin: auto;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 1.458vw;
    line-height: 1.75vw;
    text-align: center;
    color: #E2F4F2;
`
const ButtonContainer = styled.div`
    margin-right: auto;   
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 42vw;
    height: 7vw;
`
const ButtonCol = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 21vw;
    height: 5vw;
`
const HeaderContainer = styled.div`
    margin-right: auto;   
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center:
    outline: 3px solid red;
    max-width: 42vw;
    height: 4.35vw;
`

const OfferContainer = styled.div`
    margin-right: auto;   
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center:
    outline: 3px solid red;
    max-width: 42vw;
    height: 3vw;
`
const CloseButtonContainer = styled.div`
    margin-top: -0.7vw;
    margin-left: 0.7vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start:
    outline: 3px solid red;
    width: 42vw;
    height: 3.5vw;

    @media(max-width: 600px) {
        width: 100%;
    }
`

const yes = <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox="0 0 128 60">
    <style type="text/css">
        .st0{'fill:#E4F2F4;'}
    </style>
    <g>
        <path className="st0" d="M51.9,37.2c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2h-2.9c-0.2,0-0.3-0.1-0.4-0.2
c-0.1-0.1-0.2-0.3-0.2-0.4v-4.7l-5.1-9.4c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h3
c0.3,0,0.5,0.1,0.7,0.2c0.1,0.1,0.2,0.3,0.3,0.3l2.8,5.4l2.8-5.4c0-0.1,0.1-0.2,0.3-0.3c0.1-0.1,0.4-0.2,0.7-0.2h3
c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3l-5.1,9.4V37.2z"/>
        <path className="st0" d="M70,34.3c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4v2.3c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2
H59.6c-0.2,0-0.3-0.1-0.4-0.2S59,37.3,59,37.2V23c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2h10.3c0.2,0,0.3,0.1,0.4,0.2
c0.1,0.1,0.2,0.3,0.2,0.4v2.3c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2H63v2.4h6.3c0.2,0,0.3,0.1,0.4,0.2
c0.1,0.1,0.2,0.3,0.2,0.4v2.3c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2H63v2.5H70z"/>
        <path className="st0" d="M78.8,22.2c0.9,0,1.8,0.1,2.6,0.4c0.8,0.3,1.5,0.6,2,1s1,0.9,1.3,1.4c0.3,0.5,0.5,1,0.5,1.5
c0,0.1,0,0.2-0.1,0.3S85,27,84.8,27H82c-0.2,0-0.4,0-0.5-0.1c-0.1-0.1-0.3-0.1-0.4-0.3c0,0-0.1-0.1-0.2-0.2
c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.2-0.7-0.3c-0.3-0.1-0.6-0.1-1-0.1c-0.3,0-0.5,0-0.8,0.1s-0.5,0.1-0.7,0.2
c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.2,0.3-0.2,0.5s0.1,0.4,0.2,0.5c0.1,0.1,0.4,0.3,0.7,0.4c0.3,0.1,0.8,0.2,1.3,0.4
c0.5,0.1,1.2,0.3,2,0.4c0.8,0.2,1.5,0.4,2.1,0.6c0.6,0.2,1.1,0.6,1.5,0.9s0.7,0.8,0.9,1.3c0.2,0.5,0.3,1.1,0.3,1.8
c0,0.7-0.2,1.4-0.5,2c-0.3,0.6-0.8,1.1-1.4,1.5c-0.6,0.4-1.3,0.7-2.2,1C80.8,37.9,79.9,38,79,38c-1,0-1.9-0.1-2.7-0.4
s-1.5-0.6-2.1-1c-0.6-0.4-1.1-0.9-1.4-1.5c-0.3-0.5-0.5-1.1-0.5-1.7c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h2.9
c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0.1,0.1,0.2,0.3,0.3s0.3,0.3,0.5,0.4c0.2,0.1,0.4,0.3,0.7,0.4
c0.3,0.1,0.7,0.2,1.1,0.2c0.8,0,1.4-0.1,1.9-0.3c0.5-0.2,0.8-0.5,0.8-0.9c0-0.2-0.1-0.4-0.3-0.5s-0.5-0.3-0.9-0.4
c-0.4-0.1-0.9-0.2-1.5-0.4c-0.6-0.1-1.3-0.3-2.1-0.4c-1.5-0.3-2.6-0.9-3.3-1.6c-0.7-0.8-1-1.8-1-3c0-0.6,0.1-1.2,0.4-1.8
c0.3-0.6,0.7-1.1,1.2-1.5c0.5-0.4,1.2-0.8,2-1C77,22.3,77.9,22.2,78.8,22.2z"/>
    </g>
</svg>

const no = <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox="0 0 128 60">
    <style type="text/css">
        .st0{'fill:#E4F2F4;'}
    </style>
    <g>
        <path className="st0" d="M59.7,37.8c-0.2,0-0.5-0.1-0.6-0.2c-0.2-0.1-0.3-0.2-0.3-0.3l-5-7.5v7.4c0,0.2-0.1,0.3-0.2,0.4
		s-0.3,0.2-0.4,0.2h-2.9c-0.2,0-0.3-0.1-0.4-0.2s-0.2-0.3-0.2-0.4V23c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2h2.3
		c0.2,0,0.5,0.1,0.6,0.2c0.2,0.1,0.3,0.2,0.3,0.3l5,7.5V23c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2H62c0.2,0,0.3,0.1,0.4,0.2
		c0.1,0.1,0.2,0.3,0.2,0.4v14.2c0,0.2-0.1,0.3-0.2,0.4s-0.3,0.2-0.4,0.2H59.7z"/>
        <path className="st0" d="M65.1,28.1c0-0.8,0.2-1.6,0.5-2.3s0.8-1.4,1.3-1.9c0.6-0.5,1.3-1,2.1-1.3c0.8-0.3,1.8-0.5,2.9-0.5
		c1.1,0,2,0.2,2.9,0.5s1.5,0.7,2.1,1.3c0.6,0.5,1,1.2,1.3,1.9s0.5,1.5,0.5,2.3c0,0.6,0,1.3,0,2c0,0.7,0,1.3,0,1.9
		c0,0.8-0.2,1.6-0.5,2.3c-0.3,0.7-0.8,1.4-1.3,1.9s-1.3,1-2.1,1.3C74,37.9,73.1,38,72,38c-1.1,0-2-0.2-2.9-0.5
		c-0.8-0.3-1.5-0.7-2.1-1.3c-0.6-0.5-1-1.2-1.3-1.9c-0.3-0.7-0.5-1.5-0.5-2.3c0-0.6,0-1.2,0-1.9C65.1,29.4,65.1,28.8,65.1,28.1z
		 M74.8,31.9c0-0.3,0-0.6,0-0.9c0-0.3,0-0.7,0-1c0-0.3,0-0.7,0-1c0-0.3,0-0.6,0-0.8c0-0.4-0.1-0.7-0.2-1c-0.1-0.3-0.3-0.6-0.5-0.8
		s-0.5-0.4-0.8-0.5c-0.3-0.1-0.7-0.2-1.2-0.2c-0.5,0-0.9,0.1-1.2,0.2c-0.3,0.1-0.6,0.3-0.8,0.5c-0.2,0.2-0.4,0.5-0.5,0.8
		c-0.1,0.3-0.2,0.6-0.2,1c0,0.2,0,0.5-0.1,0.8c0,0.3,0,0.6,0,1c0,0.3,0,0.7,0,1s0,0.6,0.1,0.9c0.1,0.7,0.3,1.3,0.8,1.8
		c0.4,0.5,1.1,0.7,2,0.7c0.9,0,1.6-0.2,2-0.7C74.5,33.3,74.7,32.7,74.8,31.9z"/>
    </g>
</svg>

const cross = <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox="0 0 21 21">
    <style type="text/css">
        .st0{"fill:#E4F2F4;"}
    </style>
    <path className="st0" d="M11.7,10.5l2.9-2.9c0.3-0.3,0.3-0.9,0-1.2l0,0c-0.3-0.3-0.9-0.3-1.2,0l-2.9,2.9L7.6,6.4C7.3,6,6.7,6,6.4,6.4
	l0,0C6,6.7,6,7.3,6.4,7.6l2.9,2.9l-2.9,2.9c-0.3,0.3-0.3,0.9,0,1.2l0,0c0.3,0.3,0.9,0.3,1.2,0l2.9-2.9l2.9,2.9
	c0.3,0.3,0.9,0.3,1.2,0l0,0c0.3-0.3,0.3-0.9,0-1.2L11.7,10.5z"/>
</svg>

