import * as PIXI from 'pixi.js';
import { Texture } from 'pixi.js';
import { Game } from "../../Game";
import { buyAnExtraFeatureBtnStyle } from '../../styles';
import FeaturesBar from '../FeaturesBar/FeaturesBar';
import PortraitFeaturesBar from '../PortraitFeatureBar/PortraitFeatureBar';
import { EventType, getEvent } from '../../../GameEventBus';
import { MINIGAME_ASSETS } from '../../assetsLoader/miniGameLoader';

class BuyAnExtraFeatureBtn {
    game: Game;
    btn: PIXI.Container;
    btnBG: PIXI.Sprite;
    tempGraphchics: PIXI.Graphics;
    featureBar: FeaturesBar;
    pFeatureBar: PortraitFeaturesBar;


    constructor(game: Game, featureBar: FeaturesBar, pFeatureBar: PortraitFeaturesBar) {
        this.game = game;
        this.btn = new PIXI.Container();
        this.btnBG = new PIXI.Sprite(Texture.from(MINIGAME_ASSETS.BEP_BTN));
        this.featureBar = featureBar;
        this.pFeatureBar = pFeatureBar;
        this.tempGraphchics = new PIXI.Graphics();
    }

    init = () => {
        this.setupBtn();
        this.setupBtnBG();
        this.game.assetsManager.gameContainer.addChild(this.btn);
        this.game.resizeCallbacks.push(this.resize);
    }

    setupBtn = () => {
        this.btn.width = 364;
        this.btn.height = 126;
        this.btn.x = 506.5;
        this.btn.y = 799;
        this.btn.name = 'BUY_EXTRA_FEATURE_BTN';
        this.btn.interactive = true;
        this.btn.cursor = 'pointer';
        this.btn.on('pointerdown', this.doOnPointerDown);
    }

    setupBtnBG = () => {
        this.btn.addChild(this.btnBG);
    }



    doOnPointerDown = () => {
        if (this.featureBar.featuresContainer.children.length < 5) {
            getEvent(EventType.OPEN_BUY_EXTRA_FEATURE_MODAL).send({status: true, bet: this.game.slotMachine?.bet!, count: this.game.assetsManager.miniGame?.receivedFeatures.length});
        }
    }


    buyExtraFeature = () => {
        getEvent(EventType.CHEST_INTERACTION).send(true);
        this.featureBar.addFeature();
        this.pFeatureBar.addFeature();

    }

    resize = () => {
        // if (window.innerWidth / window.innerHeight <= 0.76) {
        //     this.btn.scale.set(1.46, 1.46);
        //     this.btn.x = 815;
        //     this.btn.y = 1920;
        // } else {
        //     if (window.innerWidth <= 1080) {
        //         this.btn.scale.set(0.96, 0.95);
        //         this.btn.x = 890;
        //         this.btn.y = 960;

        //     } else {
        //         this.btn.scale.set(0.98, 1);
        //         this.btn.x = 845;
        //         this.btn.y = 920;
        //     }
        // }
    }


}

export default BuyAnExtraFeatureBtn;
