import {Loader} from "pixi.js";
import GameSheetImg from '../assets/sheet.png'
import GameSheetBGImg from '../assets/sheetBG.png'
import GameSheetPortrait from '../assets/portraitSheet.png'
import GameSheetBGPortrait from '../assets/portraitSheetBG.png'
import SheetMask from '../assets/sheetMask.png'
import InfoBg from '../assets/info-bar/infoBG.svg'
import GameBg from '../assets/gameBG.png'
import FreeGameBg from '../assets/FreeGameBg.png'
import GameBgPortrait from '../assets/gameBGPortrait.png'
import logoPortrait from '../assets/logoPortrait.png'

export enum UI_ASSETS {
    GAME_FIELD = 'GAME_FIELD',
    GAME_FIELD_BG = 'GAME_FIELD_BG',
    GAME_FIELD_BG_PORTRAIT = 'GAME_FIELD_BG_PORTRAIT',
    GAME_FIELD_PORTRAIT = 'GAME_FIELD_PORTRAIT',
    SHEET_MASK = "SHEET_MASK",
    INFO_BACKGROUND = "INFO_BACKGROUND",
    GAME_BACKGROUND = "GAME_BACKGROUND",
    FREE_GAME_BACKGROUND = "FREE_GAME_BACKGROUND",
    GAME_BACKGROUND_PORTRAIT = "GAME_BACKGROUND_PORTRAIT",
    LOGO_PORTRAIT = "LOGO_PORTRAIT",
}

export const loadUiAssets = (loader: Loader) => {
    loader.add(UI_ASSETS.GAME_FIELD,  GameSheetImg)
    loader.add(UI_ASSETS.GAME_FIELD_BG,  GameSheetBGImg)
    loader.add(UI_ASSETS.GAME_FIELD_PORTRAIT, GameSheetPortrait)
    loader.add(UI_ASSETS.GAME_FIELD_BG_PORTRAIT, GameSheetBGPortrait)
    loader.add(UI_ASSETS.SHEET_MASK, SheetMask)
    loader.add(UI_ASSETS.INFO_BACKGROUND, InfoBg)
    loader.add(UI_ASSETS.GAME_BACKGROUND, GameBg)
    loader.add(UI_ASSETS.FREE_GAME_BACKGROUND, FreeGameBg)
    loader.add(UI_ASSETS.GAME_BACKGROUND_PORTRAIT, GameBgPortrait)
    loader.add(UI_ASSETS.LOGO_PORTRAIT, logoPortrait)
}
