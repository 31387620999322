import * as PIXI from "pixi.js";
import {Application} from "pixi.js";
import {assetsManager} from "./classes/assetsManager";
import {assetsLoader} from "./assetsLoader/assetsLoader";
import {slotMachine} from "./classes/slotMachine";
import {api} from "../App";
import {EventType, getEvent} from "../GameEventBus";
import {LocalStorage} from "../utils/localStorage";

const Window = window as any
Window.PIXI = PIXI

export let ratio = window.innerWidth / window.innerHeight;

export const currentBetIndex = JSON.parse(LocalStorage.getItem('chosenBet'))

if(!currentBetIndex) LocalStorage.setItem('chosenBet', 0)
PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR


export class Game {
    isAssetsLoaded: boolean
    isInit: boolean
    app: Application
    ref: any
    assetsManager: assetsManager
    resizeCallbacks: any[]
    slotMachine?: slotMachine
    isMiniGame: boolean
    inactiveElementsWhileSpin: any[];
    leftHandedMode: boolean
    isDebug: boolean

    constructor() {
        this.isAssetsLoaded = false
        this.isInit = false
        this.app = new PIXI.Application({
            width: 1920,
            height: 1080,
            resolution: window.innerWidth < 900 ? window.devicePixelRatio : 1,
            resizeTo: document.body,
            backgroundColor: 0x559fad
        })

        // @ts-ignore
        globalThis.__PIXI_APP__ = this.app;
        this.assetsManager = new assetsManager(this)
        this.resizeCallbacks = []
        this.isMiniGame = false
        this.inactiveElementsWhileSpin = []
        this.leftHandedMode = LocalStorage.getItem("isLeftHandedModeEnable");
        this.isDebug = false
    }

    async init(ref: any) {
        if (this.isInit) return
        this.isInit = true
        this.ref = ref
        await assetsLoader(this)
        await this.assetsManager.initAllComponents()
        this.isAssetsLoaded = true
        getEvent(EventType.GAME_ASSETS_LOADED).send(true)
        this.resize(this)
        sessionStorage.setItem('purchase', 'false')
        window.addEventListener('resize', () => this.resize(this))
        this.slotMachine = new slotMachine(this)
        await this.slotMachine.init()
        const initData = await api.auth.initialize()
        this.slotMachine.nextCommand = initData?.nextCommands[0] || ""


        // await this.slotMachine.onSpin()
        ref.current?.appendChild(this.app.view)
        this.app.start()

        getEvent<boolean>(EventType.MINI_GAME_IN_PROGRESS).subscribe(value => {
            this.isMiniGame = value;
        })

        getEvent<boolean>(EventType.LEFT_HANDED_MODE_TOGGLE).subscribe(value => {
            this.leftHandedMode = value;
            this.switchMode();
        })

        this.slotMachine.changeBalance(0, initData?.account.value)

        document.addEventListener('keydown', (e) => {
            if(e.code === 'KeyD') this.isDebug = !this.isDebug
            getEvent(EventType.IS_DEBUG).send(this.isDebug)
        })
    }


    switchMode = () => {
        this.resizeCallbacks.forEach((callback) => {
            callback();
        })
    }


    resize(game: this) {
        if (!game.app) return
        const isPortrait = window.innerWidth / window.innerHeight <= 0.76




        // TODO: WHEN DESIGN WILL BE DEVELOPED USE THIS FUNCTIONS FOR ADAPTIVE
        this.resizeCallbacks.forEach((callback) => {
            callback(isPortrait);
        })


        game.assetsManager.gameContainer.scale.x = window.innerWidth / 1920;
        game.assetsManager.gameContainer.scale.y = window.innerWidth / 1920;
        game.assetsManager.gameContainer.x = window.innerWidth / 2;
        game.assetsManager.gameContainer.y = window.innerHeight / 1920;

        // getEvent(EventType.OPEN_TOTAL_BET_MOBILE).send(false);

        if (window.innerWidth / window.innerHeight >= 1.7066666666666668) {
            game.assetsManager.gameContainer.scale.x = window.innerHeight / 1080 - 0.039;
            game.assetsManager.gameContainer.scale.y = window.innerHeight / 1080 - 0.039;

        }

        game.app.view.style.width = window.innerWidth + 'px';
        game.app.view.style.height = window.innerHeight + 'px';

        this.app.resize()

    }

}

