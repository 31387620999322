import * as PIXI from 'pixi.js'
import { Game } from "../../Game";
import { Texture } from "pixi.js";
import { UI_ASSETS } from "../../assetsLoader/UiAssets";

export class GameBackground {
    game: Game
    gameBG?: PIXI.Sprite
    freeGameBG?: PIXI.Sprite

    constructor(game: Game) {
        this.game = game
    }

    init = () => {
        this.gameBG = new PIXI.Sprite(Texture.from(UI_ASSETS.GAME_BACKGROUND))
        this.gameBG!.width = 1920
        this.gameBG!.height = 1920

        this.freeGameBG = new PIXI.Sprite(Texture.from(UI_ASSETS.FREE_GAME_BACKGROUND))
        this.freeGameBG!.width = 1923
        this.freeGameBG!.height = 1921
        this.freeGameBG!.visible = false;

        this.game.app.stage.addChild(this.gameBG)
        this.game.app.stage.addChild(this.freeGameBG)

        this.game.resizeCallbacks.push(this.resize)
    }

    /**
     * function for adaptive description
     */

    resize = () => {
        if (!this.gameBG) return
        if (!this.freeGameBG) return

        this.gameBG.anchor.set(0.5, 0.5)
        this.gameBG.x = window.innerWidth / 2
        this.gameBG.y = window.innerHeight / 2

        this.freeGameBG.anchor.set(0.5, 0.5)
        this.freeGameBG.x = window.innerWidth / 2
        this.freeGameBG.y = window.innerHeight / 2

        const currentRatio = window.innerWidth / window.innerHeight
        const baseRatio = 1920 / 1080


        if (baseRatio <= currentRatio) {
            this.gameBG.scale.set(window.innerWidth / 1920, window.innerWidth / 1920)
            this.freeGameBG.scale.set(window.innerWidth / 1920, window.innerWidth / 1920)
        } else {

            if (window.innerHeight / 1920 <= baseRatio) {
                if (window.innerWidth > window.innerHeight) {
                    this.gameBG.scale.set(window.innerWidth / 1920, window.innerWidth / 1920)
                    this.freeGameBG.scale.set(window.innerWidth / 1920, window.innerWidth / 1920)
                } else {
                    this.gameBG.scale.set(window.innerHeight / 1920, window.innerHeight / 1920)
                    this.freeGameBG.scale.set(window.innerHeight / 1920, window.innerHeight / 1920)
                }
            } else {
                this.gameBG.scale.set((window.innerHeight / 1920), (window.innerHeight / 1920))
                this.freeGameBG.scale.set((window.innerHeight / 1920), (window.innerHeight / 1920))
            }
            if (currentRatio <= 0.6) {
                this.gameBG.y = window.innerHeight / 2 - 30
                this.freeGameBG.y = window.innerHeight / 2 - 30
            }
        }

    }
}
