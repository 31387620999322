import styled from "styled-components";


const CloseWindowButton = (props: React.PropsWithChildren) => {
    return(
        <ButtonBg> 
            <Button>
                <ButtonHoverBg className="btn-hover">
                    {props.children}
                </ButtonHoverBg>
            </Button>
        </ButtonBg>
    )
}

export default CloseWindowButton;

const ButtonBg = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.71vw;
    height: 2.71vw;
    margin: auto;
    position: absolute;
    border-radius: 100%;
    outline: 0.14vw solid #151515;
    background-position: 0px 0px,0px 0px,0px 0px,0px 0px,0px 0px;
    background-size: 100% 100%;
    background-position: 0px 0px,0px 0px,0px 0px,0px 0px,0px 0px;
    background-image: radial-gradient(49% 81% at 45% 47%, #FFE20345 0%, #073AFF00 100%),
    conic-gradient(from 180deg at 50% 50%, #af70c1 1%, #45d1bd 33%, #f9ad29 66%, #af70c1 100%),
    radial-gradient(142% 91% at 83% 7%, #FFDB00FF 1%, #FF000000 99%),
    radial-gradient(142% 91% at -6% 74%, #FF0049FF 1%, #FF000000 99%),
    radial-gradient(142% 91% at 111% 84%, #FF7000FF 0%, #FF0000FF 100%);
    `

const ButtonHoverBg = styled.div`
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    background: #151515;
    fill: #e4f2f4;
    position: absolute;
    width: 2.5vw;
    height: 2.5vw;
    border-radius: 100%;
    border: 0.14vw solid #151515;

    &.btn-hover:hover {
        background: #e4f2f4;
        fill: #151515;
    }`

const Button = styled.button`
    background: #151515;    
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    fill: #e4f2f4;
    width: 2.5vw;
    height: 2.5vw;
    border-radius: 100%;
    border: 0.14vw solid #151515;
    `


