export class Timer {
  start: number
  timerId: number | null
  remaining: number
  callback: any
  delay: number
  constructor(callback: any, delay: number) {
      this.callback = callback
      this.delay = delay
      this.remaining = delay
      this.start = 0
      this.timerId = null
  }

  initialize = () => {
      const task = this.callback
      const timerTask = () => {
          task()
          document.removeEventListener('visibilitychange', this.listener)
          this.clear()
      }
      this.callback = timerTask
      this.timerId = window.setTimeout(this.callback, this.delay)
      this.start = Date.now()

      document.addEventListener('visibilitychange', this.listener);
  }

  listener = () => {
      if (document.hidden) {
          this.pause()
      } else {
          this.resume()
      }
  }

  resume = () => {
      if(this.timerId || this.remaining === 0) return
      this.start = Date.now()
      this.timerId = window.setTimeout(this.callback, this.remaining )
  }

  pause = () => {
      if(!this.timerId  || !this.start) return
      clearTimeout(this.timerId)
      this.timerId = null
      this.remaining -= Date.now() - this.start

  }

  clear = () => {
      if(!this.timerId) return
      window.clearTimeout(this.timerId)
      this.callback = null
      this.start = 0
      this.remaining = 0
      this.timerId = null
  }
}