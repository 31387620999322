import * as PIXI from "pixi.js";
import { Game } from "../Game";
import { EventType, getEvent } from "../../GameEventBus";


export class SpinEvents {
    game: Game

    constructor(game: Game) {
        this.game = game;
    }

    init = () => {
        getEvent<boolean>(EventType.AUTOSPIN_IN_PROGRESS).subscribe(value => {
            if (value && !this.game.isMiniGame) {
                this.game.inactiveElementsWhileSpin.forEach((item) => {
                    item.interactive = false;
                    item.alpha = 0.5;
                })
            } else if (!value && !this.game.isMiniGame) {
                this.game.inactiveElementsWhileSpin.forEach((item) => {
                    item.interactive = true;
                    item.cursor = 'pointer';
                    item.alpha = 1;
                })
            }
        })

        getEvent<boolean>(EventType.SPIN_IN_PROGRESS).subscribe(value => {
            if (value && !this.game.isMiniGame) {
                this.game.inactiveElementsWhileSpin.forEach((item) => {
                    if (item.name === 'PLAY_BUTTON') return;
                    item.interactive = false;
                    item.alpha = 0.5;
                })
            } else if (!value && !this.game.isMiniGame && !this.game.assetsManager.autoPlayModal?.isAutoPlayRunning) {
                this.game.inactiveElementsWhileSpin.forEach((item) => {
                    item.interactive = true;
                    item.cursor = 'pointer';
                    item.alpha = 1;
                })
            }
        })

    }
}

