import {SymbolItem} from "./SymbolItem";
import {SYMBOL_SIZE} from "../constants/Symbols";
import {Game} from "../Game";
import * as PIXI from 'pixi.js'
import {GameField} from "../components/gameField/GameField";
import {multiplyArray} from "../../utils/multiplyArr";
import {SymbolName} from "../../api/types";
import gsap from 'gsap'
import {slotMachine} from "./slotMachine";
import {EventType, getEvent} from "../../GameEventBus";

export class Reel {
    id: number
    symbols: SymbolItem[]
    game: Game
    sheet?: GameField
    reelsContainer: PIXI.Container
    container: PIXI.Container
    slotMachine: slotMachine
    isAnimate: boolean
    startAnim: gsap.core.Timeline

    constructor(game: Game, id: number, parentContainer: PIXI.Container) {
        this.id = id
        this.game = game
        this.slotMachine = game.slotMachine!
        this.symbols = []
        this.reelsContainer = parentContainer
        this.container = new PIXI.Container()
        this.isAnimate = false
        this.startAnim = gsap.timeline()
    }

    init = () => {
        if (!this.game.assetsManager.gameField) return
        this.sheet = this.game.assetsManager.gameField
        this.reelsContainer.y = 15
        this.reelsContainer.x = -13

    }

    generateReels = (symbols: string[]) => {
        const multiplierSymbols: string[] = multiplyArray(symbols, 2) as string[]
        return new Promise((resolve, reject) => {
            multiplierSymbols.forEach((name) => {
                const symbol = SymbolItem.getSymbol(name, this.game.app)
                if(!symbol) return
                this.symbols.push(symbol)

            })
            resolve(true)
        })

    }

    placeSymbols = (reelId: number, isInitial = true) => {
        return new Promise((resolve) => {
            const startPos = 713
            this.symbols.forEach((symbol, index) => {

                symbol.container.y = startPos - (((SYMBOL_SIZE * index + 22 ) + index * 7) - (isInitial ? 0 : 0))
                this.container.x = (SYMBOL_SIZE * reelId + 23 ) + reelId * 7
                this.container.addChild(symbol.container)
                this.reelsContainer?.addChild(this.container)
            })

            return resolve(true)
        })
    }

    replaceSymbols = (newSymbols: SymbolName[]) => {
        return new Promise((resolve, reject) => {
            const symbolsLength = this.symbols.length - 1
            for(let i = symbolsLength; i >= 4; i--) {
                const currentSymbol = this.symbols[i]
                const symbolName = newSymbols[symbolsLength - i]
                const symbol = SymbolItem.getSymbol(symbolName, this.game.app)
                if(!symbol) return
                symbol.container.y = currentSymbol.container.y
                this.symbols[i] = symbol

                const indexToDelete = this.container.children.indexOf(currentSymbol.container)
                this.container.removeChildAt(indexToDelete)

                this.container.addChild(symbol.container)

            }

            return resolve(true)
        })
    }



    clearWinBorder = () => {
        return new Promise((resolve) => {

            this.symbols.forEach((symbol) => {
                symbol.container.children.forEach((children, index) => {
                    if(children instanceof PIXI.Graphics ) {
                        symbol.container.removeChildAt(index)
                    }

                })
            })

            return resolve(true)
        })
    }


    replaceSecondSymbols = (newSymbols: SymbolName[]) => {
        return new Promise((resolve, reject) => {
            for(let i = 3; i >= 0; i--) {
                const currentSymbol = this.symbols[i]
                const symbolName = newSymbols[3 - i]
                const symbol = SymbolItem.getSymbol(symbolName, this.game.app)
                if(!symbol) return

                symbol.container.y = currentSymbol.container.y
                this.symbols[i] = symbol
                const indexToDelete = this.container.children.indexOf(currentSymbol.container)
                this.container.removeChildAt(indexToDelete)

                this.container.addChild(symbol.container)


                // this.reelsContainer[]
            }


            return resolve(true)
        })
    }

    onStartAnimate = async (initially = false) => {
        // if(this.isAnimate) return
        // this.isAnimate = true

        if(!initially) {
            await this.startAnim.progress(100).then()
        }

        getEvent<number>(EventType.ANIMATION_SPEED).subscribe((value) => {
            const currentTime = this.startAnim.duration()
            this.startAnim.duration(currentTime * value)
        })
            await this.startAnim
                .fromTo(
                    this.container,
                    { y: 0 },
                    {
                        duration:  0.2 * this.slotMachine.currentSpeed,
                        y: 892,
                        repeat: initially ? 40 : 2 + (this.id),
                        // delay: 0.1 * (this.id + 1) * this.slotMachine.currentSpeed,
                        repeatDelay: 0,
                        repeatRefresh: false,
                        ease: 'none',
                    },
                )
                .then()
        getEvent(EventType.ANIMATION_SPEED).unsubscribe()
        this.container.y = 0
    }

    onEndAnimate = async () => {
        // if(this.isAnimate) return
        // this.isAnimate = true
        console.log(`end animate`)
        await this.startAnim
            .fromTo(
                this.container,
                { y: 0 },
                {
                    duration: 0.5 * this.slotMachine.currentSpeed,
                    y: 892,
                    ease: 'back.out(1.2)',
                },
            )
            .then()

        this.container.y -= 892
        this.isAnimate = false
    }

}
