import { FC } from 'react';
import styled from 'styled-components';

interface IStopAutoPlay {
    onAnyWin: boolean;
    stopOnAnyWin: Function;
    isFreeSpinWon: boolean;
    stopIsFreeSpinWon: Function;
}

const StopAutoPlay: FC<IStopAutoPlay> = ({
    onAnyWin,
    stopOnAnyWin,
    isFreeSpinWon,
    stopIsFreeSpinWon,
 }) => {
    return (
        <Wrapper>
            <Title>STOP AUTOPLAY</Title>
            <Switch>
                <SwitchTitle>On any win</SwitchTitle>
                <ButtonLabel style={onAnyWin ? {} : { background: '#535858' }}>
                    <ButtonInput
                        type='checkbox'
                        checked={onAnyWin}
                        onChange={event => stopOnAnyWin(event.currentTarget.checked)}
                    />
                    <ButtonSpan></ButtonSpan>
                </ButtonLabel>
            </Switch>
            <Switch>
                <SwitchTitle>If free spins are won</SwitchTitle>
                <ButtonLabel style={isFreeSpinWon ? {} : { background: '#535858' }}>
                    <ButtonInput
                        type='checkbox'
                        checked={isFreeSpinWon}
                        onChange={event => stopIsFreeSpinWon(event.currentTarget.checked)}
                    />
                    <ButtonSpan></ButtonSpan>
                </ButtonLabel>
            </Switch>
        </Wrapper>
    );
};

export default StopAutoPlay;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	padding-top: 16px;
`;

const Title = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 30px;
	background: #2a2c2b;
	border-radius: 5px;
	font-family: 'Rubik';
	font-weight: 700;
	font-size: 13px;
	line-height: 13px;
	color: #7c8584;
	margin-bottom: 8px;
`;

const SwitchTitle = styled.div`
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	display: flex;
	justify-content: center;
	color: #7c8584;
	margin-bottom: 8px;
`;

const Switch = styled.div`
	padding-top: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const ButtonInput = styled.input.attrs({ type: 'checkbox' })`
	opacity: 0;
	width: 0;
	height: 0;
`;

const ButtonLabel = styled.label`
	position: relative;
	display: inline-block;
	width: 53px;
	padding: 4px;
	height: 29px;
	border-radius: 60px;
	background: conic-gradient(
		from 180deg at 50% 50%,
		#af70c1 0deg,
		#45d1bd 125.63deg,
		#f9ad29 266.25deg,
		#af70c1 360deg
	);
	input:checked + span::before {
		-webkit-transform: translateX(22px);
		-ms-transform: translateX(22px);
		transform: translateX(22px);
		background-color: #e2f4f2;
	}
`;

const ButtonSpan = styled.span`
	position: absolute;
	cursor: pointer;
	border-radius: 60px;
	margin: 2px 2px 2px 2px;
	width: 49px;
	height: 25px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #151515;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	:before {
		position: absolute;
		content: '';
		height: 19px;
		width: 19px;
		left: 3px;
		bottom: 3px;
		background-color: #535858;
		-webkit-transition: 0.4s;
		border-radius: 50%;
		transition: 0.4s;
	}
`;
