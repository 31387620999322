import * as PIXI from 'pixi.js';
import { Game } from '../Game';
import FeaturesBar from '../components/FeaturesBar/FeaturesBar';
import { Chest } from './Chest';
import PlayBtn from '../components/PlayBtn/PlayBtn';
import BuyAnExtraFeatureBtn from '../components/BuyAnExtraFeatureBtn/BuyAnExtraFeatureBtn';
import { GameField } from '../components/gameField/GameField';
import AutoPlayButton from '../components/AutoPlayButton/AutoPlayButton';
import { InfoBar } from './InfoBar';
import BuyBonusButton from '../components/BuyBonusButton/BuyBonusButton';
import Multiplier from '../components/multiplier/Multiplier';
import BetBtn from '../components/BetButton/BetButton';
import BurgerMenuBtn from '../components/BurgerMenuBtn/BurgerMenuBtn';
import { EventType, getEvent } from '../../GameEventBus';
import PortraitFeaturesBar from '../components/PortraitFeatureBar/PortraitFeatureBar';
import { FeatureDetailed } from "../../api/types";
import { Texture } from 'pixi.js';
import { MINIGAME_ASSETS } from '../assetsLoader/miniGameLoader';
import { UI_ASSETS } from '../assetsLoader/UiAssets';
import LettersHelper from '../components/LettersHelper/LettersHelper';
import { Timer } from '../../utils/Timer';


const chestPosArray = [
    { x: 170, y: 320 }, { x: 400, y: 245 }, { x: 720, y: 220 }, { x: 1020, y: 220 },
    { x: 560, y: 370 }, { x: 240, y: 550 }, { x: 500, y: 580 },
    { x: 760, y: 500 }, { x: 1000, y: 430 }, { x: 920, y: 670 }
]

class MiniGame {
    game: Game;
    gameField: GameField;
    playBtn: PlayBtn;
    autoPlayBtn: AutoPlayButton;
    infoBar: InfoBar;
    miniGameContainer: PIXI.Container;
    chests: Chest[];
    featureBar: FeaturesBar;
    baefBtn: BuyAnExtraFeatureBtn;
    buyBonusBtn: BuyBonusButton;
    multiplier: Multiplier;
    betBtn: BetBtn;
    burgerMenuBtn: BurgerMenuBtn;
    pFeatureBar: PortraitFeaturesBar;
    features: FeatureDetailed[];
    receivedFeatures: FeatureDetailed[];
    minigameBG: PIXI.Sprite;
    minigameBGPortrait: PIXI.Sprite;
    chestContainer: PIXI.Container;
    lettersHelper: LettersHelper;

    constructor(
        game: Game,
        gameField: GameField,
        playBtn: PlayBtn,
        autoPlayBtn: AutoPlayButton,
        infoBar: InfoBar,
        multiplier: Multiplier,
        burgerMenuBtn: BurgerMenuBtn,
        betBtn: BetBtn,
        buyBonusBtn: BuyBonusButton
    ) {
        this.game = game;
        this.gameField = gameField;
        this.playBtn = playBtn;
        this.autoPlayBtn = autoPlayBtn;
        this.infoBar = infoBar;
        this.miniGameContainer = new PIXI.Container();
        this.chests = [];
        this.featureBar = new FeaturesBar(this.game);
        this.pFeatureBar = new PortraitFeaturesBar(this.game);
        this.baefBtn = new BuyAnExtraFeatureBtn(this.game, this.featureBar, this.pFeatureBar);
        this.buyBonusBtn = buyBonusBtn;
        this.multiplier = multiplier;
        this.betBtn = betBtn;
        this.burgerMenuBtn = burgerMenuBtn;
        this.features = [];
        this.receivedFeatures = [];
        this.minigameBG = new PIXI.Sprite(Texture.from(MINIGAME_ASSETS.MINIGAME_BG));
        this.minigameBGPortrait = new PIXI.Sprite(Texture.from(MINIGAME_ASSETS.MINIGAME_BG_PORTRAIT));
        this.chestContainer = new PIXI.Container();
        this.lettersHelper = new LettersHelper(this.game.app.loader.resources.LETTERS_SHEET_DATA.spritesheet?.textures, "MINIGAME_HEADER");
    }

    init = () => {
        this.setupMiniGameContainer();
        this.setupMiniGameBG();
        this.setupMiniGameHeader();
        this.setupChestContainer();
        this.chestListener();
        this.setupBuyAnExtraFeatureBtn();
        this.setupFeatureBar();
        this.checkFeatureBar();


        this.game.assetsManager.gameContainer.addChild(this.miniGameContainer);
        this.game.resizeCallbacks.push(this.resize)

        getEvent<boolean>(EventType.CHEST_INTERACTION).subscribe(value => {
            this.chestListener();
            this.chestContainer.interactiveChildren = false;
            const timer = new Timer(() => {
                this.chestContainer.interactiveChildren = true;
                this.modalChestListener();
            }, 1000)
            timer.initialize()
        })

        getEvent<boolean>(EventType.OPEN_CHEST).subscribe(value => {
            this.baefBtn.btn.visible = false;
            this.lettersHelper.visible = false;
            this.miniGameEndTrigger();
        })

    }

    setupMiniGameContainer = () => {
        this.miniGameContainer.width = 1377;
        this.miniGameContainer.height = 1021;
        this.miniGameContainer.visible = false;
        this.miniGameContainer.zIndex = -1;
        this.miniGameContainer.name = 'MINI_GAME';
    }

    setupMiniGameBG = () => {
        this.minigameBG.visible = false;
        this.minigameBG.name = "MINIGAME_BG"
        this.minigameBGPortrait.visible = false;
        this.minigameBGPortrait.scale.set(1.25)
        this.minigameBGPortrait.name = "MINIGAME_BG_PORTRAIT"
        this.miniGameContainer.addChild(this.minigameBG);
        this.miniGameContainer.addChild(this.minigameBGPortrait)
    }

    setupMiniGameHeader = () => {
        let currency = "usd"
        let amount = 12
        this.lettersHelper.makeSentense(`buy an extra pick for ${amount} ${currency} or open chest`, -7);
        this.lettersHelper.y = 115;
        this.lettersHelper.x = this.minigameBG.width / 2 - this.lettersHelper.width / 2;
        this.miniGameContainer.addChild(this.lettersHelper);
    }

    setupChestContainer = () => {
        this.chestContainer.width = 1152;
        this.chestContainer.height = 925;
        this.chestContainer.name = "CHEST_CONTAINER";
    }

    setupChests = () => {
        for (let i = 0; i < 10; i++) {
            const chest = new Chest(this);
            chest.init();
            chest.featureName = `Feature ${i}`
            chest.container.x = chestPosArray[i].x;
            chest.container.y = chestPosArray[i].y;
            this.chests.push(chest);
            this.chestContainer.addChild(chest.container);
        }
        this.miniGameContainer.addChild(this.chestContainer);
    }

    restoreChests = () => {
        this.chests.splice(0, this.chests.length);
        this.chestContainer.removeChildren();
    }

    timerCallback = () => {
        const timer = new Timer(() => {
            this.chestContainer.interactiveChildren = true;
            this.modalChestListener();
        }, 1000)
        timer.initialize();
    }

    setupFeatureBar = () => {
        this.featureBar.init();
        this.pFeatureBar.init();
    }

    checkFeatureBar = () => {
        if (this.featureBar.hollowFeaturesContainer.children.length == 5) {
            this.lettersHelper.visible = false;
            this.baefBtn.btn.visible = false;
        }
    }

    setupBuyAnExtraFeatureBtn = () => {
        this.baefBtn.init();
        this.baefBtn.btn.visible = true;
        this.miniGameContainer.addChild(this.baefBtn.btn);
    }

    toggleMiniGame = (status: boolean) => {
        if (status) {
            this.setupMiniGameIsAvaliable()
        }
        else this.setupMiniGameIsNotAvaliable()
        getEvent(EventType.MINI_GAME_IN_PROGRESS).send(status)
    }

    setupMiniGameIsAvaliable = () => {
        this.gameField.container.visible = false;
        this.miniGameContainer.visible = true;

        this.lettersHelper.visible = true;

        this.setupChests();

        this.playBtn.button.interactive = false;
        this.playBtn.button.alpha = 0;

        this.autoPlayBtn.button.interactive = false;
        this.autoPlayBtn.button.alpha = 0;

        this.infoBar.betBar.minusButton.interactive = false;
        this.infoBar.betBar.minusButton.alpha = 0.5;

        this.infoBar.betBar.plusButton.interactive = false;
        this.infoBar.betBar.plusButton.alpha = 0.5;

        this.baefBtn.btn.visible = true;

        this.buyBonusBtn.btn.visible = false;

        this.multiplier.multiplier.renderable = false;

        this.betBtn.btn.interactive = false;
        this.betBtn.btn.alpha = 0;

        this.buyBonusBtn.btn.visible = false;

        if (this.receivedFeatures.length == 5) {
            this.baefBtn.btn.visible = false;
            this.lettersHelper.visible = false;
        }

        this.featureBar.addFeatures(this.receivedFeatures.length);

        this.pFeatureBar.addFeatures(this.receivedFeatures.length);

        this.game.assetsManager.footerForMobileHorizontal!.footer.alpha = 0;
        this.game.assetsManager.footerForMobileVertical!.footer.alpha = 0;

    }

    setupMiniGameIsNotAvaliable = () => {
        this.gameField.container.visible = true;
        this.miniGameContainer.visible = false;

        this.playBtn.button.interactive = true;
        this.playBtn.button.alpha = 1;

        this.autoPlayBtn.button.interactive = true;
        this.autoPlayBtn.button.alpha = 1;

        this.infoBar.betBar.minusButton.interactive = true;
        this.infoBar.betBar.minusButton.alpha = 1;

        this.infoBar.betBar.plusButton.interactive = true;
        this.infoBar.betBar.plusButton.alpha = 1;

        this.playBtn.button.alpha = 1;

        this.baefBtn.btn.visible = true;

        // this.buyBonusBtn.btn.visible = true;

        this.multiplier.multiplier.renderable = true;

        this.betBtn.btn.interactive = true;
        this.betBtn.btn.alpha = 1;


        this.restoreChests();

        this.game.assetsManager.footerForMobileHorizontal!.footer.alpha = 1;
        this.game.assetsManager.footerForMobileVertical!.footer.alpha = 1;

        // this.featureBar.clearFeatures();

        // this.pFeatureBar.clearFeatures();
    }

    setupRespinMinigame = () => {
        this.featureBar.addFeatures(this.receivedFeatures.length);
        this.pFeatureBar.addFeatures(this.receivedFeatures.length);
        this.buyBonusBtn.btn.visible = false;
        this.receivedFeatures.forEach(feature => {
            if (feature.name === "MultiplierIncrease") this.game.assetsManager.multiplier?.setupMultiplierValue(feature.value);
            this.featureBar.insertObtaindedFeature([feature.name, feature.value])
            this.pFeatureBar.insertObtaindedFeature([feature.name, feature.value])
            this.game.assetsManager.minigameSummaryModal?.addObtainedFeature([feature.name, feature.value])
        })
    }

    chestListener = () => {
        let openCounter = this.chests.filter(chest => chest.status).length;
        if (this.featureBar.hollowFeaturesContainer.children.length === openCounter + 1) {
            this.chestContainer.children.forEach(e => {
                e.interactive = false;
            })
        } else {
            this.chestContainer.children.forEach(e => {
                e.interactive = true;
            })
        }

    }


    modalChestListener = () => {
        let openCounter = this.chests.filter(chest => chest.status).length;
        if (this.featureBar.hollowFeaturesContainer.children.length === openCounter) {
            this.chestContainer.children.forEach(e => {
                e.interactive = false;
            })
        } else {
            this.chestContainer.children.forEach(e => {
                e.interactive = true;
            })
        }

    }

    miniGameEndTrigger = () => {
        let counter = 0;
        this.chestContainer.children.forEach(chest => {
            if (chest.interactive) counter++;
        })
        if (!counter) getEvent(EventType.MINIGAME_ENDED).send(true);
    }

    replaceFeatures = (features: FeatureDetailed[]) => {
        this.receivedFeatures = features
    }

    resize = () => {
        if (window.innerWidth / window.innerHeight <= 0.76) {
            this.miniGameContainer.scale.set(1.4);
            this.miniGameContainer.x = 15;
            this.miniGameContainer.y = 215;

            this.chestContainer.scale.set(1.1);
            this.chestContainer.x = -100;
            this.chestContainer.y = -50;

            this.lettersHelper.setScale(1.25);
            this.lettersHelper.x = 140;
            this.lettersHelper.y = 83;

            this.minigameBG.visible = false;
            this.minigameBG.width = 1080;
            this.minigameBG.height = 885;

            this.minigameBGPortrait.visible = true;

            this.baefBtn.btn.x = 495;
            this.baefBtn.btn.y = 870;
        } else {
            if (window.innerWidth <= 1080) {
                this.miniGameContainer.scale.set(0.93);
                this.miniGameContainer.y = 120;
                this.miniGameContainer.x = 330;

                this.chestContainer.scale.set(1);
                this.chestContainer.x = 0;
                this.chestContainer.y = 0;

                this.lettersHelper.setScale(1);
                this.lettersHelper.x = this.minigameBG.width / 2 - this.lettersHelper.width / 2;
                this.lettersHelper.y = 115;

                this.minigameBG.visible = true;
                this.minigameBG.width = 1377;
                this.minigameBG.height = 1021;

                this.minigameBGPortrait.visible = false;

                this.baefBtn.btn.x = 506;
                this.baefBtn.btn.y = 799;
            } else {
                this.miniGameContainer.scale.set(1);
                this.miniGameContainer.x = 280;
                this.miniGameContainer.y = -25;

                this.chestContainer.scale.set(1);
                this.chestContainer.x = 0;
                this.chestContainer.y = 0;

                this.lettersHelper.setScale(1);
                this.lettersHelper.x = this.minigameBG.width / 2 - this.lettersHelper.width / 2;
                this.lettersHelper.y = 115;

                this.minigameBG.visible = true;
                this.minigameBG.width = 1377;
                this.minigameBG.height = 1021;

                this.minigameBGPortrait.visible = false;

                this.baefBtn.btn.x = 506;
                this.baefBtn.btn.y = 799;
            }
        }
    }
}

export default MiniGame;

