export const numberOfSpinsList = [
    {
        spinsNumber: 'OFF',
    },
    {
        spinsNumber: 'ထ',
    },
    {
        spinsNumber: 20,
    },
    {
        spinsNumber: 50,
    },
    {
        spinsNumber: 75,
    },
    {
        spinsNumber: 100,
    },
];

export const lossLimitList = [
    {
        lossLimitNumber: 'NO LIMIT',
    },
    {
        lossLimitNumber: '5x',
    },
    {
        lossLimitNumber: '20x',
    },
    {
        lossLimitNumber: '50x',
    },
    {
        lossLimitNumber: '75x',
    },
    {
        lossLimitNumber: '100x',
    },
];

export const singleWinLimitList = [
    {
        singleWinLimitNumber: 'NO LIMIT',
    },
    {
        singleWinLimitNumber: '5x',
    },
    {
        singleWinLimitNumber: '20x',
    },
    {
        singleWinLimitNumber: '50x',
    },
    {
        singleWinLimitNumber: '75x',
    },
    {
        singleWinLimitNumber: '100x',
    },
];
