import axios from 'axios';
import Public from './Public';
import { EventType, getEvent } from '../GameEventBus';

const randomNumber = Math.random()
const date = new Date()
export default class Api {
	auth: Public;

	constructor() {
		this.auth = new Public(this.request);
	}
	// // инстанс запросов через который буду выполятся все запросы на бекенд
	get request() {
		const instance = axios.create({
			baseURL: 'https://vikings-slots.fgp.digital/api/v1'
		});

		instance.interceptors.request.use(config => {

			(config.headers as any)['Authorization'] = `Bearer ${randomNumber}-${date.getTime()}`;

			return config;
		}, function (error) {
			let errorArray = [error.name, error.message, error.code]
			console.log(`ERROR`, error)
			getEvent(EventType.ERROR).send(errorArray);
			return Promise.reject(error)
		});

		return instance;

	}
}


