import * as PIXI from "pixi.js"
import { Texture } from "pixi.js"

class LettersHelper extends PIXI.Container {
    letters: Map<string, Texture>;

    constructor(textures: any, name: string = "LETTERS_HELPER") {
        super();
        this.name = name;
        this.letters = new Map();    
        this.setupMap(textures);
    }

    setupMap = (textures: any) => {
        this.letters.set("a", textures["a.png"])
        this.letters.set("b", textures["b.png"])
        this.letters.set("c", textures["c.png"])
        this.letters.set("d", textures["d.png"])
        this.letters.set("e", textures["e.png"])
        this.letters.set("f", textures["f.png"])
        this.letters.set("g", textures["g.png"])
        this.letters.set("h", textures["h.png"])
        this.letters.set("i", textures["i.png"])
        this.letters.set("j", textures["j.png"])
        this.letters.set("k", textures["k.png"])
        this.letters.set("l", textures["l.png"])
        this.letters.set("m", textures["m.png"])
        this.letters.set("n", textures["n.png"])
        this.letters.set("o", textures["o.png"])
        this.letters.set("p", textures["p.png"])
        this.letters.set("q", textures["q.png"])
        this.letters.set("r", textures["r.png"])
        this.letters.set("s", textures["s.png"])
        this.letters.set("t", textures["t.png"])
        this.letters.set("u", textures["u.png"])
        this.letters.set("v", textures["v.png"])
        this.letters.set("w", textures["w.png"])
        this.letters.set("x", textures["x.png"])
        this.letters.set("y", textures["y.png"])
        this.letters.set("z", textures["z.png"])
        this.letters.set(" ", textures["space.png"])
        this.letters.set("1", textures["1.png"])
        this.letters.set("2", textures["2.png"])
        this.letters.set("3", textures["3.png"])
        this.letters.set("4", textures["4.png"])
        this.letters.set("5", textures["5.png"])
        this.letters.set("6", textures["6.png"])
        this.letters.set("7", textures["7.png"])
        this.letters.set("8", textures["8.png"])
        this.letters.set("9", textures["9.png"])
        this.letters.set("0", textures["0.png"])
    }

    makeSentense = (sentense: string = "Test message", letterSpacing: number = 0) => {
        let xPos = new PIXI.Container();
        xPos.x = 0;
        xPos.y = 0;
        for (let i = 0; i < sentense.length; i++) {
            let letter = new PIXI.Sprite(this.letters.get(sentense[i].toLowerCase()))
            letter.position.copyFrom(xPos);
            letter.x += xPos.width + letterSpacing;
            letter.y = 0;
            this.addChild(letter)
            xPos = letter;
        }
        xPos = new PIXI.Container();
        xPos.destroy();
    }

    setScale = (scale: number = 1) => {
        this.scale.set(scale);
    }
}

export default LettersHelper;