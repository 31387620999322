import * as PIXI from 'pixi.js';
import { EventType, getEvent } from '../../../GameEventBus';
import { LocalStorage } from '../../../utils/localStorage';
import { Game } from "../../Game";
import {
    staticTextFooterHorizontalStyles,
    dynamicTextFooterHorizontalStyles,
    dynamicTextFooterHorizontalStylesWin
} from '../../styles';


class FooterForMobileHorizontal {
    game: Game;
    footer: PIXI.Container;
    footerBG: PIXI.Graphics;
    betText: PIXI.Text;
    betValue: PIXI.Text;
    winText: PIXI.Text;
    winValue: PIXI.Text
    creditText: PIXI.Text;
    creditValue: PIXI.Text;
    totalBetList: number[];
    currentBetValue: number;
    selectedIndex: number;

    constructor(game: Game) {
        this.totalBetList = [0];
        this.game = game;
        this.selectedIndex = LocalStorage.getItem('chosenBet') ? LocalStorage.getItem('chosenBet') : 0;
        this.currentBetValue = this.totalBetList[this.selectedIndex]
        this.footer = new PIXI.Container();
        this.footerBG = new PIXI.Graphics();
        this.betText = new PIXI.Text('TOTAL BET', staticTextFooterHorizontalStyles);
        this.betValue = new PIXI.Text(`${this.currentBetValue}`, dynamicTextFooterHorizontalStyles);
        this.winText = new PIXI.Text('WIN', staticTextFooterHorizontalStyles);
        this.winValue = new PIXI.Text('0.00 USD', dynamicTextFooterHorizontalStylesWin);
        this.creditText = new PIXI.Text('CREDIT', staticTextFooterHorizontalStyles);
        this.creditValue = new PIXI.Text('100500.00', dynamicTextFooterHorizontalStyles);

    }

    init = async () => {
        await this.setupFooterContainer();
        await this.setupFooterBG();
        await this.setupBet();
        await this.setupWin();
        await this.setupCredit();
        this.game.app.stage.addChild(this.footer);
        this.game.resizeCallbacks.push(this.resize)

        getEvent<number>(EventType.CHOSEN_BET).subscribe(value => {
            this.setTotalBet(value)
        })
    }

    setupFooterContainer = () => {
        this.footer.width = 1920;
        this.footer.height = 70;
        this.footer.x = 0;
        this.footer.y = 500;
        this.footer.pivot.set(0, 70)
        this.footer.name = 'FOOTER_FOR_MOBILE_HORIZONTAL';
    }

    setupFooterBG = () => {
        this.footerBG.beginFill(0x000000);
        this.footerBG.drawRect(0, 0, 1920, 70);
        this.footerBG.endFill();
        this.footer.addChild(this.footerBG);
    }

    setupBet = () => {
        this.betText.x = 10;
        this.betValue.x = this.betText.width + 30;
        this.betValue.y = 10;
        this.betText.y = 10;
        this.footer.addChild(this.betText)
        this.footer.addChild(this.betValue)
    }

    setupWin = () => {
        this.winText.x = 1920 / 2 - this.winText.width;
        this.winText.y = 10;
        this.winValue.x = 1920 / 2 + 15;
        this.winValue.y = 10;
        this.footer.addChild(this.winText)
        this.footer.addChild(this.winValue)
    }

    setupCredit = () => {
        this.creditText.x = 1920 - this.creditText.width - this.creditText.width * 1.55;
        this.creditText.y = 10;
        this.creditValue.x = 1920 - this.creditText.width * 1.5;
        this.creditValue.y = 10;
        this.footer.addChild(this.creditText)
        this.footer.addChild(this.creditValue)

    }

    setTotalBet = (index: number) => {
        this.currentBetValue = this.totalBetList[index];
        this.betValue.text = this.currentBetValue?.toFixed(2);
    }

    changeBalance = (value: number) => {
        this.creditValue.text = value.toFixed(2);
    }

    changeWin = (value: number) => {
        this.winValue.text = `${value.toFixed(2)} USD`
    }

    setBetList = (value: number[]) => {
        this.totalBetList = value;
        this.currentBetValue = this.totalBetList[this.selectedIndex];
        this.betValue.text = `${this.currentBetValue?.toFixed(2)}`;
    }

    resize = () => {
        let ratio = window.innerWidth / window.innerHeight;
        if (window.innerWidth <= 1080 && ratio >= 0.76) {
            this.footer.visible = true;
            this.footer.scale.x = window.innerWidth / 1920;
            this.footer.scale.y = window.innerWidth / 1920;
            this.footer.y = window.innerHeight - 30;
            return;
        }
        this.footer.visible = false;
    }

}

export default FooterForMobileHorizontal;
