import styled from "styled-components";
import { reelsList } from "./constants";

interface IHandleCloseModal {
    handleCloseModal: () => void;
}
const PaytableDesktop = ({handleCloseModal}:IHandleCloseModal ) => {

    return (
        <Container>
        
                <ButtonBg onClick={handleCloseModal}>
                    <Button>
                        <ButtonHoverBg className="btn-hover">
                            {cross}
                        </ButtonHoverBg>
                    </Button>
                </ButtonBg>
            

            <Title>PAYTABLE</Title>

            <ReelsContainer>
                <SoloReelContainer>
                    <img src={reelsList[0].img} />
                    <div dangerouslySetInnerHTML={{ __html: reelsList[0].text }} />
                </SoloReelContainer>
                <SoloReelContainer>
                    <img src={reelsList[1].img} />
                    <div dangerouslySetInnerHTML={{ __html: reelsList[1].text }} />
                </SoloReelContainer>
                <SoloReelContainer>
                    <img src={reelsList[2].img} />
                    <div dangerouslySetInnerHTML={{ __html: reelsList[2].text }} />
                </SoloReelContainer>
            </ReelsContainer>

            <ReelsContainer>
                <SoloReelContainer>
                    <img src={reelsList[3].img} />
                    <div dangerouslySetInnerHTML={{ __html: reelsList[3].text }} />
                </SoloReelContainer>
                <SoloReelContainer>
                    <img src={reelsList[4].img} />
                    <div dangerouslySetInnerHTML={{ __html: reelsList[4].text }} />
                </SoloReelContainer>
                <SoloReelContainer>
                    <img src={reelsList[5].img} />
                    <div dangerouslySetInnerHTML={{ __html: reelsList[5].text }} />
                </SoloReelContainer>
                <SoloReelContainer>
                    <img src={reelsList[6].img} />
                    <div dangerouslySetInnerHTML={{ __html: reelsList[6].text }} />
                </SoloReelContainer>
            </ReelsContainer>

        </Container>
    );
}
export default PaytableDesktop;

const Container = styled.div`
    display: flex;
    position: absolute;
    justify-content: center;
    flex-direction: column;
    width: 84.27vw;
    height: 45.78vw;
    padding: 2.292vw;
`
const Title = styled.h1`
    margin-bottom: 2.292vw;
    font-family: Rubik;
    font-size: 2.292vw;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
`
const ReelsContainer = styled.div`
    display: flex;
    margin: auto;
    margin-bottom: 5vw;
    align-items: space-between;
    justify-content: center;
    width: 79.84vw;
    height: 11.458vw;
`
const SoloReelContainer = styled.div`
    display: flex;
    margin: auto; 
    width: 19.17vw;
    height: 11.458vw;

    img {
        width: 11.458vw;
        height: 11.458vw;
    }
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        margin-left: 0.83333vw;
        font-family: Rubik;
        font-size: 1.14583vw;
        font-weight: 700;
        line-height: 2.2916vw;
        letter-spacing: 0em;
        text-align: left;
    }
`

const ButtonBg = styled.div`
    top: -1.041666vw;
    right: 0.78125vw;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.71vw;
    height: 2.71vw;
    margin: auto;
    position: absolute;
    border-radius: 100%;
    outline: 0.14vw solid #151515;
    background-position: 0px 0px,0px 0px,0px 0px,0px 0px,0px 0px;
    background-size: 100% 100%;
    background-position: 0px 0px,0px 0px,0px 0px,0px 0px,0px 0px;
    background-image: radial-gradient(49% 81% at 45% 47%, #FFE20345 0%, #073AFF00 100%),
    conic-gradient(from 180deg at 50% 50%, #af70c1 1%, #45d1bd 33%, #f9ad29 66%, #af70c1 100%),
    radial-gradient(142% 91% at 83% 7%, #FFDB00FF 1%, #FF000000 99%),
    radial-gradient(142% 91% at -6% 74%, #FF0049FF 1%, #FF000000 99%),
    radial-gradient(142% 91% at 111% 84%, #FF7000FF 0%, #FF0000FF 100%);
    `

const ButtonHoverBg = styled.div`
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    background: #151515;
    fill: #e4f2f4;
    position: absolute;
    width: 2.5vw;
    height: 2.5vw;
    border-radius: 100%;
    border: 0.14vw solid #151515;

    &.btn-hover:hover {
        background: #e4f2f4;
        fill: #151515;
    }`

const Button = styled.button`
    background: #151515;    
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    fill: #e4f2f4;
    width: 2.5vw;
    height: 2.5vw;
    border-radius: 100%;
    border: 0.14vw solid #151515;
    `

const cross = <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox="0 0 21 21">
    <style type="text/css">
        .st0{"fill:#E4F2F4;"}
    </style>
    <path className="st0" d="M11.7,10.5l2.9-2.9c0.3-0.3,0.3-0.9,0-1.2l0,0c-0.3-0.3-0.9-0.3-1.2,0l-2.9,2.9L7.6,6.4C7.3,6,6.7,6,6.4,6.4
	l0,0C6,6.7,6,7.3,6.4,7.6l2.9,2.9l-2.9,2.9c-0.3,0.3-0.3,0.9,0,1.2l0,0c0.3,0.3,0.9,0.3,1.2,0l2.9-2.9l2.9,2.9
	c0.3,0.3,0.9,0.3,1.2,0l0,0c0.3-0.3,0.3-0.9,0-1.2L11.7,10.5z"/>
</svg>
