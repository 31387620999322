import {FC, useState, useEffect, useLayoutEffect} from 'react';
import {Game} from "../../../game/Game";
import {respondFrom, respondTo} from "../../../styles/mixins";
import styled from "styled-components";
import {EventType, useEventReceiver} from '../../../GameEventBus';
import {LocalStorage} from '../../../utils/localStorage';

interface IInfo {
    game: Game;
	betsArray: number[];
}

const InfoBar: FC<IInfo> = ({ game, betsArray }) => {
    const index = LocalStorage.getItem('chosenBet') ? LocalStorage.getItem('chosenBet') : 0;
	const [bets, setBetsArray] = useState(betsArray)
	const [bet, setBet] = useState(betsArray[index > 0 ? index : 0])
	const [balance, setBalance] = useState(0)
    const [win, setWin] = useState(0)

	useEffect(() => {
		setBetsArray(betsArray);
		setBet(bets[index]);
	})

	useEventReceiver<number>(EventType.CHOSEN_BET, value => {
		setBet(bets[value]);
	})

    useEventReceiver<number>(EventType.CHANGE_WIN, value => {
        setWin(value);
    })

	useEventReceiver<number>(EventType.CHANGE_BALANCE, value => {
		setBalance(value);
	})

    return (
        <InfoContainer>
            <InfoItem>
                <InfoTitle>TOTAL BET:</InfoTitle>
                <InfoValue>{bet?.toFixed(2)} USD</InfoValue>
            </InfoItem>
            <InfoItem>
                <InfoTitle>WIN:</InfoTitle>
                <InfoValue>
					<span>
						{win.toFixed(2)} USD
					</span>
                </InfoValue>
            </InfoItem>
            <InfoItem>
                <InfoTitle>BALANCE:</InfoTitle>
                <InfoValue>
                    {balance.toFixed(2)} USD
                </InfoValue>
            </InfoItem>
        </InfoContainer>
    );
};

export default InfoBar;

const InfoContainer = styled.div`
	margin: auto;
	max-width: 631px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const InfoItem = styled.div`
	display: flex;
	align-items: center;
	padding: 0 10px;
	font-family: 'Rubik', sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;

	${respondFrom.sm`
		flex-direction: column;
	`}
`;

const InfoTitle = styled.div`
	color: #7c8584;
	padding-right: 8px;
`;

const InfoValue = styled.div`
	color: #f9ad29;
	span {
		color: #e2f4f2;
		${respondFrom.xs`
		color: #f9ad29;
		`}
		${respondTo.sm`
		color: #f9ad29;
	`}
	}
`;
