import {FC, useState, useCallback, useEffect} from 'react';
import styled from 'styled-components';

import advancedSettingsIcon from '../../game/assets/modal/auto-play-modal-arrow.svg';
import {Game} from "../../game/Game";
import {respondFrom, respondTo} from "../../styles/mixins";
import NumberOfSpins from "./components/NumberOfSpins";
import AutoPlayHeader from "./components/ModalHeader";
import StopAutoPlay from "./components/StopAutoPlay";
import LossLimit from "./components/LossLimit";
import SingleWinLimit from "./components/SingleWinLimit";

interface IAutoPlayModal {
    game: Game;
}

const AutoPlayModal: FC<IAutoPlayModal> = ({game}) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isAdvancedSettingsOpen, setAdvancedSettingsOpen] = useState(false);
    const [spinCount, setSpinCount] = useState<number | string>('OFF');
    const [onAnyWin, setOnAnyWin] = useState(false);
    const [isFreeSpinWon, setIsFreeSpinWon] = useState(false);
    const [lossLimit, setLossLimit] = useState<string>('NO LIMIT');
    const [singleWinLimit, setSingleWinLimit] = useState<string>('NO LIMIT');

    const changeSpinCount = useCallback(
        (count: number | string) => {
            if(!game.assetsManager.autoPlayModal) return

            setSpinCount(count);
            game.assetsManager.autoPlayModal.stopSpin = true
            // game.soundController.btn_ui?.play()
            game.assetsManager?.autoPlayBtn?.setSpinCounter(count);
        },
        [setSpinCount, game]
    );

    const stopOnAnyWin = useCallback(
        (check: boolean) => {
            if(!game.assetsManager.autoPlayModal) return
            game.assetsManager?.autoPlayModal?.setStopOnAnyWin(check)
            game.assetsManager.autoPlayModal.refreshLimit = true
            setOnAnyWin(check);
        },
        [setOnAnyWin]
    );

    const stopIsFreeSpinWon = useCallback(
        (check: boolean) => {
            if(!game.assetsManager.autoPlayModal) return
            game.assetsManager?.autoPlayModal?.setStopIsFreeSpinWon(check)
            game.assetsManager.autoPlayModal.refreshLimit = true
            setIsFreeSpinWon(check);
        },
        [setIsFreeSpinWon]
    );

    const changeLossLimit = useCallback(
        (limit: string) => {
            if (!game.assetsManager.autoPlayModal) return
            game.assetsManager?.autoPlayModal?.setLossLimitValue(limit)

            // game.soundController.btn_ui?.play()
            // game.slotMachine.refreshLimits = true
            setLossLimit(limit);
        },
        [setLossLimit]
    );

    const changeSingleWinLimit = useCallback(
        (limit: string) => {
            if (!game.assetsManager.autoPlayModal) return
            game.assetsManager?.autoPlayModal?.setSingleWinLimitValue(limit)
            game.assetsManager.autoPlayModal.refreshLimit = true
            // game.soundController.btn_ui?.play()
            setSingleWinLimit(limit);
        },
        [setSingleWinLimit]
    );

    const startAutoPlay = useCallback(() => {
        if(!game.assetsManager.autoPlayModal) return
        game.assetsManager.autoPlayModal.refreshLimit = true
        game.assetsManager?.autoPlayModal?.startAutoPlay(spinCount);
        if (spinCount !== 'OFF') {
            // game.assetsManager.btn_ui?.play()
            showModal(false);
        }
    }, [game, spinCount]);

    const showModal = (isOpen: boolean) => {
        setModalOpen(isOpen);
        // game.assetsController?.isInteractiveActive(!isOpen);
        if (game.assetsManager && game.assetsManager.autoPlayBtn) {
            game.assetsManager.autoPlayBtn.isModalActive = isOpen;
        }
    };

    const toggleAdvancedSettings = useCallback((status: boolean) => {
        setAdvancedSettingsOpen(status)
    }, [setAdvancedSettingsOpen])


    useEffect(() => {
        game.assetsManager?.autoPlayBtn?.button.on('click', e => {
                leftClickAutoPlayButton(e)
            }
        );
        game.assetsManager?.autoPlayBtn?.button.on('touchstart', () =>
            showModal(true)
        );
    }, [game])




    const leftClickAutoPlayButton = (e: any) => {
        if (e.data.button === 0) {
            showModal(true)
        }
        ;
    }

    const changeButtonState = () => {
        if(game.assetsManager!.autoPlayModal!.isAutoPlayRunning === false) {
            // game.slotMachine?.changeButtonState(false);
        }
    }

    const closeModal = useCallback(() => {
        showModal(false)
        changeButtonState()
    }, [showModal])

    return (
        <Wrapper style={game.assetsManager.autoPlayBtn?.isModalActive ? {display: 'flex'} : {display: 'none'}}>
            <Container>
                <div>
                    <AutoPlayHeader
                        spinCount={spinCount}
                        closeModal={closeModal}
                        startAutoPlay={startAutoPlay}
                        game={game}
                    />

                    <NumberOfSpins
                        spinCount={spinCount}
                        changeSpinCount={changeSpinCount}
                    />
                    <FooterWrapper>
                        <FooterTitle>ADVANCED SETTINGS</FooterTitle>
                        <FooterButton
                            style={isAdvancedSettingsOpen ? {} : {transform: 'rotate(0)'}}
                            onClick={() => toggleAdvancedSettings(!isAdvancedSettingsOpen)}
                        >
                            <img src={advancedSettingsIcon} alt='Arrow'/>
                        </FooterButton>
                    </FooterWrapper>
                </div>
                <AdvancedSettingsWrapper
                    style={
                        isAdvancedSettingsOpen ? {display: 'flex'} : {display: 'none'}
                    }
                >
                    <StopAutoPlay
                        onAnyWin={onAnyWin}
                        stopOnAnyWin={stopOnAnyWin}
                        isFreeSpinWon={isFreeSpinWon}
                        stopIsFreeSpinWon={stopIsFreeSpinWon}
                    />
                    <LossLimit lossLimit={lossLimit} changeLossLimit={changeLossLimit}/>
                    <SingleWinLimit
                        singleWinLimit={singleWinLimit}
                        changeSingleWinLimit={changeSingleWinLimit}
                    />
                </AdvancedSettingsWrapper>
            </Container>
        </Wrapper>
    );
};

export default AutoPlayModal;

const Wrapper = styled.div`
  padding: 38px 6px 6px;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(21, 21, 21, 0.8);
  left: 0;
  top: 0;
  align-items: flex-start;
  justify-content: flex-end;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${respondFrom.sm`
		align-items: flex-end;
		justify-content: center;
	`}
  ${respondTo.lg`
		align-items: center;
		justify-content: center;
	`}
`;

const Container = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: #151515;
  padding: 8px 9px 10px;
  border: 1px solid #2a2c2b;
  border-radius: 15px;
  width: 304px;
  max-height: 100%;
  ${respondFrom.sm`
		max-width: 360px;
		width: 100%;
	`}
`;

const FooterTitle = styled.div`
  font-family: 'Rubik';
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  color: #7c8584;
  flex: auto;
  text-align: center;
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  position: relative;
  margin-top: 16px;
`;

const FooterButton = styled.div`
  transform: rotate(180deg);
  transition: all ease 0.2s;
  position: absolute;
  right: 7px;
  cursor: pointer;
`;

const AdvancedSettingsWrapper = styled.div`
  flex-direction: column;
`;
