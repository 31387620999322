// import { ITrackEntry, Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';
import {Game} from '../../Game';
import CustomButton from '../CustomButton';
import {confirmButtonStyles} from '../../styles';
import {Texture} from 'pixi.js';
import {BUTTONS_ASSETS} from '../../assetsLoader/ButtonsLoader';
import {MODAL_ASSETS} from '../../assetsLoader/modalLoader';
import {SYMBOLS_ASSETS} from "../../assetsLoader/SymbolsLoader";

class WinStartPopUp {
    game: Game;
    modalBorder?: PIXI.Sprite
    confirmButtonDefault?: PIXI.Sprite
    modal: PIXI.Container;
    value?: PIXI.Container;
    textContainer: PIXI.Container
    modalWrapper: PIXI.Container;
    windowBackground: PIXI.Graphics;
    isModalOpen: boolean;
    modalText?: PIXI.Sprite
    numbers: PIXI.Texture[]
    spinsCount?: string
    
    constructor(game: Game) {
        this.game = game;
        this.modal = new PIXI.Container();
        this.modalWrapper = new PIXI.Container();
        this.textContainer = new PIXI.Container();

        this.windowBackground = new PIXI.Graphics();

        this.value = new PIXI.Container();
        this.isModalOpen = false;
        this.numbers = [
            Texture.from(SYMBOLS_ASSETS.ZERO),
            Texture.from(SYMBOLS_ASSETS.ONE),
            Texture.from(SYMBOLS_ASSETS.TWO),
            Texture.from(SYMBOLS_ASSETS.THREE),
            Texture.from(SYMBOLS_ASSETS.FOUR),
            Texture.from(SYMBOLS_ASSETS.FIVE),
            Texture.from(SYMBOLS_ASSETS.SIX),
            Texture.from(SYMBOLS_ASSETS.SEVEN),
            Texture.from(SYMBOLS_ASSETS.EIGHT),
            Texture.from(SYMBOLS_ASSETS.NINE)
        ]
        this.spinsCount = '0'
    }

    init = async () => {
        this.confirmButtonDefault = new PIXI.Sprite(Texture.from(BUTTONS_ASSETS.WIN_START_BTN_DEFAULT))
        this.modalBorder = new PIXI.Sprite(Texture.from(MODAL_ASSETS.MODAL_BACKGROUND))

        this.modalWrapper.visible = false;
        this.modalWrapper.zIndex = 10000;
        this.modalWrapper.width = window.innerWidth;
        this.modalWrapper.height = window.innerHeight;
        this.windowBackground.beginFill(0x151515);
        this.windowBackground.drawRect(0, 0, window.innerWidth, window.innerHeight);
        this.windowBackground.endFill();
        this.windowBackground.alpha = 0.8;
        this.windowBackground.zIndex = 1

        this.modal.x = window.innerWidth / 2 - 35;
        this.modal.y = window.innerHeight / 2 + 320;
        this.modal.zIndex = 100;

        this.modalText = new PIXI.Sprite(Texture.from(MODAL_ASSETS.WIN_START_MODAL_TEXT))
        this.modalText.zIndex = 22222
        this.modalText!.width = 748
        this.modalText!.height = 417
        this.modalText!.x = (this.modalBorder!.width - this.modalText!.width) / 2
        this.modalText!.y = 75

        this.modalBorder.addChild(this.modalText);
        this.game.app.stage.addChild(this.modalWrapper);
        this.modalWrapper.addChild(this.windowBackground!);
        this.modalWrapper.addChild(this.modal!);
        this.modal.addChild(this.value!);
        this.game.resizeCallbacks.push(this.resize)
        await this.setupButtons()

        // document.addEventListener('keydown', (e) => {
        //     if (e.code === 'KeyD') this.showModal(true, '6')
        // })
        const isPortrait = window.innerWidth / window.innerHeight <= 0.76
        this.resize(isPortrait)
    };

    openModal = async (spinsCount: string) => {
        if (!this.game.assetsManager?.playBtn) return
        this.isModalOpen = true;
        this.confirmButtonDefault!.buttonMode = true
        this.confirmButtonDefault!.interactive = true
        this.setSpinCount(spinsCount)
        const timer = setTimeout(() => {
            clearTimeout(timer)
        }, 150)

    }

    setSpinCount = (number: string) => {
        this.textContainer.removeChildren()
        const summary = number.split('');
        const width = summary.length * 110;
        this.textContainer.width = width;


        this.textContainer.y = 170;

        for (let i = 0; i < summary.length; i++) {
            let index: number = Number(summary[i]);
            let numToAdd = new PIXI.Sprite(this.numbers[index]);
            numToAdd.scale.set(0.63, 0.63)
            numToAdd.x = numToAdd.width * i + 20;
            this.textContainer.addChild(numToAdd);
        }

        this.textContainer.x = this.modalBorder!.width / 2 - this.textContainer.width / 2 - 25;
        this.modalBorder!.addChild(this.textContainer);
    }

    showModal = (isActive: boolean, spinsCount?: string) => {
        if (isActive) {
            this.spinsCount = spinsCount
            this.openModal(spinsCount!)
            this.modalWrapper.visible = true;
            this.game.assetsManager.gameContainer.interactiveChildren = false;
        } else {
            this.modalWrapper.visible = false;
            this.game.assetsManager.gameContainer.interactiveChildren = true;
        }
    };

    closeModal = async () => {
        this.isModalOpen = false;
        this.showModal(false)
        this.value?.removeChildren()
    }

    setupButtons = async () => {

        this.confirmButtonDefault!.on('touchstart', () => {
            this.confirmButtonDefault!.buttonMode = false;
            this.confirmButtonDefault!.interactive = false;
            // this.game.slotMachine?.onFreeSpin(Number(this.spinsCount))
            this.closeModal()
        })
        this.confirmButtonDefault!.on('click', () => {
            this.confirmButtonDefault!.buttonMode = false;
            this.confirmButtonDefault!.interactive = false;
            // this.game.slotMachine?.onFreeSpin(Number(this.spinsCount))
            this.closeModal()
        })

        this.modalBorder!.x = (this.modal.width - this.modalBorder!.width) / 2;
        this.modalBorder!.y = 500;

        this.confirmButtonDefault!.x = (this.modalBorder!.width - this.confirmButtonDefault!.width) / 2;
        this.confirmButtonDefault!.y = 520;

        this.modal.addChild(this.modalBorder!)
        this.modalBorder!.addChild(this.confirmButtonDefault!)
    };

    doPointerDown = () => {
        this.showModal(false)
    };

    leftClick = (e: any) => {
        if (e.data.button === 0) {
            this.showModal(false)
        }
        ;
    }

    changeWinStartScale(scalingX: number, scalingY: number) {
        if (!this.modal) return;
        this.modal.scale.x = scalingX;
        this.modal.scale.y = scalingY;

        this.windowBackground.width = window.innerWidth;
        this.windowBackground.height = window.innerHeight;
    }

    changeWinStartPosition(x: number, y: number) {
        if (!this.modal) return;
        this.modal.x = x;
        this.modal.y = y;
    }

    changeWinStartSize(x: number, y: number) {
        if (!this.modal) return;
        this.modal.width = x;
        this.modal.height = y;
    }

    resize = (isPortrait: any) => {
        if (!this.modal || !this.game.assetsManager.gameField?.container) return

        this.windowBackground.width = window.innerWidth
        this.windowBackground.height = window.innerHeight

        const currentRatio = window.innerWidth / window.innerHeight

        if (currentRatio > 1.77) {
            const defaultScale = 0.88
            const height = window.innerHeight * defaultScale
            this.modal.scale.set(height / 960, height / 960)
        } else {
            if (isPortrait) {
                const defaultScale = 0.88
                const width = window.innerWidth * defaultScale
                this.modal.scale.set(width / 960, width / 960)
            } else {
                const defaultScale = 0.52
                const width = window.innerWidth * defaultScale
                this.modal.scale.set(width / 960, width / 960)
            }
        }

        this.modal.x = window.innerWidth / 2
        this.modal.y = this.modalWrapper.y - this.modal.height / 2
    }
}

export default WinStartPopUp;
