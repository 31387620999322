import { FC, useState, useEffect } from 'react';

import MenuModal, { MenuList }  from '../MenuModal/MenuModal';
import { Game } from '../../../game/Game';
export interface INav {
	game: Game;
}

const NavBar: FC<INav> = ({ game }) => {
	// const { logout } = useActions();
	const [activeModal, setActiveModal] = useState(MenuList.Paytable);
	const [isSoundOn, setIsSoundOn] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	
	const logOut = () => {
		// logout();
		window.location.reload()
	};

	const changeModalState = () => {
		setIsModalOpen(!isModalOpen)
		game.assetsManager?.burgerMenuBtn?.changeMenuState(!isModalOpen)
        window.addEventListener('keydown', function (e) {
            if (e.key == " " ||
                e.code == "Space" ||
                e.keyCode == 32) {
                e.preventDefault();
                return false;
            }
        });
	}

	const showMenuModal = () => {
		changeModalState();
		setActiveModal(MenuList.Paytable);
	};

	game.assetsManager?.burgerMenuBtn?.btn.on('click', () =>
		showMenuModal()
	);
	game.assetsManager?.burgerMenuBtn?.btn.on('touchstart', () =>
		showMenuModal()
	);

	return (
		<>
			<MenuModal
				game={game}
				activeModal={activeModal}
				setActiveModal={setActiveModal}
				isModalOpen={isModalOpen}
				showMenuModal={showMenuModal}
			/>
		</>
	);
};

export default NavBar;
