import {Application} from 'pixi.js';
import {Game} from "../../Game";
import {EventType, getEvent} from "../../../GameEventBus";
import {Timer} from '../../../utils/Timer';

class AutoPlayModal {
    app: Application;
    game: Game;
    buttons?: any[];
    lossLimitValue?: number;
    singleWinLimitValue?: number;
    winResult: number;
    totalBet: number;
    stopOnAnyWin: boolean;
    stopIsFreeSpinWon?: boolean;
    isAutoPlayRunning?: boolean;
    stopSpin: boolean
    stopOnFreeSpin: boolean
    singleWin: null | number
    lossLimit: null | number
    refreshLimit: boolean


    constructor(game: Game) {
        this.isAutoPlayRunning = false;
        this.game = game;
        this.app = game.app;
        this.buttons = [];
        this.winResult = 0;
        this.totalBet = 0;
        this.stopSpin = false

        this.stopOnAnyWin = false;
        this.stopOnFreeSpin = false;
        this.singleWin = 0;
        this.lossLimit = 0;
        this.refreshLimit = false
        this.setupListeners()
    }


    initialize = async () => {


    };


    startAutoPlay = (value: string | number) => {
        if (value === 'OFF') {
            this.isAutoPlayRunning = false;
            this.stopSpin = true;
            getEvent(EventType.GAME_AUTO_SPIN_COUNT).send('');
            getEvent(EventType.AUTOSPIN_IN_PROGRESS).send(false);
            return;
        } else if (value === 'ထ') {
            this.isAutoPlayRunning = true;
            this.stopSpin = false;
            this.winResult = 0;
            this.totalBet = 0;
            getEvent(EventType.AUTOSPIN_IN_PROGRESS).send(true);
            this.setCounter(Infinity);
        } else if (Number(value) > 0) {
            this.isAutoPlayRunning = true;
            this.stopSpin = false;
            this.winResult = 0;
            this.totalBet = 0;
            getEvent(EventType.AUTOSPIN_IN_PROGRESS).send(true);
            this.setCounter(Number(value));
        }
    }

    setCounter = (value: number, count: number = 0) => {
        if (this.stopSpin || !this.game.slotMachine) return;
        if (value > count) {
            this.game.assetsManager?.autoPlayBtn?.setButtonState(true)
            const timer = new Timer(async () => {
                if (this.stopSpin) return timer.clear()
                getEvent<boolean>(EventType.SPIN_IN_PROGRESS).subscribe(check => {
                    if (!check) value -= 1
                })
                this.totalBet += 1
                if (value === Infinity) {
                    this.game.assetsManager?.autoPlayBtn?.setSpinCounter('ထ')
                } else {
                    this.game.assetsManager?.autoPlayBtn?.setSpinCounter(value);
                    getEvent(EventType.GAME_AUTO_SPIN_COUNT).send(value === 0 ? '' : value);
                }
                const isStopped = await this.game.slotMachine?.onSpin();
                this.refreshLimit = false

                if (isStopped) {
                    this.game.assetsManager?.autoPlayBtn?.setButtonState(false);
                    this.game.assetsManager?.playBtn?.changeButtonMode('default');
                    value = 0
                    this.stopSpin = true
                    this.isAutoPlayRunning = false;
                    getEvent(EventType.AUTOSPIN_IN_PROGRESS).send(false)
                    timer.clear()

                }
                this.setCounter(value)
                if (value === 0) {
                    this.isAutoPlayRunning = false;
                }
            }, 1000);
            timer.initialize()
        } else {
            this.game.assetsManager?.autoPlayBtn?.setButtonState(false)
            this.stopSpin = true
            this.isAutoPlayRunning = false;
        }
    }

    setLossLimitValue = (value: string) => {
        switch (value) {
            case '5x':
                this.lossLimitValue = 5
                getEvent(EventType.SET_LOSS_LIMIT).send(this.lossLimitValue);
                break;
            case '20x':
                this.lossLimitValue = 20
                getEvent(EventType.SET_LOSS_LIMIT).send(this.lossLimitValue);
                break;
            case '50x':
                this.lossLimitValue = 50
                getEvent(EventType.SET_LOSS_LIMIT).send(this.lossLimitValue);
                break;
            case '75x':
                this.lossLimitValue = 75
                getEvent(EventType.SET_LOSS_LIMIT).send(this.lossLimitValue);
                break;
            case '100x':
                this.lossLimitValue = 100
                getEvent(EventType.SET_LOSS_LIMIT).send(this.lossLimitValue);
                break;
            default:
                this.lossLimitValue = 0
                getEvent(EventType.SET_LOSS_LIMIT).send(this.lossLimitValue);
                break;
        }
    }
    setSingleWinLimitValue = (value: string) => {
        switch (value) {
            case '5x':
                this.singleWinLimitValue = 5
                getEvent(EventType.SET_SINGLE_WIN).send(this.singleWinLimitValue);
                break;
            case '20x':
                this.singleWinLimitValue = 20
                getEvent(EventType.SET_SINGLE_WIN).send(this.singleWinLimitValue);
                break;
            case '50x':
                this.singleWinLimitValue = 50
                getEvent(EventType.SET_SINGLE_WIN).send(this.singleWinLimitValue);
                break;
            case '75x':
                this.singleWinLimitValue = 75
                getEvent(EventType.SET_SINGLE_WIN).send(this.singleWinLimitValue);
                break;
            case '100x':
                this.singleWinLimitValue = 100
                getEvent(EventType.SET_SINGLE_WIN).send(this.singleWinLimitValue);
                break;
            default:
                this.singleWinLimitValue = 0
                getEvent(EventType.SET_SINGLE_WIN).send(this.singleWinLimitValue);
                break;
        }
    }
    setStopOnAnyWin = (check: boolean) => {
        this.stopOnAnyWin = check
        getEvent(EventType.SET_STOP_ON_ANY_WIN).send(this.stopOnAnyWin);
    }
    setStopIsFreeSpinWon = (check: boolean) => {
        this.stopIsFreeSpinWon = check
        getEvent(EventType.SET_STOP_ON_FREE_SPIN).send(this.stopIsFreeSpinWon);
    }


    setupListeners = () => {
        console.log(`setup listeners`)
        getEvent<number>(EventType.SET_LOSS_LIMIT).subscribe(value => {
            this.lossLimit = value;

            console.log('loss limit', this.lossLimit)
        })

        getEvent<number>(EventType.SET_SINGLE_WIN).subscribe(value => {
            this.singleWin = value;
        })

        getEvent<boolean>(EventType.SET_STOP_ON_ANY_WIN).subscribe(value => {
            this.stopOnAnyWin = value;
            console.log(`ON ANY WIN`, value)
        })

        getEvent<boolean>(EventType.SET_STOP_ON_FREE_SPIN).subscribe(value => {
            this.stopOnFreeSpin = value;

        })

        getEvent<boolean>(EventType.AUTOSPIN_IN_PROGRESS).subscribe(value => {
            if (!this.game.slotMachine) return
            console.log(`auto play in progress`, this.lossLimit)
            console.log(this.stopOnAnyWin)
            this.game.slotMachine.requestParams = {
                stopOnAnyWin: this.stopOnAnyWin,
                singleWin: this.singleWin,
                stopOnFreeSpin: this.stopOnFreeSpin,
                refreshLimit: this.refreshLimit,
                lossLimit: this.lossLimit
            }

            if (!value) {
                this.stopOnAnyWin = false;
                this.stopOnFreeSpin = false;
                this.singleWin = null;
                this.lossLimit = null;
                this.game.slotMachine.requestParams = undefined
            }
        })
    }

}

export default AutoPlayModal;
